import React,{useState} from 'react';
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from "./PolicyNavbarUpdated";
import BtnPrimary from './PrimaryButton';
import BtnSecondary from './SecondaryButton';
import FormField from './Formfield';
import DateField from './DateField';
import {createPolicy} from "../services/expense";
import { getLegalUnitList } from '../services/globalData';
import {useNavigate} from "react-router-dom";
import ErrorMsg from './ErrorMsg';
import {currencyList} from './CurrencyDropdown';
import { currentUserDetails } from '../utils/currentUserDetails';

const PolicyDetails = ({type, listingPageLink, addCategoryPageLink}) => {
  const [apiCall,setApiCall]=useState(false);
  const navigate = useNavigate();
  const user = currentUserDetails();
  const createdBy = user.fname+" "+user.lname;
  const [policyData,setPolicyData] = useState({
        code:"",
        name:"",
        currencyType:"",
        startDate:null,
        endDate:null,
        description:""
  });
  const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
  const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({code:"",
                                                            name:"",
                                                            startDate:"",
                                                            endDate:"",
                                                            currencyType:""
                                                          });
 const [error,setError]=useState("");

 const handleNextClick = async() => {
      const errors ={};

      if (!policyData.code) errors.code="Code is required"
      if (!policyData.name) errors.name="Name is required"
      if (!policyData.startDate) errors.startDate="Start Date is required"
      if (!policyData.endDate) errors.endDate="End Date is required"
      
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      console.log(policyData);
      setApiCall(true);
      try {
          policyData.type=type;
          const legalUnit = getLegalUnitList();
          policyData.legalUnit = legalUnit[0]._id;
          const res = await createPolicy(policyData);
          console.log(res);
          if(res.success){
            navigate(addCategoryPageLink,{state:{policyId: res.data._id}});
          } else {
              console.error('Policy creation failed:', res.error);
              setError(res.message);
          }
      } catch (error) {
          console.error('Error creating Policy:', error);
      }
      setApiCall(false);
  };

  const handleChange = (field, value) => {
    const updatedValue = field === "code" ? value?.toUpperCase() : value;
    setPolicyData(prevState => ({...prevState, [field]: updatedValue}));
    setValidationErrors(prevState => ({...prevState, [field]: ""}));
    setError("");
  };

  return (
      <Box sx={{width:"100%"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={["Policy Details"]} value="16"/>
              <BtnSecondary to={listingPageLink} sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}>
                  Back
              </BtnSecondary>
          </Box>
          <Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                      <FormField
                          label={`${type==="ANY"?"Expense":"Trip"} Policy Code`}
                          type="text"
                          value={policyData.code}
                          validation={validationErrors.code}
                          placeholder="e.g. E099"
                          onChange={(e) => handleChange('code', e.target.value)}
                      />
                      <FormField
                          label={`${type==="ANY"?"Expense":"Trip"} Policy Name`}
                          type="text"
                          value={policyData.name}
                          validation={validationErrors.name}
                          onChange={(e) => handleChange('name', e.target.value)}
                      />
                      <FormField
                          label="Created By"
                          type="text"
                          value={createdBy}
                          disabled={true}
                      />
                      {/* <FormField
                          label="Currency Type"
                          type="select"
                          options={currencyList}
                          value={policyData.currencyType}
                          validation={validationErrors.currencyType}
                          onChange={(e) => handleChange('currencyType', e.target.value)}
                      /> */}
                      <FormField
                        label="Description"
                        type="text"
                        value={policyData.description}
                        fullwidth
                        row={4}
                        onChange={(e) => handleChange('description', e.target.value)}
                      />
                      <DateField
                        label="Policy Valid From"
                        value={policyData.startDate}
                        isDialogOpen={startDateDialogOpen}
                        validation={validationErrors.startDate}
                        openDialog={() => setStartDateDialogOpen(true)}
                        closeDialog={() => setStartDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('startDate', date)
                                  setStartDateDialogOpen(false)
                                  }}
                      />
                      <DateField
                        label="Policy Valid Till"
                        value={policyData.endDate}
                        isDialogOpen={endDateDialogOpen}
                        validation={validationErrors.endDate}
                        openDialog={() => setEndDateDialogOpen(true)}
                        closeDialog={() => setEndDateDialogOpen(false)}
                        onSelect={(date) => {
                                  handleChange('endDate', date)
                                  setEndDateDialogOpen(false)
                                }}
                      />
            </Box>
          </Box>
          <ErrorMsg>{error}</ErrorMsg>
          <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress/>:"Next"}
              </BtnPrimary> 
          </Box>
      </Box>
    )
}

export default PolicyDetails;