import React from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const MainBox = styled(Box)(({ sx }) => ({
  border: "1px solid #A2A1A833",
  padding: sx && sx.padding ? sx.padding : "0.8rem", 
  borderRadius: "10px",
  width: "100%",
}));

export default (props) => <MainBox sx={props.sx}>{props.children}</MainBox>;
