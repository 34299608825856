import React, { useState} from 'react';
import { Dialog,Box, IconButton, Button} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "./PrimaryButton";
import BtnSecondary from "./SecondaryButton";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const TextBox = styled(Box)({
    display: "block",
});

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
  });
  
function DateSelectDialog({ open, onClose, onSelect}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleContinue = () => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    onSelect(formattedDate);
  };

  function CustomCalendarHeader(props) {
    const { currentMonth, onMonthChange } = props;
    const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectPreviousMonth = () => onMonthChange(currentMonth.subtract(1, 'month'), 'right');
  
    return (
      <CustomCalendarHeaderRoot sx={{width:"100%"}}>
          <IconButton onClick={selectPreviousMonth} title="Previous month">
            <ChevronLeft />
          </IconButton>
          <TextBox sx={{fontSize: "1rem", lineHeight: "1.5rem", color:"#0081FF"}}>
            {currentMonth.format('MMMM YYYY')}
          </TextBox>
          <IconButton onClick={selectNextMonth} title="Next month">
            <ChevronRight />
          </IconButton>
       </CustomCalendarHeaderRoot>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={()=>{onClose()}} 
      PaperProps={{
        sx: {
          borderRadius: "12px",
          padding: "1.5rem",
        }
      }}
    >
        <TextBox sx={{width:"100%", textAlign:"center", fontSize: "1rem", lineHeight: "1.5rem", fontWeight:"500", color: "#3A3A3A", marginBottom: "1.5rem" }}>
          Select Date
        </TextBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            slots={{ calendarHeader: CustomCalendarHeader }}
            onChange={handleDateChange}
            value={selectedDate}
            showDaysOutsideCurrentMonth
            // disablePast
            disableHighlightToday
            dayOfWeekFormatter={(_day, weekday) => `${weekday.format('ddd')}`}
            sx={{
              width: "100%",
              border: "none",
              padding: 0,
              "& .MuiPickersToolbar-root": {
                display: "none"
              },
              "& .MuiDialogActions-root": {
                display: "none"
              },
              "& .MuiPickersLayout-contentWrapper":{
                borderRadius:"12px",
                border:"1px solid #D7D7D7",
                boxShadow: "0px 4px 40px 0px #6363631A",
              },
              "& .MuiDayCalendar-root":{
                width:"320px"
              },
              "& .MuiTypography-root":{
                fontSize:"0.75rem",
                lineHeight:"1rem",
                marginInline:"0.52rem",
                marginBottom:"0.1rem",
                color:"#8F9BB3"
              },
              "& .MuiButtonBase-root":{
                fontSize:"0.75rem",
                lineHeight:"1rem",
                marginInline:"0.42rem",
                marginBottom:"0.1rem",
              },
            }}
          />
        </LocalizationProvider>
        <Box style={{display:"flex", gap:"1.5rem", justifyContent:"space-between", width:"100%", marginTop:"1.5rem"}}>
           <BtnPrimary onClick={handleContinue} sx={{flex:"1", height: '3rem', padding: '1rem', borderRadius: '4px' }}>Apply</BtnPrimary>
           <BtnSecondary onClick={onClose} sx={{ flex:"1", height: '3rem', padding: '1rem', borderRadius: '4px' }}>Cancel</BtnSecondary>
        </Box>
    </Dialog>
  );
}

export default DateSelectDialog;

