import React from "react";
import { Box} from "@mui/material";
import { Outlet } from "react-router";

export default () => {

  return (
    <Box sx={{width:"100%"}}>
         <Outlet/>     
    </Box>
  );
};
