import { postCall, getCall,  putCall  } from "./api";

// trips policy

export const getAllTripsPolicy = async (legalUnit) => {
    console.log(legalUnit);
    const request = {
      path: `/policy/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
};

export const createTripPolicy = async (input = {}) => {
  const request = {
    path: `/policy`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const updateTripPolicy = async (policyId, input = {}) => {
  const request = {
    path: `/policy/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};

// add approver to policy

export const addApprovers = async (policyId, input = {}) => {
  const request = {
    path: `/policy/${policyId}`,
    bodyParams: input,
  };
  return await putCall(request);
};


  // vehicle type

  export const createVehicleType = async (input = {}) => {
    const request = {
      path: `/policy-cateogary`,
      bodyParams: input,
    };
    return await postCall(request);
  };
  
  export const getAllVehicleType = async (legalUnit) => {
    const request = {
      path: `/policy-cateogary/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
  };

  export const getVehicleTypeById = async (id) => {
    const request = {
      path: `/policy-cateogary/id/${id}`,
    };
    return await getCall(request);
  };

  export const updateVehicleType = async (id,input = {}) => {
    const request = {
      path: `/policy-cateogary/${id}`,
      bodyParams: input
    };
    return await putCall(request);
  };

  // Get all trips of organisation

  export const getAllTrips = async () => {
    const request = {
      path: `/trip/organization`,
    };
    return await getCall(request);
  };

  // Get trip by trip id

  export const getTripById = async (tripId) => {
    const request = {
      path: `/trip/${tripId}`,
    };
    return await getCall(request);
  };

  export const getTrip = async () => {
    const request = {
      path: `/expense/trip`,
    };
    return await getCall(request);
  }