import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import PaginationTable from '../../../../../../component/PaginationTable';
import { getAllLeavePolicy } from '../../../../../../services/leave';
import { useNavigate } from 'react-router';
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import PolicyListingHeader from "../../../../../../component/PolicyListingHeader";
import { PolicyTableCell } from "../../../../../../styles/PolicyTableCell";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";

const PolicyListing = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await getAllLeavePolicy();
          const filteredData = res.data.filter(data => data.createdBy !== undefined);
          const employeeIds = filteredData.map(data => data.createdBy);
          const combinedData = await getEmployeeNames(res.data, employeeIds,"createdBy");
          setData(combinedData.reverse()); 
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
    
      fetchData();
    }, []);

    const handleMenuItemClick = (id) => { 
      navigate("/app/leave-policy/edit",{state:{policyId:id}});
    };
  
    const headings = [
      { label: "Code", style: {...PolicyTableHeadings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Policy Name", style: {...PolicyTableHeadings} },
      { label: "Created by", style: {...PolicyTableHeadings} },
      { label: "Created on", style: {...PolicyTableHeadings} },
      { label: "Category attached", style: { ...PolicyTableHeadings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <Box>
        <PolicyListingHeader 
          Heading = "Leave Policies"
          ButtonText = "+ Create Leave Policy"
          ButtonLink = "settings"
        />
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell>{row.code}</PolicyTableCell>
              <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                      {row.name}
                </Box>
              </PolicyTableCell>
              <PolicyTableCell>{row?.employee?.fname+" "+row?.employee?.lname}</PolicyTableCell>
              <PolicyTableCell>{new Date(row.createdAt).toLocaleDateString('en-GB')}</PolicyTableCell>
              <PolicyTableCell>{row.leaveQuantities.length}</PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default PolicyListing