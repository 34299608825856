import React,{useState} from 'react';
import {Box} from '@mui/material';
import ActiveBar from "../../../../../../../../../component/PolicyNavbarUpdated";
import BtnSecondary from '../../../../../../../../../component/SecondaryButton';
import SortBy from './SortBy';
import CreateGroup from '../../../../../../../../../component/CreateGroup';
import TopBar from '../../../../../../../../../component/TopBar';
import MainBox from '../../../../../../../container/MainBox';

const AssignShift = () => {

  const [page, setPage] = useState("sortBy");

  const loadNextPage = (p) => {
    setPage(p);
  };

  const commonProps = {
    loadNextPage,
  };

  const sortByProps = {
    ...commonProps,
  };

  const createGroupProps = {
    ...commonProps,
    redirectLink: "/app/shift/new/assign-shift", 
  };


  const mapPages = {
    sortBy: (props) => <SortBy {...sortByProps} />,
    createGroup: (props) => <CreateGroup {...createGroupProps} />,
  };

  const ShowPage = mapPages[page];
 
  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <TopBar 
            MainHeading={"Shift Creation"}
            breadcrumbs={[{label:"Create Shift", href:""},{label:"Shift Details", href:""}, {label:"Assign Shift", href:""}]}
      />
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar page="shift" active={["Shift Details","Assign Shift"]} value="32"/>
              <BtnSecondary 
                sx={{width:"10rem", height:"2.25rem", borderRadius:"10px"}} 
                onClick={()=>{page==="sortBy"?loadNextPage("createGroup"):loadNextPage("sortBy")}}
              >
                  {page==="sortBy"?"+ Create Group":"Back"}
              </BtnSecondary>
          </Box>
          <Box>
            <ShowPage/>
          </Box> 
      </MainBox>
    </Box>
    )
}

export default AssignShift;