import React from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { Outlet } from "react-router";

function VehicleType() {
  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Vehicle Type"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Outlet/>
        </MainBox>
     </Box>
      
  );
}

export default VehicleType;
