import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const AddDocument = createSvgIcon(
    <svg
      width="14"
      height="18"
      viewBox="0 -3 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g id="Icon">
           <path d="M5 11.5312H11M8 8.53125L8 14.5312M13 19.5312H3C1.89543 19.5312 1 18.6358 1 17.5312V3.53125C1 2.42668 1.89543 1.53125 3 1.53125H8.58579C8.851 1.53125 9.10536 1.63661 9.29289 1.82414L14.7071 7.23836C14.8946 7.42589 15 7.68025 15 7.94546V17.5312C15 18.6358 14.1046 19.5312 13 19.5312Z" 
                 stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
      </g>
    </svg>
  );

  return <AddDocument />;
}
