import { postCall, getCall } from "./api";

// Create Holidays

export const createHolidays = async (input = {}) => {
  const request = {
    path: `/holiday`,
    bodyParams: input,
  };
  return await postCall(request);
};

// Get Holidays

export const getAllHolidays = async () => {
  const request = {
    path: `/holiday`,
  };
  return await getCall(request);
};
