import React, { Suspense } from "react";
import Loader from "../component/Loader";
const HomePage = React.lazy(() => import("../app/home"));

function Login() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <HomePage />
      </Suspense>
    </>
  );
}

export default Login;
