import React,{useState, useEffect} from 'react';
import { Box,TableCell} from "@mui/material";
import styled from '@emotion/styled';
import PaginationTable from '../../../../../component/PaginationTable';
import { useNavigate } from "react-router-dom";
import TopBar from '../../../../../component/TopBar';
import MainBox from '../../../container/MainBox';
import TableHeader from '../../../../../component/TableHeader';
import { getEmployeeExpense } from '../../../../../services/expense';
import PolicyListingHeader from '../../../../../component/PolicyListingHeader';

const CustomTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  padding: "1rem",
  textAlign:"center",
});

const TripsPolicyListing = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [order,setOrder] = useState("desc");
    
    useEffect(() => { 
      fetchData();
    }, []);

    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getEmployeeExpense();
        console.log(res);
        setData(res?.data.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };  

    const Headings = {
      fontWeight: "300",
      fontSize: "1rem",
      color: "#000000",
      lineHeight: "1.5rem",
      padding: "1rem",
      padding: "1.375rem 1rem",
      backgroundColor: "#F5F5F5",
      borderBottom: "none",
      textAlign:"center"
    }
  
    const headings = [
      { label: "Id", style: {...Headings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Category", style: {...Headings} },
      { label: "Date", style: {...Headings} },
      { label: "Amount", style: {...Headings} },
      { label: "Status", style: { ...Headings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <Box sx={{width:"100%"}}>
      <TopBar MainHeading={"Expenses"} SubHeading={"Expenses > My Expenses"}/>
      <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
        <PolicyListingHeader 
          Heading = "My Expenses"
          ButtonText = "+  Add New Expense"
          ButtonLink = "/app/employee-expense/add-new"
        />
        <TableHeader data={data} setDate={setDate} setOrder={setOrder}/>
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <CustomTableCell 
                // sx={{":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}} 
                // onClick={() => handleMenuItemClick(row.id)}
              >
                {row?.id}
              </CustomTableCell>
              <CustomTableCell>{row?.policyCateogary?.name}</CustomTableCell>
              <CustomTableCell>{row?.date}</CustomTableCell>
              <CustomTableCell>{row?.amount}</CustomTableCell>
              <CustomTableCell>
                  <Box sx={{color: row?.status === "rejected" ? "#D62525" : row?.status ==="pending"? "#F99500" :"#069855"}}>
                    {row?.status}
                  </Box>
              </CustomTableCell>
            </>
          )}
        />
       </MainBox>
    </Box>
  )
}

export default TripsPolicyListing