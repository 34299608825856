import { postCall, getCall, putCall } from "./api";

//type="TRIP"

export const getAllVehicleType = async (legalUnit) => {
    const request = {
      path: `/policy-cateogary/${legalUnit}?type=TRIP`,
    };
    return await getCall(request);
};

//type="ANY"

export const getAllExpenseCategory = async (legalUnit) => {
  console.log(legalUnit);
  const request = {
    path: `/policy-cateogary/${legalUnit}?type=ANY`,
  };
  return await getCall(request);
};