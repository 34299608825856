import React, { useState, useEffect } from 'react';
import { Box,TableCell } from '@mui/material';
import PaginationTable from '../../../../../component/PaginationTable';
import TopBar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";
import { getAllExpense} from '../../../../../services/expense';
import { getEmployeeNames } from '../../../../../utils/getEmployeeNames';
import TableHeader from '../../../../../component/TableHeader';

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const AllEmployeePayroll = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB')); 
    const [order,setOrder] = useState("desc");
    const [data, setData] = useState([]);
    const [open,setOpen] = useState(false);
    const [filteredData,setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
      filteredData.reverse();
    },[order]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);
    
    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""?item:item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);

      const headings = [
        { label: "Date", style: {...Headings, width:"4.25rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Employee Name", style: {...Headings, width:"10rem"} },
        { label: "Employee Id", style: {...Headings, width:"10rem"} },
        { label: "Paid Days", style: {...Headings, width:"10rem"} },
        { label: "Gross Pay", style: {...Headings, width:"7.5rem"} },
        { label: "Net Pay", style: {...Headings, width:"7.5rem"} },
        { label: "Pay Slip", style: {...Headings, width:"7.5rem"} },
        { label: "Status", style: { ...Headings, width:"8.68rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
              setLoading(false);
        }catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      },[])


    return (
        <Box sx={{ width: "100%", paddingBlock:"1.25rem"}}>
            <TopBar 
              MainHeading={"Payroll"}
            />
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
            <Box></Box>
                 <TableHeader 
                   data={data} 
                   setDate={setDate} 
                   setOrder={setOrder}
                   searchQuery={searchQuery}
                   handleSearch={(value)=>setSearchQuery(value)}
                 />
                  <PaginationTable
                      rows={filteredData}
                      headings={headings}
                      loading={loading}
                      noRecordMessage={"No Record Found"}
                      renderCell={(row) => (
                        <>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem",  maxWidth:"6.43rem"}}
                            >
                            </TableCell>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"9rem", maxWidth: "9rem"}}
                          >
                          </TableCell>
                          <TableCell 
                            align="center"
                            sx={{...CustomTableCell, width:"6.43rem", maxWidth: "6.43rem",":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}} 
                          >
                          </TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}></TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}></TableCell>
                          <TableCell align="center" sx={{...CustomTableCell, width:"6.43rem"}}>
                                  <Box sx={{
                                            borderRadius:"4px", 
                                            padding:"4px 8px",
                                            textAlign:"center",
                                            backgroundColor: row.status === "pending" ? "#FFBE64" : (row.status === "rejected" ? "#E493931A" : "#0CBD7D"),
                                            color: row.status === "pending" ? "#212423" : (row.status === "rejected" ? "#E52E2E" : "#FFFFFF")
                                          }}>
                                      {row.status}
                                  </Box>
                          </TableCell>
                        </>
                      )}
                    />
            </MainBox>
        </Box>
    )
}

export default AllEmployeePayroll;
