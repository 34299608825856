import React from 'react'
import styled from "@emotion/styled";
import {Avatar, Box} from "@mui/material";
import InfoCard from './InfoCard';
import ChartPie from '../../../../../../../assets/icons/chart-pie.png';
import DoubleSquare from '../../../../../../../assets/icons/doubleSquare.png';
import { useOutletContext } from 'react-router';
import { getOrgDepartments } from '../../../../../../../services/globalData';

const Container = styled(Box)({
    borderRadius:"12px",
    padding:"1.5rem",
    border:"1px solid var(--Colors-Prime-Gray-Prime-Gray-300, #D1D5DB)",
 });
 
 const TextBox = styled(Box)({
    lineHeight:"1.5rem",
 })

const PersonalDetails = () => {
  const [employeeData] = useOutletContext();
  const department = getOrgDepartments().find(dep => dep._id === employeeData?.department)?.name;
  console.log(employeeData);
  
  const basicInfo = [
                      {label:"Profile Image",value:employeeData?.profileImage, field:"profileImage"},
                      {label:"First Name",value:employeeData?.fname, field:"fname"},
                      {label:"Last Name",value:employeeData?.lname, field:"lname"},
                      {label:"Employee Id",value:employeeData?.aliasId, field:"aliasId"},
                      {label:"Designation",value:employeeData?.designation, field:"designation"},
                      {label:"Department",value:department, field:"department"},
                      {label:"Date of Joining",value:employeeData?.joiningDate?.split("T")[0], field:"joiningDate"}
                   ]

  const personalInfo = [
                        {label:"Date of Birth",value:employeeData?.dob, field:"dob"},
                        {label:"Father's Name",value:employeeData?.fatherName, field:"fatherName"},
                        {label:"Mother's Name",value:employeeData?.motherName, field:"motherName"},
                        {label:"Mobile Number",value:employeeData?.phone, field:"phone"},
                        {label:"Nationality",value:employeeData?.nationality, field:"nationality"},
                        {label:"Visa Sponsorship",value:employeeData?.workAuthorisation, field:"workAuthorisation", options:["Yes","No"]},
                        {label:"Place Of Birth",value:employeeData?.placeOfBirth, field:"placeOfBirth"},
                        {label:"Residential Status",value:employeeData?.residentialStatus, field:"residentialStatus",
                         options:["Born or Naturalized Citizen","Resident or Green Card Holder","Temporary Non-Immigrant","Undocumented Immigrants"]},
                       ]

  const contactInfo = [
                        {label:"Phone Number",value:employeeData?.phone, field:"phone"},
                        {label:"Personal Email Id",value:employeeData?.email, field:"email"},
                        {label:"Present Address",value:employeeData?.presentStreet, field:"presentStreet"},
                        {label:"Pin Code",value:employeeData?.presentPincode, field:"presentPincode"},
                        {label:"Emergency Contact Name",value:employeeData?.emergencyContactName, field:"emergencyContactName"},
                        {label:"Emergency Contact Number",value:employeeData?.emergencyContactNumber, field:"emergencyContactNumber"},
                      ]

  const identityInfo = [
                        {label:"Permanent Account Number (PAN)",value:employeeData?.panNumber, field:"panNumber"},
                        {label:"UAN",value:employeeData?.uanNumber, field:"uanNumber"},
                        {label:"Aadhar Number",value:employeeData?.aadharNumber, field:"aadharNumber"},
                        {label:"IP Number",value:employeeData?.ipNumber, field:"ipNumber"},
                       ]

  const bankInfo = [
                     {label:"Account Holder Name",value:employeeData?.accholderName, field:"accholderName"},
                     {label:"Account Number",value:employeeData?.accNumber, field:"accNumber"},
                     {label:"IFSC Code",value:employeeData?.ifscCode, field:"ifscCode"},
                     {label:"Bank Name",value:employeeData?.bankName, field:"bankName"},
                     {label:"Branch Name",value:employeeData?.bankBranch, field:"bankBranch"},
                     {label:"Account Type",value:employeeData?.accType, field:"accType"},
                     {label:"Swift Code",value:employeeData?.swiftCode, field:"swiftCode"},
                   ]

  const modules = [
        {icon:ChartPie, name:"Payments, Expenses & Work Submissions",detail:"Review their submitted invoices and manage any payments"},
        {icon:ChartPie, name:"Timesheet",detail:"Review their submitted invoices and manage any payments"},
        {icon:DoubleSquare, name:"Time off",detail:"Review and approve time off requests"},
        {icon:DoubleSquare, name:"Payslips",detail:"Open and review payslips history"},
        {icon:DoubleSquare, name:"Documents",detail:"Verify compliance. See if required documents are uploaded"},
        {icon:DoubleSquare, name:"Equipment",detail:"Order, manage, and track vendor leased equipment"},
        {icon:DoubleSquare, name:"History",detail:"Open and review payslips history"},
  ]

  return (
    <Box sx={{width:"100%", display:"flex", gap:"1.5rem"}}>
               <Box sx={{display:"flex", flexDirection:"column", gap:"1.5rem", flex:1}}>
                      <InfoCard headingText="Basic Information" data={basicInfo} employeeId={employeeData?._id} name={employeeData?.fname+" "+employeeData?.lname}/>
                      <InfoCard headingText="Personal Information" data={personalInfo} employeeId={employeeData?._id} name={employeeData?.fname+" "+employeeData?.lname}/>
                      <InfoCard headingText="Contact Information" data={contactInfo} employeeId={employeeData?._id} name={employeeData?.fname+" "+employeeData?.lname}/>
                      <InfoCard headingText="Identity Information" data={identityInfo} employeeId={employeeData?._id} name={employeeData?.fname+" "+employeeData?.lname}/>
                      <InfoCard headingText="Bank Information" data={bankInfo} employeeId={employeeData?._id} name={employeeData?.fname+" "+employeeData?.lname}/>
               </Box>
               <Box sx={{display:"flex", flexDirection:"column", gap:"1rem", flex:1}}>
                 {
                    modules.map((item)=>{
                        return(
                            <Container sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                                <Avatar src={item.icon} alt="icon" sx={{height:"1.5rem", width:"1.5rem",borderRadius:"0"}}/>
                                <Box>
                                    <TextBox sx={{fontWeight:"500",color:"#000000",fontSize:"1rem"}}>
                                        {item.name}
                                    </TextBox>
                                    <TextBox sx={{fontSize:"0.75rem",color:"#4B5563"}}>{item.detail}</TextBox>
                                </Box>
                            </Container>
                        )
                    })
                 }
               </Box>
    </Box>
  )
}

export default PersonalDetails