import React, {useState} from 'react';
import MainBox from '../../../../../../container/MainBox';
import TopBar from '../../../../../../../../component/TopBar';
import {Autocomplete, Box,CircularProgress, TextField} from '@mui/material';
import ActiveBar from '../../../../../../../../component/PolicyNavbarUpdated';
import BtnSecondary from "../../../../../../../../component/SecondaryButton";
import BtnPrimary from "../../../../../../../../component/PrimaryButton";
import ErrorMsg from '../../../../../../../../component/ErrorMsg';
import FormField from '../../../../../../../../component/Formfield';
import { useNavigate } from 'react-router';
import { createShift, getAllShift } from '../../../../../../../../services/shift';
import SetShift from './SetShift';
import { getLegalUnitList, setOrgShifts } from '../../../../../../../../services/globalData';

const initialDayState = {
  isHoliday: true,
  startTime: undefined,
  endTime: undefined,
  breakTime:0,
  hoursMetric: "gross",
  minHalfDayHours: 0,
  minFullDayHours: 0,
  maxHalfDayHours: 0,
  maxFullDayHours: 0,
};

const ShiftDetails = () => {

    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();

    const [shiftData,setShiftData] = useState({
      code:"",
      name:"",
      days: Array(7).fill(initialDayState),
      legalUnit:"",
      doesScheduleVary: false,
      flexibleWorkingHours: false,
    });

    const [validationErrors, setValidationErrors] = useState({code:"",name:"", legalUnit:""});
    const [workingDays, setWorkingDays] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [error,setError]=useState("");
    const [selectedLocation, setSelectedLocation]=useState("");
    const legalUnits = getLegalUnitList()?.map(legalUnit => ({
      id: legalUnit?._id,
      label: legalUnit?.name
    }));


    const onLocationChange = (value) =>{
      setSelectedLocation(value);
      setShiftData(prevState => ({
        ...prevState,
        legalUnit: value?.id
      }));
    }
  
   const handleNextClick = async() => {
        const errors ={};
  
        if (!shiftData.code) errors.code="Code is required"
        if (!shiftData.name) errors.name="Name is required"
        if (!shiftData.legalUnit) errors.legalUnit="Location is required"

        console.log(workingDays);
        console.log(holidays);
        
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }

        console.log(shiftData);
        setApiCall(true);
        try {
          const res = await createShift(shiftData);
          console.log(res);
          if(res.success){
            if(res.success){
              const shifts = await getAllShift();
              console.log(shifts);
              setOrgShifts(shifts?.data);
            }
            navigate("/app/shift/new/assign-shift",{state:{policyId: res?.data?._id}});
          }else{
            setError("Shift Creation Failed");
          }
        } catch (error) {
            console.error('Error creating Shift:', error);
        }
        setApiCall(false);
    };
  
    const handleChange = (field, value) => {
      const updatedValue = field === "code" ? value?.toUpperCase() : value;
      setShiftData(prevState => ({...prevState, [field]: updatedValue}));
      setValidationErrors(prevState => ({...prevState, [field]: ""}));
      setError("");
    };
  
  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
            MainHeading={"Shift Creation"}
            breadcrumbs={[{label:"Create Shift", href:""},{label:"Shift Details", href:""}]}
        />
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                <ActiveBar page="shift" active={["Shift Details"]} value="16"/>
                <BtnSecondary to={"/app/shift/listing"} sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}>
                    Back
                </BtnSecondary>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <FormField
                            label="Shift Code"
                            type="text"
                            value={shiftData.code}
                            validation={validationErrors.code}
                            placeholder="e.g. E099"
                            onChange={(e) => handleChange('code', e.target.value)}
                        />
                        <FormField
                            label="Name of the Shift"
                            type="text"
                            value={shiftData.name}
                            validation={validationErrors.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                        <SetShift 
                           shiftData={shiftData}
                           setShiftData={setShiftData}
                           workingDays={workingDays}
                           setWorkingDays={setWorkingDays}
                           holidays={holidays}
                           setHolidays={setHolidays}
                           initialDayState={initialDayState}
                        />
                        <Box sx={{width:"48%"}}>
                           <Box sx={{ fontSize: '1rem',lineHeight: '1.5rem',color: '#818181',marginBottom: '4px'}}>
                            Shift Location
                           </Box>
                           <Autocomplete
                            options={legalUnits}
                            size="small"
                            value={selectedLocation}
                            onChange={(e,newValue)=>onLocationChange(newValue)}
                            sx={{
                              '& .MuiInputBase-root':{
                                 borderRadius: '10px',
                                 fontSize: '1rem',
                                 lineHeight: '1.5rem',
                                 fontWeight:"300",
                                 color: '#16151CCC',
                                 height:"3rem",
                                },
                                "& .MuiOutlinedInput-notchedOutline":{
                                  border: '1px solid #A2A1A833'
                                }
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                          />
                          <ErrorMsg>{validationErrors?.legalUnit}</ErrorMsg>
                        </Box>
            </Box>
            <ErrorMsg>{error}</ErrorMsg>
            <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    onClick={handleNextClick} 
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    disabled={apiCall}
                >
                    {apiCall?<CircularProgress/>:"Next"}
                </BtnPrimary> 
            </Box>
        </MainBox>
    </Box>
    )
}

export default ShiftDetails;