import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import {Box,Avatar, IconButton, Popover,MenuItem} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getOnLoginEmployeeInfo } from '../services/globalData';

const TextBox = styled(Box)({
    display: "block",
    wordBreak: "break-word",
    width: "100%",
  });


export default function ProfileComponent() {
  const [userDetails, setUserDetails] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(()=>{
    const employeeInfo = getOnLoginEmployeeInfo();
    setUserDetails(employeeInfo.employee);
  },[])

  const onLogout = () => {
      sessionStorage.clear();
      window.location.pathname = "/login";
  };

  return (
    <Box 
       sx={{
         display:"flex", 
         alignItems:"center",
         gap:"0.625rem",
         border: '1px solid #A2A1A833', 
         borderRadius: '8px', 
         height:"3.43rem",
         padding:"5px"
       }}
    > 
      <Box sx={{display:"flex", alignItems:"center", gap:"5px", height:"100%", flex:1}}>
            <Avatar 
              alt="User Image" 
              sx={{
                  width: '2.5rem', 
                  height: '2.5rem', 
                  borderRadius: '8px', 
                }}
            />
            <Box>
                <TextBox fontSize={"1rem"} fontWeight={600}>
                     {userDetails ? `${userDetails.fname} ${userDetails.lname}` : 'Loading...'}
                </TextBox>
                <TextBox fontSize={"0.75rem"} fontWeight={300} color={"#A2A1A8"}>{userDetails?.designation||""}</TextBox>
            </Box>
      </Box>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        sx={{
            width: '2rem',
            height: '2rem', 
            '&:hover': {
              cursor: 'pointer',
              bgcolor: '#F5F5F5'
            }
        }}
      >
        {open? <ExpandLessIcon/> :<ExpandMoreIcon/>}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '0.8rem',
              marginLeft: '-1.6rem'
            }
          }
        }}
      >
        <Box sx={{ p: 1,borderRadius:'4px'}}>
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Box>
      </Popover>
    </Box>
  );
}
