import React, { useState } from "react";
import { Box} from "@mui/material";
import {TabList} from "./tablist";
import Topbar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";
import TabbarMenuItem from "../../../container/TabbarMenuItem";
import TabBar from "../../../container/Tabbar";
import BottomPanel from "../../../container/BottomPanel";

function Settings(props) {

  const [activeTabKey, setActiveTabKey] = useState(2);

  function getActiveTab() {
    return TabList[activeTabKey];
  }

  const activeTab = getActiveTab();

  function onNavItemsClick(item) {
    const { key } = item;
    setActiveTabKey(key);
  } 

  const tabBarList = TabList.map((item) => {
    return (
      <TabbarMenuItem
        item={item}
        onItemClick={onNavItemsClick}
        activeTab={activeTab}
      />
    );
  });

  return (
    <Box sx={{width:"100%"}}>
        <Topbar/>        
        <MainBox>
            <TabBar>{tabBarList}</TabBar> 
            <BottomPanel controller={activeTab.controller} />
        </MainBox>
    </Box>
  );
}

export default Settings;
