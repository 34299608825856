import React,{useState} from 'react';
import {Box} from '@mui/material';
import {useLocation} from "react-router-dom";
import SelectSortBy from '../../../../../../../../component/assign-policy/SelectSortBy';
import ReviewSortBy from '../../../../../../../../component/assign-policy/ReviewSortBy';

const mapPages = {
    selectSortBy: SelectSortBy,
    reviewSortBy: ReviewSortBy,
};

const SortBy = () => {

  const {state} = useLocation();
  const [sortBy,setSortBy] = useState();
  const [selectedList,setSelectedList] = useState({department: [],employeeGroup:[]});
  const [page, setPage] = useState("selectSortBy");

  console.log(state);

  const loadNextPage = (p) => {
    setPage(p);
  };
  const ShowPage = mapPages[page];

  return (
      <Box sx={{width:"100%"}}>
            <ShowPage 
              loadNextPage={loadNextPage} 
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              policyId={state?.policyId}
              rulesPageLink={"/app/expense-policy/new/rules"}
              type="expense"
            />
      </Box>
    )
}

export default SortBy;