import React from 'react';
import { Dialog,Box, Button} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import { useNavigate } from 'react-router';

const TextBox = styled(Box)({
    display: "block",
});

function DeleteWarning({open, onClose, redirectLink}) {
  
  const navigate = useNavigate();

  const handleDelete = () =>{
      navigate(redirectLink);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: "0.75rem 1rem",
          display:"flex",
          flexDirection:"column",
          alignItems:"center",
          gap:"1rem",
        }
      }}
    >
            <TextBox sx={{fontWeight:"500", fontSize: "1.25rem", lineHeight: "1.5rem"}}>
               Are you sure you want to delete?
            </TextBox>
            <TextBox sx={{fontSize: "1rem", lineHeight: "1.5rem"}}>
               This item will be deleted immediately. You can't undo this action.
            </TextBox>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem", marginBlock: "1.875rem" }}>
                  <Button 
                    onClick={handleDelete} 
                    sx={{
                      width:"6.25rem", 
                      height: '3rem', 
                      padding: '1.25rem', 
                      borderRadius: '10px', 
                      textAlign: "center",
                      textTransform: "none", 
                      bgcolor:"#CC3100",
                      color:"white",
                      border:"1px solid #CC3100",
                      "&:hover":{
                        color:"#CC3100",
                        bgcolor:"white",
                      }
                  }}>
                      Delete
                </Button>
                <Button 
                    onClick={onClose} 
                    sx={{
                      width:"6.25rem", 
                      height: '3rem', 
                      padding: '1.25rem', 
                      borderRadius: '10px', 
                      textAlign: "center",
                      textTransform: "none", 
                      color:"#CC3100",
                      bgcolor:"white",
                      border:"1px solid #CC3100",
                      "&:hover":{
                        bgcolor:"#CC3100",
                        color:"white",
                      }
                  }}>
                      Cancel
                </Button>
            </Box>
    </Dialog>
  );
}

export default DeleteWarning;

