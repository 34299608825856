import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import DocumentUpload from "../assets/icons/document-upload.svg";

const fileTypes = ["JPG", "PNG", "GIF"];

function DragDrop({style,file, setFile}) {

  const handleChange = (file) => {
    console.log(file[0]);
    setFile(URL.createObjectURL(file[0]));
  };

  return (
        <FileUploader 
          handleChange={handleChange} 
          name="file" 
          types={fileTypes} 
          multiple={true}
        >
          <Box sx={style}>
            <Avatar src={DocumentUpload} style={{ width: '1.25rem', height: '1.25rem' }}/>
            <Box sx={{marginTop:"0.75rem"}}>
              <span style={{color:"#0081FF"}}>Click to Upload</span> or drag and drop
            </Box>
            <Box sx={{fontSize:"0.875rem", marginTop:"0.5rem"}}>
              {file ? `Files: ${file}` : null}
            </Box>
            <Box sx={{fontSize:"1rem", marginTop:"0.75rem"}}>
              (Max. File size: 25 MB)
            </Box>
          </Box>
        </FileUploader>
  );
}

export default DragDrop;