import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const GeneralSettings= createSvgIcon(
    <svg
      width="14"
      height="18"
      viewBox="0 -3 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g id="Icon">
          <path 
           fillRule="evenodd" 
           clipRule="evenodd" 
           d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 21C15.866 21 19 19.2091 19 17C19 14.7909 15.866 13 12 13C8.13401 13 5 14.7909 5 17C5 19.2091 8.13401 21 12 21Z" 
           fill={color}
          />
        </g>
      </g>
    </svg>
  );

  return <GeneralSettings/>;
}
