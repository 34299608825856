import React from 'react';
import PolicyRules from '../../../../../../../../component/PolicyRules';
import { Box } from '@mui/material';
import TopBar from '../../../../../../../../component/TopBar';
import MainBox from '../../../../../../container/MainBox';

const ShiftRules = () => {
 
  return ( 
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
      <TopBar 
        MainHeading={"Shift Creation"}
        breadcrumbs={[{label:"Shift Details", href:""},{label:"Create Shift", href:""}]}
      />
      <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
        <PolicyRules
           addNewLink="/app/attendance-policy/new/setting" 
           viewAllLink="/app/attendance-policy/listing" 
        />
      </MainBox> 
    </Box>    
  )
}

export default ShiftRules;