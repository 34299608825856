import React, { useState } from 'react'
import PaginationTable from '../../../../../../../component/PaginationTable'
import { useOutletContext } from 'react-router';
import { Box } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const Reimbursements = () => {
  const [CustomTableCell,Headings] = useOutletContext();
  const [data,setData] = useState([{
    name:"Fuel Reimbursement",
    type:"Fuel Reimbursement",
    maxReimbursibleAmount:"1000 per month",
    status:"Active"
  }]);

  const headings = [
    { label: "Name", style: {...Headings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Reimbursement Type", style: {...Headings} },
    { label: "Maximum Reimbursable Amount", style: {...Headings} },
    { label: "Status", style: {...Headings} },
    { label: "", style: { ...Headings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

  return (
    <PaginationTable
            rows={data}
            headings={headings}
            noRecordMessage={"No Record Found"}
            renderCell={(row) => (
                <>
                    <CustomTableCell>{row?.name}</CustomTableCell>
                    <CustomTableCell >{row.type}</CustomTableCell>
                    <CustomTableCell>{row.maxReimbursibleAmount}</CustomTableCell>
                    <CustomTableCell>
                    <Box sx={{
                          borderRadius: "8px",
                          color: "white",
                          width:"5rem",
                          backgroundColor: "#50c878",
                          padding: "3px 8px",
                          fontSize:"0.75rem",
                          lineHeight:"1.5rem",
                          textAlign:"center"
                        }}>
                          {row.status}
                     </Box>
                    </CustomTableCell>
                    <CustomTableCell>
                        <MoreVert/>
                    </CustomTableCell>
                </>
            )}
    />
  )
}

export default Reimbursements