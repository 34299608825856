import React,{useState} from 'react'
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';
import RadioButtonGroup from '../../../../../../component/RadioButtonGroup';
import MultiCheckBox from '../../../../../../component/MultiCheckbox';

const Timelog = () => {

  const [timelogData, setTimelogData] = useState({
     viewFreq: "",
     logTime: "Total Hours",
     editPermission: "",
     isShowLocation: true,
     isRestrictToEstimatedHr: true,
     isRestrictToPerDay: true,
     maxBillableHours: "",
     restrictFor: [],
     restrictIf: []
  });

  const restrictForOptions = [
    { value: 'Future Date(s)', label: 'Future Date(s)' },
    { value: 'Past Date(s)', label: 'Past Date(s)' },
    { value: 'Holidays', label: 'Holidays' },
    {value:'Unpaid Leave', label:'Unpaid Leave'},
    {value:'Paid Leave/Compensatory Off', label:'Paid Leave/Compensatory Off'},
  ];

  const restrictIfOptions = [
    {value:"Time log date is not within Job's start date and end date", label:"Time log date is not within Job's start date and end date"},
    {value:"The present date is not within Job's start date and end date", label:"The present date is not within Job's start date and end date"},
  ]

  const handleChange = (field, value) => {
    setTimelogData(prevState => ({
        ...prevState,
        [field]: value 
    }));
  };

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Time Log Settings :
        </Box>
        <Box>
            <FormField
                label="Time log view"
                type="select"
                name="viewFreq"
                value={timelogData.viewFreq}
                options={['weekly','monthly','yearly']}
                onChange={(e) => handleChange('viewFreq', e.target.value)}
            />
            <RadioButtonGroup
                label="Log Time Using"
                name="logTime"
                display="col"
                value={timelogData.logTime} 
                onChange={(e) => handleChange('logTime', e.target.value )}
                options={[
                    { value: 'Total Hours', label: 'Total Hours' },
                    { value: 'Start and End Time', label: 'Start and End Time' },
                    { value: 'Timer', label: 'Timer' }
                ]}
            />
            <FormField
                label="Timer Edit Permission"
                type="select"
                value={timelogData.editPermission}
                options={['self','hr']}
                onChange={(e) => handleChange('editPermission', e.target.value)}
            />
            <RadioButtonGroup
                label="Show Time Log Location"
                name="isShowLocation"
                display="col"
                value={timelogData.isShowLocation} 
                onChange={(e) => handleChange('isShowLocation', e.target.value)}
                options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ]}
            />
            <RadioButtonGroup
                label="Restrict the log time based on the estimated hours"
                name="isRestrictToEstimatedHr"
                display="col"
                value={timelogData.isRestrictToEstimatedHr} 
                onChange={(e) => handleChange('isRestrictToEstimatedHr', e.target.value)}
                options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ]}
            />
            <RadioButtonGroup
                label="Restrict time logs to 24 hours per day"
                name="isRestrictToPerDay"
                display="col"
                value={timelogData.isRestrictToPerDay} 
                onChange={(e) => setTimelogData("isRestrictToPerDay", e.target.value )}
                options={[
                    { value: 'true', label: 'Yes' },
                    { value: 'false', label: 'No' },
                ]}
            />
            <FormField
                label="Maximum billable log hours per day/week"
                type="text"
                name="maxBillableHours"
                value={timelogData.maxBillableHours}
                onChange={(e) => handleChange('maxBillableHours', e.target.value)}
            />
            {/* <MultiCheckBox
                label="Restrict time log(s) for"
                options={restrictForOptions}
                values={checkboxValues}
                onChange={(e) => handleChange('maxBillableHours', e.target.value)}
            /> */}
            
        </Box>
        <Box sx={{borderRadius:"4px", border:"1px solid #A2A1A8", bgcolor:"#FEF3C7", padding:"0.75rem",
                fontWeight:"300",fontSize:"0.875rem", lineHeight:"1.5rem"}}>
          Note: This setting is applicable only when start date or end date of Job are configured. Click here to know more.
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                   Next
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default Timelog