import React from 'react';
import PolicyRules from '../../../../../../../../component/PolicyRules';

const ExpensePolicyRules = () => {
 
  return (  
        <PolicyRules
           addNewLink="/app/trips/new/setting" 
           viewAllLink="/app/trips/listing" 
        />
  )
}

export default ExpensePolicyRules;