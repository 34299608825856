import React from 'react'
import {Box,Avatar} from "@mui/material"
import ProgressBar from './ProgressBar'

//Heading of different sections of all dashboards

const DashboardSectionHeading = ({icon, label}) => {
  return (
    <Box>
        <Box sx={{display:"flex", gap:"0.5rem"}}>
            <Avatar src={icon} style={{ width: '1.5rem', height: '1.5rem' }}/>
            <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                {label}
            </Box>
        </Box>
        <ProgressBar value={100}/>
    </Box>
  )
}

export default DashboardSectionHeading