import React, {useState, useEffect} from "react";
import {Box, Button, CircularProgress, TableCell} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import CardItem from "../../../../../../component/CardItem";
import OfficeBuilding from "../../../../../../assets/icons/office-building.svg";
import Logout from "../../../../../../assets/icons/logout.svg";
import People from "../../../../../../assets/icons/people.svg";
import Card from "../../../../container/Card";
import Ticket from "../../../../../../assets/icons/ticket.svg";
import Department from "../../../../../../component/Department";
import DashboardSectionHeading from "../../../../../../component/DashboardSectionHeading";
import TV from "../../../../../../assets/icons/tv.svg";
import { Link } from "react-router-dom";
import DropdownButton from "../../../../../../component/DropdownButton";
import { getOrgEmployees, getOrgShifts } from "../../../../../../services/globalData";
import { getAllLeaveRequests} from '../../../../../../services/leave';
import { getEmployeeDetails } from "../../../../../../utils/getEmployeeNames";
import PaginationTable from "../../../../../../component/PaginationTable";
import styled from '@emotion/styled';

export const StyledTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "0.8rem",
  lineHeight: "1.125rem",
  padding: "1rem 0rem",
  textAlign:"center",
  color:"#16151C",
});

const LeaveDashboard = () => {

  const [loading,setLoading] = useState(false);
  const shiftOptions = getOrgShifts()?.map((shift) => ({
      value: shift?._id,
      name: shift?.name,
  }));
  const [selectedShift, setSelectedShift] = useState(shiftOptions[0]?.value);
  const [leaveData, setLeaveData] = useState([]);

  useEffect(()=>{
    fetchLeaveData();
  },[]);

  const fetchLeaveData = async () => {
    setLoading(true);
    try {
      const res = await getAllLeaveRequests();
      if(res.success){
      const employeeIds = res.data.map(data => data.employeeId);
      const combinedData = await getEmployeeDetails(res.data, employeeIds, "employeeId");        
      const dataTable = combinedData.map((item)=>({
        _id: item?._id,
        noOfDays: item?.days?.length,
        employeeName:item?.employee?.fname+" "+item?.employee?.lname,
        leaveType: item?.leaveType,
        status:item?.status
     }));
     const reversedDataTable = dataTable.reverse();
     setLeaveData(reversedDataTable?.slice(0,3));
     }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };
 
  console.log(selectedShift);
  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Leave"} 
          breadcrumbs={[{label:"Leave", href:""},{label:"Dashboard", href:""}]}
        />
        <MainBox sx={{ padding: "2rem 3rem"}}>
          {
            loading?(
              <Box sx={{width:"100%",display:"flex", justifyContent:"center"}}>
                <CircularProgress/>
              </Box>
            ):(
              <>
                  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                    <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                        Dashboard
                    </Box>
                    <DropdownButton 
                      value={selectedShift} 
                      options={shiftOptions} 
                      onChange={(e)=>setSelectedShift(e.target.value)}
                    />
                </Box>
                <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap: "1.75rem"}}>
                      <CardItem icon={People} Heading={"Total employees present"} 
                          subHeading={new Date().toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}>
                        <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                            12
                        </Box>
                        <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                            Total employees : {getOrgEmployees()?.length}
                        </Box>
                      </CardItem>
                      <CardItem icon={OfficeBuilding} Heading={"Leave Type"}>
                        <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                            12
                        </Box>
                        <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                          
                        </Box>
                      </CardItem>
                      <CardItem icon={Logout} Heading={"Upcoming Holidays"}>
                        <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                            12
                        </Box>
                        <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                            
                        </Box>
                      </CardItem>
                </Box>
                <Box sx={{display:"flex", gap:"1.125rem", marginBlock:"1.125rem"}}>
                          <Card sx={{flex:"1", padding:"1.5rem", height:"16.5rem"}}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                                  <DashboardSectionHeading icon={TV} label={"Overview"}/>
                                  <Department/>
                            </Box>
                          </Card>
                          <Card sx={{flex:"1",height:"16.5rem", padding:"1.5rem"}}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                                    <DashboardSectionHeading icon={Ticket} label={"Leave Requests"}/>
                                    <Button 
                                    sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4F4F50", textTransform:"none", textDecoration:"underline"}}
                                    >
                                      <Link 
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                      to="/app/leave-requests"
                                      >
                                        more info
                                      </Link>
                                    </Button>
                              </Box>
                              <PaginationTable
                                customWidth="300px"
                                paginationHide
                                rows={leaveData}
                                noRecordMessage={"No Record Found"}
                                renderCell={(row) => (
                                  <>
                                    <StyledTableCell sx={{width:"8.62rem"}}>{row?.employeeName}</StyledTableCell>
                                    <StyledTableCell sx={{width:"8.62rem"}}>{}</StyledTableCell>
                                    <StyledTableCell sx={{width:"4.87rem"}}>{row?.noOfDays+" days"}</StyledTableCell>
                                    <StyledTableCell sx={{width:"5.56rem", display:"flex",justifyContent:"center"}}>
                                      <Box sx={{color:"#F99500",border:"1px solid #F99500", borderRadius:"6.6px", textAlign:"center",width:"100%"}}>
                                        {row?.status}
                                      </Box>
                                    </StyledTableCell>
                                  </>
                                )}
                              />
                          </Card>
                </Box>
              </>
            )
          }
        </MainBox>
     </Box>
  );
};

export default LeaveDashboard;
