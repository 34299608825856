import * as React from "react";
import { createSvgIcon } from "@mui/material";

export default function SvgIconChildren(props) {
  const { color } = props;
  const AccountAccess = createSvgIcon(
    <svg
      width="14"
      height="18"
      viewBox="0 -3 14 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon/Solid/view-grid">
        <g id="Icon">
          <path d="M13 7H5M13 7C15.2091 7 17 8.79086 17 11V17C17 19.2091 15.2091 21 13 21H5C2.79086 21 1 19.2091 1 17V11C1 8.79086 2.79086 7 5 7M13 7V5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5V7M11 14C11 15.1046 10.1046 16 9 16C7.89543 16 7 15.1046 7 14C7 12.8954 7.89543 12 9 12C10.1046 12 11 12.8954 11 14Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
      </g>
    </svg>
  );

  return <AccountAccess />;
}
