import React,{useState, useEffect} from 'react'
import {Box, Button, Checkbox, FormControlLabel} from '@mui/material';
import CheckboxField from '../../../../../../../../component/CheckboxField';
import SetSchedule from './SetSchedule';
import { calculateTime } from '../../../../../../../../utils/calculateTime';
import styled from "@emotion/styled";
import ErrorMsg from '../../../../../../../../component/ErrorMsg';

const TextBox = styled(Box)({
    fontSize:"1rem",
    lineHeight:"1.5rem"
});

const daysOfWeek = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const SetShift = ({shiftData, setShiftData, workingDays, setWorkingDays, holidays, setHolidays, initialDayState, disabled}) => {

    const [displaySetScheduleFields, setDisplaySetScheduleFields] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [workingDaysError,setWorkingDaysError]=useState("");
    const [holidaysError,setHolidaysError]=useState("");
   
    const renderCheckboxes = (type) => {
        return daysOfWeek.map((day, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={type === 'working' ? workingDays.includes(index) : holidays.includes(index)}
                onChange={() => handleCheckboxChange(index, type)}
                disabled={disabled}
              />
            }
            label={day}
          />
        ));
      };
    
      const handleCheckboxChange = (index, type) => {
        setWorkingDaysError('');
        setHolidaysError('');
    
        const updatedDays = [...shiftData.days]; 
    
        if (type === 'working') {
          if (holidays.includes(index)) {
            setWorkingDaysError(`${daysOfWeek[index]} is already set as a holiday.`);
            return;
          }
          setWorkingDays(prev => {
            if (prev.includes(index)) {
                updatedDays[index] = { ...initialDayState };
                return prev.filter(day => day !== index);
            } else {
                updatedDays[index] = { ...updatedDays[index], isHoliday: false };
                return [...prev, index];
            }
          });
        } else {
          if (workingDays.includes(index)) {
            setHolidaysError(`${daysOfWeek[index]} is already set as a working day.`);
            return;
          }
          setHolidays(prev =>
            prev.includes(index) ? prev.filter(day => day !== index) : [...prev, index]
          );
          updatedDays[index] = {...initialDayState};
        }
        setShiftData(prevState => ({ ...prevState, days: updatedDays }));
      };

      console.log(shiftData);
      
      useEffect(() => {
        setShiftData(prevState => ({
          ...prevState,
          days: Array(7).fill(initialDayState)
        }));
      }, [shiftData?.doesScheduleVary]);

  return (
    <Box sx={{width:"100%"}}>
        <Box sx={{marginBlock:"1.375rem"}}>
            <TextBox>Set Shift Timing</TextBox>
            <CheckboxField
                    label="Employees are working with flexible schedules, not restricted to specific hours."
                    checked={shiftData?.flexibleWorkingHours}
                    onChange={(isChecked) => setShiftData((prevData) => ({
                      ...prevData,
                      flexibleWorkingHours: isChecked,
                    }))}
                    labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                    disabled={disabled}
                />
        </Box>
        <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB", marginBottom:"1.375rem"}}>
            <Box sx={{marginBlock:"1.375rem"}}>
                <TextBox>Shift Working Days</TextBox>
                <CheckboxField
                        label="work schedule varies on a daily basis."
                        checked={shiftData?.doesScheduleVary}
                        onChange={(isChecked) => setShiftData((prevData) => ({
                          ...prevData,
                          doesScheduleVary: isChecked,
                        }))}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                        disabled={disabled}
                    />
                <Box sx={{display:"flex", justifyContent:"space-between"}}>
                {
                    daysOfWeek.map((day, index) => (
                    <Box sx={{ display: "flex", flexDirection: "column" }} key={index}>
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={workingDays.includes(index)}
                            onChange={() => handleCheckboxChange(index, "working")}
                            disabled={disabled}
                            />
                        }
                        label={day}
                        />
                        {(shiftData?.doesScheduleVary && workingDays.includes(index)) && (
                        <Button
                            onClick={() => {
                              setSelectedIndex(index);
                              setDisplaySetScheduleFields(true);
                            }}
                            disabled={disabled}
                            sx={{
                              textTransform: "none",
                              fontWeight: "300",
                              fontSize: "0.75rem",
                              lineHeight: "1.125rem",
                              textDecoration: "underline",
                              color: "#0081FF"
                            }}
                        >
                            {shiftData?.days[index]?.minFullDayHours === 0 && shiftData?.days[index]?.startTime === 0
                            ? "Set Schedule"
                            : (shiftData?.flexibleWorkingHours
                                ? shiftData?.days[index]?.minFullDayHours + " hours"
                                : calculateTime(shiftData?.days[index]?.startTime) + " - " + calculateTime(shiftData?.days[index]?.endTime))}
                        </Button>
                        )}
                    </Box>
                    ))
                }
                </Box>
                <ErrorMsg>{workingDaysError}</ErrorMsg>
                {
                (shiftData?.doesScheduleVary && displaySetScheduleFields) && (
                    <SetSchedule
                      disabled={disabled}
                      shiftData={shiftData}
                      setShiftData={setShiftData}
                      workingDays={workingDays}
                      selectedIndex={selectedIndex}
                    />
                )
                }
                {
                !shiftData?.doesScheduleVary && (
                    <SetSchedule
                      disabled={disabled}
                      shiftData={shiftData}
                      setShiftData={setShiftData}
                      workingDays={workingDays}
                      selectedIndex={selectedIndex}
                    />
                )
                }
            </Box>                 
        </Box>   
        <TextBox sx={{marginBlock:"1.375rem"}}>Set Shift Week Off</TextBox>
        <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB", marginBottom:"1.375rem"}}>
                <TextBox>Week off days</TextBox>
                <Box sx={{display:"flex", justifyContent:"space-between"}}>
                {renderCheckboxes('holiday')}
                </Box> 
                <ErrorMsg>{holidaysError}</ErrorMsg>      
        </Box>                        
    </Box>
  )
}

export default SetShift