import React from "react";
import { Button, Avatar } from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const PrimaryBtn = styled(Button)({
  background: "#0081FF",
  color: "white",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  letterSpacing: "0.19px",
  whiteSpace: "nowrap",
  textAlign: "center",
  textTransform: "none",
});

const PrimaryButton = (props) => {
  const { onClick, disabled, startIcon, children, sx, to } = props;

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  if (to) {
    return (
      <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
        <PrimaryBtn
           sx={{
            ...sx,
            "&:hover": {
              background: "white",
              color: "#008CC4",
              border: "1px solid #008CC4",
            },
          }}
          startIcon={startIcon?<Avatar src={startIcon} style={{ width: '1.25rem', height: '1.25rem' }}/>:null}
        >
          {children || "click me..."}
        </PrimaryBtn>
      </Link>
    );
  }

  return (
    <PrimaryBtn 
     onClick={handleOnClick}
     disabled={disabled}
     sx={{
       ...sx,
       "&:hover": {
         background: "white",
         color: "#008CC4",
         border: "1px solid #008CC4",
       },
     }}
     startIcon={startIcon?<Avatar src={startIcon} style={{ width: '1.25rem', height: '1.25rem' }}/>:null}
    >
      {children || "click me..."}
    </PrimaryBtn>
  );
};

export default PrimaryButton;
