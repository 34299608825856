import React, { useState, useEffect} from 'react';
import { Dialog, DialogContent, DialogActions,Select, MenuItem } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { styled } from '@mui/material/styles'; 
import { PickersDay } from '@mui/x-date-pickers/PickersDay'; 
import BtnPrimary from "../../../../../component/PrimaryButton";
import dayjs from 'dayjs';

const HighlightedDay = styled(PickersDay)(() => ({
  "&.Mui-selected": {
    backgroundColor: "#008CC4",
    color: "white",
  },
}));

function DialogBox({ open, onClose, onSelect,currentStartDate, currentEndDate, isHalfDay }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([]);

  useEffect(() => {
    if (currentStartDate && (!isHalfDay)) {
      setHighlightedDays([dayjs(currentStartDate).format('YYYY-MM-DD')]);
    } else {
      setHighlightedDays([]);
    }
  }, [currentStartDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleContinue = () => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    onSelect(formattedDate);
  };

  const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  
    const isSelected =
      !props.outsideCurrentMonth &&
      (highlightedDays.includes(day.format("YYYY-MM-DD")));

    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          boxShadow: "0px 4px 20px 0px #18181833",
          padding: "1.5rem"
        }
      }}
    >
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            onChange={handleDateChange}
            value={selectedDate}
            minDate={currentStartDate? dayjs(currentStartDate):null}
            maxDate={currentEndDate? dayjs(currentEndDate) :null}
            showDaysOutsideCurrentMonth
            disablePast
            disableHighlightToday
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                  highlightedDays,
                  sx: {
                      "&.css-xzgz41-MuiButtonBase-root-MuiPickersDay-root": {
                          borderRadius: "none",
                      }
                  }
              },
            }}
            sx={{
              width: "100%",
              border: "none",
              padding: 0,
              "& .MuiPickersToolbar-root": {
                display: "none"
              },
              "& .MuiDialogActions-root": {
                display: "none"
              },
            }}
          />
        </LocalizationProvider>
        {}
      </DialogContent>
      <DialogActions>
        <BtnPrimary onClick={handleContinue} sx={{ padding: '1rem 0.75rem', borderRadius: '4px', width: '100%' }}>
          Continue
        </BtnPrimary>
      </DialogActions>
    </Dialog>
  );
}

export default DialogBox;

