import React from 'react'
import {Box, Avatar} from "@mui/material";
import BtnSecondary from "./SecondaryButton";
import ProgressBar from './ProgressBar';
import DocsIcon from '../assets/icons/docs.svg';

const PolicyListingHeader = ({Heading, ButtonText, ButtonLink}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.9375rem", alignItems:"end" }}>
             <Box>
                <Box sx={{display:"flex"}}>
                   <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                   <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                      {Heading}
                   </Box>
                </Box>
                <ProgressBar value={100}/>
             </Box>
             <BtnSecondary
               to={ButtonLink}
               sx={{ borderRadius: "10px",paddingBlock:"1.125rem", height: "3rem" }}
             >
               {ButtonText}
             </BtnSecondary>
    </Box>
  )
}

export default PolicyListingHeader