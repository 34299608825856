import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { TextField, Box, Autocomplete, CircularProgress} from "@mui/material";
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import { createEmployee, addEmployeeToLegalUnit, sendEmployeeOnboardingEmail, getAllEmployee } from "../../../../../../services/employee";
import { useNavigate } from "react-router";
import { getOrgEmployees, getOrgDetails, setOrgEmployees, getLegalUnitList} from "../../../../../../services/globalData";
import {getDepartments} from "../../../../../../services/department";
import { AutocompleteField, FormField, DateField } from "../../../../../../component/OnboardingFields";
import ErrorMsg from "../../../../../../component/ErrorMsg";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import "../../../../../../styles/PhoneInput.css"

const TextBox = styled(Box)({
  display: "block",
  wordBreak: "break-word",
  fontSize: "1rem",
  fontWeight: "500",
  color:"#5C5C5C",
  lineHeight:"1.5rem",
  marginTop: ".7812rem",
});

const btnStyle={
  height: "3.25rem",
  width: "13.75rem",
  padding: "1rem",
  borderRadius: "4px",
}

const InputStyle = {
  fontSize: "1rem", 
  lineHeight: '1.5rem',
  backgroundColor: "#F5F8FF",
  borderRadius: "4px",
  padding: "1"
}

const PhoneInputStyle = {
  flagDropdown: {
    backgroundColor: "#F5F8FF",
    borderRadius: "4px",
    border: "1px solid #E7E7ED",
  }
}

export default function EmployeeForm() {

  const navigate = useNavigate();
  const [employeeForm, setEmployeeForm] = useState({
    fname:"",
    lname:"",
    designation:"",
    email:"",
    location:"",
    reportingTo:"",
    department:"",
    phone:"",
    onboardingType:"",
    employmentType:""
  });
  const [reportingTo,setReportingTo] = useState(null||{id:"",label:""})
  const [selectedDepartment,setSelectedDepartment] = useState(null);
  const [selectedLocation,setSelectedLocation] = useState(null);
  const [departments,setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error,setError] = useState("");
  const [contact,setContact] = useState("");
  const [isDateDialogOpen, setIsDateDialogOpen] = useState(null);

  useEffect(()=>{
    fetchDepartments();
  },[])

  const fetchDepartments = async () => {
        try {
          const res = await getDepartments();
          console.log(res);
          const dept = res?.data?.map(department => ({
            id: department._id,
            label: department.name,
          }));
          setDepartments(dept);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  }

  const employees = getOrgEmployees()?.map(employee => ({
    id: employee._id,
    label: employee.fname+" "+employee.lname
  }));

  const legalUnits = getLegalUnitList()?.map(legalUnit => ({
    id: legalUnit._id,
    label: legalUnit.name
  }));

  const onValueChange = (e, label) => {
    const value = e.target.value;
    setEmployeeForm(prevState => ({
      ...prevState,
      [label]: value
    }));
  };

  const onReportingToChange = (value) =>{
        console.log(value);
        setReportingTo(value);
        setEmployeeForm(prevState => ({
          ...prevState,
          reportingTo: value?.id
        }));
  }

  const onDepartmentChange = (value) =>{
    console.log(value);
    setSelectedDepartment(value);
    setEmployeeForm(prevState => ({
      ...prevState,
      department: value?.id
    }));
}

  const onLocationChange = (value) =>{
    console.log(value);
    setSelectedLocation(value);
    setEmployeeForm(prevState => ({
      ...prevState,
      location: value?.id
    }));
  }

  const onContactChange = (value) =>{
    setContact(value);
    setEmployeeForm(prevState => ({
      ...prevState,
      phone: value
    }));
  }

  const onDateChange = (value) =>{
    setEmployeeForm(prevState => ({
      ...prevState,
      joiningDate: value
    }));
  }

  const submit = async () => {
    setLoading(true);
    console.log(employeeForm);
    const res = await createEmployee(employeeForm);
    console.log(res);
    if (res.success) {
      const employeeId = res.data._id;
      await addEmployeeToLegalUnit(employeeId);
      const result = await getAllEmployee();
      console.log(result);
      setOrgEmployees(result.data);
      const sendMail = await sendEmployeeOnboardingEmail(employeeId);
      console.log(sendMail);
      if (sendMail.success) {
        sessionStorage.setItem("employeeId", employeeId);
        navigate("/app/employees/onboarding-invite")
      }
    }else{
      if (res.message==="Validation Failed"){
        setError("Fields must not be empty");
      }else{
        setError(res.message);
      }
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box
        width={"100%"}
        display={"flex"}
        flexWrap={"wrap"}
        marginBlock={"1.5rem"}
        justifyContent={"space-between"}
      >

        <FormField
           label="First Name"
           type="text"
           width="48%"
           value={employeeForm.fname}
           onChange={(e) => onValueChange(e, "fname")}
        />
        <FormField
           label="Last Name"
           type="text"
           width="48%"
           value={employeeForm.lname}
           onChange={(e) => onValueChange(e, "lname")}
        />
        <AutocompleteField
          label="Designation"
          width="48%"
          value={employeeForm.designation}
          options={getOrgDetails().designations}
          onChange={(e, value) => onValueChange({ target: { value } }, "designation")}
        />
        <FormField
           label="Personal Email Id"
           type="text"
           width="48%"
           value={employeeForm.email}
           onChange={(e) => onValueChange(e, "email")}
        />
        <Box sx={{width:"48%"}}> 
             <TextBox>Location</TextBox>
             <Autocomplete
                options={legalUnits}
                size="small"
                value={selectedLocation}
                onChange={(e,newValue)=>onLocationChange(newValue)}
                sx={{
                  '& .MuiInputBase-root': InputStyle,
                    "& .MuiOutlinedInput-notchedOutline":{
                      border: "1px solid #E7E7ED",
                    }
                }}
                renderInput={(params)=><TextField {...params}/>}
              />
        </Box>
        <Box sx={{width:"48%"}}> 
             <TextBox>Reporting To</TextBox>
             <Autocomplete
                options={employees}
                size="small"
                value={reportingTo}
                onChange={(e,newValue)=>onReportingToChange(newValue)}
                sx={{
                  '& .MuiInputBase-root': InputStyle,
                    "& .MuiOutlinedInput-notchedOutline":{
                      border: "1px solid #E7E7ED",
                    }
                }}
                renderInput={(params)=><TextField {...params}/>}
              />
        </Box>
        <Box sx={{width:"48%"}}> 
             <TextBox>Departments</TextBox>
             <Autocomplete
                options={departments}
                size="small"
                value={selectedDepartment}
                onChange={(e,newValue)=>onDepartmentChange(newValue)}
                sx={{
                  '& .MuiInputBase-root': InputStyle,
                    "& .MuiOutlinedInput-notchedOutline":{
                      border: "1px solid #E7E7ED",
                    }
                }}
                renderInput={(params)=><TextField {...params}/>}
              />
        </Box>
        <Box sx={{width:"48%"}}>
            <TextBox>Contact Number</TextBox>
            <PhoneInput
              international
              value={contact}
              onChange={onContactChange}
              dropdownProps={{
                style: PhoneInputStyle.flagDropdown
              }}
            />
        </Box>
        <FormField
           label="Onboarding Type"
           type="select"
           width="48%"
           value={employeeForm.onboardingType}
           onChange={(e) => onValueChange(e, "onboardingType")}
           options={["Remote", "Office"]}
        />
        <FormField
           label="Employment Type"
           type="select"
           width="48%"
           value={employeeForm.employmentType}
           onChange={(e) => onValueChange(e, "employmentType")}
           options={["Full-time", "Part-time", "Internship", "Contract"]}
        />
         <DateField
            label="Date of joining"
            value={employeeForm.joiningDate}
            isDialogOpen={isDateDialogOpen}
            openDialog={() => setIsDateDialogOpen(true)}
            closeDialog={() => setIsDateDialogOpen(false)}
            onSelect={(date)=> {
                      onDateChange(date);
                      setIsDateDialogOpen(false)
            }}
          />
      </Box>
      <ErrorMsg>{error}</ErrorMsg>
      <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginBlock:"2.25rem"}}>
        <BtnPrimary onClick={submit} sx={btnStyle} disabled={loading}>
          {loading ? <CircularProgress size={24}/> : "Save and Next"}
        </BtnPrimary>
        <BtnSecondary sx={btnStyle} to="/app/employees">
             Back
        </BtnSecondary>
      </Box>
    </Box>
  );
}
