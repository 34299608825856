import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { useNavigate } from "react-router";
import { PolicyTableHeadings } from "../../../../../../../styles/PolicyTableHeadings";
import { PolicyTableCell } from "../../../../../../../styles/PolicyTableCell";
import { formatDate, formatDateByDay } from "../../../../../../../utils/formatDate";
import PaginationTable from "../../../../../../../component/PaginationTable";
import PolicyListingHeader from "../../../../../../../component/PolicyListingHeader";

function ShiftRotationListing() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
          try {

            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);
  
    const headings = [
      { label: "Sr. No.", style: {...PolicyTableHeadings, width:"7.68rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Rotation Name", style: {...PolicyTableHeadings,  width:"15.5rem"} },
      { label: "Rotation Time", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Location", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Rotation Frequency", style: { ...PolicyTableHeadings,width:"12.56rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("/app/shift/edit",{state:{policyId:id}});
    };

  return (
     <Box sx={{width:"100%",paddingBlock:"1rem"}}>
        <Topbar MainHeading={"Shift Rotation"}/>
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
                <PolicyListingHeader 
                    Heading = "Shifts"
                    ButtonText = "+ Add shift rotation"
                    ButtonLink = "/app/shift-rotation/new/rotation-details"
                />
                <PaginationTable
                    rows={data}
                    headings={headings}
                    loading={loading}
                    noRecordMessage={"No Record Found"}
                    renderCell={(row) => (
                        <>
                        <PolicyTableCell>{row?.code}</PolicyTableCell>
                        <PolicyTableCell 
                            sx={{":hover":{cursor:"pointer"}, color:"#D62525"}} 
                            onClick={() => handleMenuItemClick(row._id)}
                        >
                            {row.name}
                        </PolicyTableCell>
                        <PolicyTableCell>{formatDate(row.startDate)}-{formatDate(row.endDate)}</PolicyTableCell>
                        <PolicyTableCell>
                            {row?.employee?.fname + " " +row?.employee?.lname}
                        </PolicyTableCell>
                        <PolicyTableCell>{formatDateByDay(row.updatedAt)}</PolicyTableCell>
                        </>
                    )}
                />
        </MainBox>
     </Box>
      
  );
}

export default ShiftRotationListing;
