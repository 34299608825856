import React from "react";
import { Button, Avatar } from "@mui/material";
import styled from "@emotion/styled";

const FilterBtn = styled(Button)({
  border: "1px solid  #16151C33",
  backgroundColor: "white",
  color: "#16151C",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  letterSpacing: "0.19px",
  wordWrap: "break-word",
  textAlign: "center",
  textTransform: "none",
});

const FilterButton = (props) => {
  const { onClick, disabled, startIcon, endIcon, children, sx } = props;

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <FilterBtn 
     onClick={handleOnClick}
     disabled={disabled}
     sx={{
       ...sx,
       "&:hover": {
         background: "white",
         color: "#008CC4",
         border: "1px solid #008CC4",
       },
     }}
     startIcon={startIcon?<Avatar src={startIcon} style={{ width: '1.125rem', height: '1.125rem' }}/>:null}
     endIcon={endIcon?<Avatar src={endIcon} style={{ width: '1.125rem', height: '1.125rem' }}/>:null}
    >
      {children || "click me..."}
    </FilterBtn>
  );
};

export default FilterButton;
