import styled from 'styled-components';
import { Box, Avatar } from "@mui/material";

const AvatarWrapper = styled(Box)({
  width: "2.5rem",
  height: "2.5rem",
  borderRadius: "5px",
  backgroundColor:"#7152F30D",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box"
});

const AvatarImage = styled(Avatar)({
  width: "1.6rem",
  height: "1.6rem"
});

export { AvatarWrapper, AvatarImage };