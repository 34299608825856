import React from "react";
import styled from "@emotion/styled";
import {Box} from "@mui/material";

const TabBarContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "2.18rem", 
    gap:"1.25rem",
    borderBottom: "1px solid #A2A1A833"
  });  

const TabBar = (props) => (
    <TabBarContainer sx={props.sx}>
      {props.children}
    </TabBarContainer>
);

export default TabBar;
