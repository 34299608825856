import React from 'react';
import {Box, Dialog} from '@mui/material';
import FormField from '../Formfield';
import CloseIcon from '@mui/icons-material/Close';
import { findDepartment } from '../../utils/findDepartment';

const Info = ({open,onClose, sortBy, infoId}) => {
    const name = findDepartment(infoId)?.name;
    console.log(findDepartment(infoId));
  
    return (
    <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width:"80%",
            borderRadius: "12px",
            boxShadow: "0px 4px 10px 0px #0000001A",
            padding: "1.5rem 1.25rem",
          }
        }}
      >
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between",marginBottom: '1rem' }}>
                      <Box sx={{fontWeight:"500",  fontSize: '1.25rem', lineHeight: '1.875rem', color: '#0081FF'}}>
                            {sortBy + " Info:"}
                        </Box>
                        <CloseIcon fontSize='small' onClick={onClose} 
                          sx={{"&:hover": {cursor: "pointer",}}}
                        />
           </Box>
          <Box sx={{ width: "70%"}}>
                        <FormField
                            label={sortBy+" name"}
                            type="text"
                            value={name}
                            fullwidth={true}
                            disabled={true}
                        />
           </Box>
           <Box sx={{fontSize: '1rem', lineHeight: '1.5rem', color: '#818181'}}>
                Total no. of employees: 
            </Box>
            <Box sx={{fontSize: '1rem', lineHeight: '1.5rem', color: '#818181', marginBlock: '1.25rem'}}>
                Employee List: 
            </Box>
            <Box sx={{display:"flex"}}>
                <Box sx={{backgroundColor: '#8B939054',  borderRadius:"6px",padding:"4px 3px", width:"7.31rem", 
                          textAlign:"center", fontWeight:"600", fontSize: '0.875rem', 
                          lineHeight: '1.125rem', color:"#00000080", letterSpacing:"-2.5%",
                        }}>
                  shreya
                </Box>
            </Box>        
      </Dialog>
    )
}

export default Info;