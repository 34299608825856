import React, { Suspense } from "react";
import Loader from "../component/Loader";
const SignupPage = React.lazy(() => import("../app/signup"));

function Login() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <SignupPage />
      </Suspense>
    </>
  );
}

export default Login;
