export const PolicyTableHeadings = {
    fontFamily:"Poppins",
    fontWeight: "300",
    fontSize: "1rem",
    color: "#16151C",
    lineHeight: "1.5rem",
    padding: "1.375rem 0.625rem",
    backgroundColor: "#F5F5F5",
    borderBottom: "none",
    textAlign:"center"
  }