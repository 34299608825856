import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import styled from "@emotion/styled";
import BtnPrimary from "../../../../../../component/PrimaryButton";
import { fetchEmployeeDetailsById } from "../../../../../../services/employee";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PlaceIcon from "@mui/icons-material/Place";
import {getLegalUnitList, getOrgDepartments, getOrgEmployees} from "../../../../../../services/globalData";

const TextBox = styled(Box)({
  wordBreak: "break-word",
  display: "block",
  width: "100%",
  textAlign: "center",
});

const StyledOuterBox = styled(Box)({
  boxShadow: "0px 4px 20px 0px #0000001A",
});

const MainBox = styled(Box)({
  height: "100vh",
  width:"100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const OnboardingBox = styled(Box)({
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  textAlign: "center",
  width:"100%"
});

const ContactInfo = ({ icon, value }) => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    fontSize="1rem"
    padding="1rem"
  >
    <span style={{ marginRight: "0.46rem", marginTop: "0.156rem" }}>{icon}</span>
    <span>{value}</span>
  </Box>
);

export default ({ loadNextPage }) => {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const employee = getOrgEmployees().find(employee => employee._id === employeeDetails?.reportingTo);
  const department = getOrgDepartments().find(department => department._id === employeeDetails?.department);
  const location = getLegalUnitList().find(legalUnit => legalUnit._id === employeeDetails?.location);
  const [loading,setLoading]=useState(false);
  console.log(getOrgDepartments());

  useEffect(() => {
    const employeeId = sessionStorage.getItem("employeeId");
    if (employeeId) {
      fetchEmployeeDetails(employeeId);
    }
  }, []);

  const fetchEmployeeDetails = async (employeeId) => {
    setLoading(true);
    const res = await fetchEmployeeDetailsById(employeeId);
    console.log(res);
    if(res.success){
      setEmployeeDetails(res.data);
    }
    setLoading(false);
  };

  return (
    <MainBox>
      <StyledOuterBox
        width="75%"
        bgcolor="white"
        margin="auto"
        borderRadius="12px"
        padding="2.25rem"
      >
        <Stack
          paddingBlock="1rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
      {
        loading?(
            <CircularProgress/>
        ):(
          <>
            <OnboardingBox bgcolor="#0081FF" padding="1rem">
              <TextBox
                color="#FEFEFE"
                fontSize="1.75rem"
                fontWeight="500"
              >
                Onboarding Invite Sent to{" "}
                {`${employeeDetails.fname} ${employeeDetails.lname}`}
              </TextBox>
              <TextBox color="#FEFEFE" fontSize="0.875rem">
                Onboarding link has been sent to user's registered email address
                with the below-mentioned face card.
              </TextBox>
            </OnboardingBox>

            {/* First Row */}
            <Stack
              direction="row"
              justifyContent="space-around"
              bgcolor="#FAFAFA"
              width="100%"
              marginBottom=".156rem"
            >
              <ContactInfo
                icon={
                  <MailOutlineIcon
                    style={{ color: "#0081FF", fontSize: "1.25rem" }}
                  />
                }
                value={employeeDetails.email}
              />
              <ContactInfo
                icon={
                  <LocalPhoneIcon
                    style={{ color: "#0081FF", fontSize: "1.25rem" }}
                  />
                }
                value={employeeDetails.phone}
              />
              <ContactInfo
                icon={
                  <PlaceIcon style={{ color: "#0081FF", fontSize: "1.25rem" }} />
                }
                value={location?.name}
              />
            </Stack>

            {/* Second Row */}
            <Stack
              direction="row"
              justifyContent="space-around"
              bgcolor="#FAFAFA"
              width="100%"
            >
              {[
                { label: "Employee ID", value: employeeDetails.aliasId },
                { label: "Designation", value: employeeDetails.designation },
                { label: "Department", value: department?.name },
                { label: "Reporting To", value: employee?.fname+" "+employee?.lname},
              ].map((item, index) => (
                <Stack key={index} direction="column" alignItems="center">
                  <TextBox
                    fontSize="1rem"
                    fontWeight={500}
                    color="#818181"
                  >
                    {item.label}
                  </TextBox>
                  <TextBox
                    fontSize="1.125rem"
                    color="#141414"
                  >
                    {item.value}
                  </TextBox>
                </Stack>
              ))}
            </Stack>

            <Box marginTop="2.25rem" width="20rem">
              <BtnPrimary to="/app/employees" sx={{width:"100%", padding: '1rem', borderRadius:"4px", margin:"auto"}}>Done</BtnPrimary>
            </Box>
          </>
          )
        }
        </Stack>
      </StyledOuterBox>
    </MainBox>
  );
};
