import React, { useState} from 'react';
import { Dialog,Box, Avatar,} from '@mui/material';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import FormField from '../../../../../../component/Formfield';
import ErrorMsg from '../../../../../../component/ErrorMsg';
import Cross from '../../../../../../assets/icons/cross.png';
import CheckboxField from '../../../../../../component/CheckboxField';
import SuccessDialog from '../../../../../../component/SuccessDialog';

function UpdateRecordDialog({open, onClose}) {

  const [errorMsg,setErrorMsg] = useState("");
  const [sendPayslipToEmployee, setSendPayslipToEmployee] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleSave = () => {
      onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(null)}
      PaperProps={{
        sx: {
          width:"100%",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.375rem 1.5rem",
        }
      }}
    >
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
            <Box sx={{display: "block",
                      fontWeight:"500",
                      fontSize:"1.25rem",
                      lineHeight:"1.875rem",
                      color:"#0081FF",
                      marginBottom:"1.5rem"
                    }}>
                Update Record
            </Box>
            <Avatar src={Cross} sx={{height:"1.2rem", width:"1.2rem", '&:hover': {cursor: "pointer"}}} onClick={() => onClose(null)}/>
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
          <FormField
                label="Group Name"
                type="text"
                value={""}
                disabled={true}
          />
          
          <FormField
                label="Paid On"
                type="text"
                value={""}
                disabled={true}
          />
          <Box sx={{fontSize: "1rem", lineHeight: "1.5rem",color:"#818181"}}>Total Employee Paid:</Box>
        </Box>
        <Box sx={{display:"flex", marginBlock:"1.90rem"}}>
              <Box sx={{fontSize: "0.875rem", lineHeight: "1.125rem",color:"#7C7B7B", flex:1,textAlign:"center"}}>
                <Box>Bank Account</Box>
                <Box sx={{borderTop:"1px solid #D5D4DF"}}>

                </Box>
              </Box>
              <Box sx={{fontSize: "0.875rem", lineHeight: "1.125rem",color:"#7C7B7B", flex:1,textAlign:"center"}}>
                <Box>Cheque</Box>
                <Box sx={{borderTop:"1px solid #D5D4DF"}}>

                </Box>
              </Box>
              <Box sx={{fontSize: "0.875rem", lineHeight: "1.125rem",color:"#7C7B7B", flex:1,textAlign:"center"}}>
                <Box>Pending Payment</Box>
                <Box sx={{borderTop:"1px solid #D5D4DF"}}>

                </Box>
              </Box>
        </Box>
        <CheckboxField
            label="Generate and send Pay Slip to employee"
            checked={sendPayslipToEmployee}
            onChange={(isChecked) => setSendPayslipToEmployee(isChecked)}
            labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", fontWeight:"300", letterSpacing:"-2.5%"}}
        />
        <ErrorMsg>
          {errorMsg}
        </ErrorMsg>
        <Box style={{display:"flex",justifyContent:"center", gap:"10px", width:"100%", marginTop:"1.5rem"}}>
           <BtnSecondary onClick={handleSave} sx={{width:"25.25rem", height: '3.5rem', padding: '1rem', borderRadius: '4px', backgroundColor:"#E5F2FF" }}>
                Cancel
           </BtnSecondary>
           <BtnPrimary 
             onClick={()=> {setIsSuccessDialogOpen(true)}} 
             sx={{width:"25.25rem", height: '3.5rem', padding: '1rem', borderRadius: '4px' }}
           >
                Confirm
           </BtnPrimary>
        </Box>
        <SuccessDialog
           open={isSuccessDialogOpen} 
           onClose={() => setIsSuccessDialogOpen(false)} 
           message="Payroll Request Approved" 
           addNewLink="/app/settings/payroll/allemployeepayroll" 
           addNewText="Done"
        />
    </Dialog>
  );
}

export default UpdateRecordDialog;

