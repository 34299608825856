import React, { useState} from 'react'
import { Box } from '@mui/material'
import styled from "@emotion/styled";
import RadioButtonGroup from '../../../../../../../../component/RadioButtonGroup'
import MultiSectionClock from '../../../../../../../../component/MultiSectionClock';

const TextBox = styled(Box)({
    fontSize:"1rem",
    lineHeight:"1.5rem"
});

const daysOfWeek = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const SetSchedule = ({ shiftData, setShiftData, workingDays, selectedIndex, disabled }) => {

  const [isMaxGrossHoursEnabled, setIsMaxGrossHoursEnabled] = useState(false);

   const onFieldChange = (field, value) => {
    
    const updatedDays = [...shiftData.days];

    if (shiftData?. doesScheduleVary) {
      updatedDays[selectedIndex] = {
        ...updatedDays[selectedIndex],
        [field]: value,
      };
    } else {
      workingDays.forEach(index => {
        updatedDays[index] = {
          ...updatedDays[index],
          [field]: value,
        };
      });
    }

    setShiftData(prevData => ({
      ...prevData,
      days: updatedDays,
    }));
  };

  const isDisabled = workingDays?.length === 0;
  console.log(shiftData);

  return (
    <Box>
        {isDisabled ? (
          null
        ) : (
        <Box>
        <Box sx={{borderBlock:"1px solid #D5D4DF", paddingBlock:"1.5rem", marginBlock:"1.5rem"}}>
            {
              selectedIndex !== undefined?( 
               <Box sx={{marginBlock:"0.5rem"}}>
                  <TextBox>Schedule for {!shiftData?.doesScheduleVary?"All Days":daysOfWeek[selectedIndex]}</TextBox>
               </Box>
              ):null
            }
            {console.log(shiftData?.flexibleWorkingHours)}
            {
              shiftData?.flexibleWorkingHours?
              <Box>
                   <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <TextBox>Minimum Gross Hours required</TextBox>
                        <Box sx={{width:"50%"}}>
                            <RadioButtonGroup
                                labelStyle={{fontSize:"1rem", lineHeight:"1.5rem", color:"#000"}}
                                name="hoursMetric"
                                onChange={(e) => {onFieldChange('hoursMetric', e.target.value)}}
                                value={shiftData?.doesScheduleVary ? shiftData?.days[selectedIndex]?.hoursMetric : shiftData?.days[workingDays[0]]?.hoursMetric}
                                options={[
                                    { value: "gross", label: 'Gross Hour' },
                                    { value: "effective", label: 'Effective Hour' }
                                ]}
                                disabled={disabled}
                            />
                            <Box sx={{display:"flex", gap:"2.75rem"}}>
                                <Box>
                                    <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                                        Full Day
                                    </Box>
                                    <MultiSectionClock 
                                       initialHours={shiftData?.doesScheduleVary ? shiftData?.days[selectedIndex]?.minFullDayHours : shiftData?.days[workingDays[0]]?.minFullDayHours}
                                       showMinutes={false}
                                       onChangeHours={(value) => onFieldChange('minFullDayHours', value)}
                                       disabled={disabled}
                                    />
                                </Box>
                                <Box>
                                    <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                                        Half Day
                                    </Box>
                                    <MultiSectionClock 
                                       initialHours={shiftData?.doesScheduleVary ? shiftData?.days[selectedIndex]?.minHalfDayHours : shiftData?.days[workingDays[0]]?.minHalfDayHours}
                                       showMinutes={false}
                                       onChangeHours={(value) => onFieldChange('minHalfDayHours', value)}
                                       disabled={disabled}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", justifyContent:"space-between", marginTop:"2.43rem"}}>
                        <TextBox>Maximum  Gross Hours required</TextBox>
                        <Box sx={{width:"50%"}}>
                            <RadioButtonGroup
                                labelStyle={{fontSize:"1rem", lineHeight:"1.5rem", color:"#000"}}
                                value={isMaxGrossHoursEnabled}
                                onChange={(e) => setIsMaxGrossHoursEnabled(e.target.value==='true')}
                                options={[
                                    { value: false, label: 'Disable' },
                                    { value: true, label: 'Enable' }
                                ]}
                                disabled={disabled}
                            />
                            {isMaxGrossHoursEnabled && 
                              (
                                <Box sx={{display:"flex", gap:"2.75rem"}}>
                                    <Box>
                                        <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                                            Full Day
                                        </Box>
                                        <MultiSectionClock 
                                           initialHours={shiftData?.doesScheduleVary? shiftData?.days[selectedIndex]?.maxFullDayHours : shiftData?.days[workingDays[0]]?.maxFullDayHours}
                                           showMinutes={false}
                                           onChangeHours={(value) => onFieldChange('maxFullDayHours', value)}
                                           disabled={disabled}
                                        />
                                    </Box>
                                    <Box>
                                        <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                                            Half Day
                                        </Box>
                                        <MultiSectionClock 
                                           initialHours={shiftData?.doesScheduleVary ? shiftData?.days[selectedIndex]?.maxHalfDayHours : shiftData?.days[workingDays[0]]?.maxHalfDayHours}
                                           showMinutes={false}
                                           onChangeHours={(value) => onFieldChange('maxHalfDayHours', value)}
                                           disabled={disabled}
                                        />
                                    </Box>
                                </Box>
                              )
                            }
                        </Box>
                    </Box>
              </Box>      
            :
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <TextBox>Shift Timing</TextBox>
                <Box sx={{width:"50%", display:"flex", gap:"2.75rem"}}>
                    <Box>
                        <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                            Start Time
                        </Box>
                        <MultiSectionClock 
                            initialHours={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.startTime/60) : Math.floor(shiftData?.days[workingDays[0]]?.startTime/60)}
                            initialMinutes={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.startTime%60) : Math.floor(shiftData?.days[workingDays[0]]?.startTime%60)}
                            onChangeTime={(value) => onFieldChange('startTime', value)}
                            disabled={disabled}
                        />
                    </Box>
                    <Box>
                        <Box sx={{fontSize:"0.75rem", lineHeight:"1.125rem", color:"#818181"}}>
                            End Time
                        </Box>
                        <MultiSectionClock 
                            initialHours={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.endTime/60) : Math.floor(shiftData?.days[workingDays[0]]?.endTime/60)}
                            initialMinutes={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.endTime%60) : Math.floor(shiftData?.days[workingDays[0]]?.endTime%60)}
                            onChangeTime={(value) => onFieldChange('endTime', value)}
                            disabled={disabled}
                        />
                    </Box>
                 </Box>
            </Box>
            }
        </Box>
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
                    <TextBox>Break time for the Shift</TextBox>
                    <Box sx={{width:"50%"}}>
                        <MultiSectionClock 
                            initialHours={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.breakTime/60) : Math.floor(shiftData?.days[workingDays[0]]?.breakTime/60)}
                            initialMinutes={shiftData?.doesScheduleVary ? Math.floor(shiftData?.days[selectedIndex]?.breakTime%60) : Math.floor(shiftData?.days[workingDays[0]]?.breakTime%60)}
                            onChangeTime={(value) => onFieldChange('breakTime', value)}
                            disabled={disabled}
                        />
                    </Box>
        </Box>
       </Box>
     )}
    </Box>
  )
}

export default SetSchedule