import React from "react";
import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import { SearchBar} from "./SearchBox";
import ToggleBellIcon from "./ToggleBellIcon";
import Profile from "./Profile";
import BreadCrumbsNavigation from "./BreadCrumbsNavigation";

const TopBar = styled(Box)({
    display: "block",
    height: "5rem",
    width: "100%",
    marginBottom:"1.875rem",
    paddingBlock:"0.9375rem",
});

const TextBox = styled(Box)({
    display: "block",
    wordBreak: "break-word",
  });

export default ({MainHeading, breadcrumbs}) => (
  <TopBar>
          <Box sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
            <Box>
                <TextBox 
                  fontSize={"1.25rem"} 
                  fontWeight={600}
                  lineHeight={"1.875rem"}
                  color="#16151C"
                >
                    {MainHeading||"All Employees"}
                </TextBox>
                <BreadCrumbsNavigation breadcrumbs={breadcrumbs}/>
            </Box>
            <Box>
                 {/* <ToggleBellIcon/> */}
                 <Profile/>
             </Box>
          </Box>
  </TopBar>
);