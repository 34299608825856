import React,{useState} from 'react';
import {Box} from '@mui/material';
import SelectSortBy from '../../../../../../../component/assign-policy/SelectSortBy';
import ReviewSortBy from '../../../../../../../component/assign-policy/ReviewSortBy';

const mapPages = {
    selectSortBy: SelectSortBy,
    reviewSortBy: ReviewSortBy,
};

const SortBy = ({policyId}) => {

  const [sortBy,setSortBy] = useState();
  const [selectedList,setSelectedList] = useState({department: [],employeeGroup:[]});
  const [page, setPage] = useState("selectSortBy");

  console.log(policyId);

  const loadNextPage = (p) => {
    setPage(p);
  };
  const ShowPage = mapPages[page];

  return (
      <Box sx={{width:"100%"}}>
            <ShowPage 
              loadNextPage={loadNextPage} 
              sortBy={sortBy}
              setSortBy={setSortBy}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              policyId={policyId}
              rulesPageLink={"/app/leave-policy/rules"}
              type="leave"
            />
      </Box>
    )
}

export default SortBy;