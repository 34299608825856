import React,{useEffect, useState} from 'react';
import {Box} from '@mui/material';
import BtnPrimary from '../PrimaryButton';
import FormField from '../Formfield';
import Info from './Info';
import {getOrgDepartments, getOrgGroups} from '../../services/globalData'
import SortByTable from './SortByTable';

const SelectSortBy = ({loadNextPage, sortBy, setSortBy,selectedList, setSelectedList}) => {
  const [infoId,setInfoId]=useState("");
  const [isInfoDialogOpen,setIsInfoDialogOpen]=useState(false);
  const [data,setData] = useState();
  const allDepartments = getOrgDepartments();
  const allGroups = getOrgGroups(); 
  
  useEffect(()=>{
    setData(sortBy==="Departments"?allDepartments:allGroups);
  },[sortBy])

  const handleNextClick = () => { 
    loadNextPage("reviewSortBy")
  };

  return (
    <Box>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between",marginBottom:"0.75rem" }}>
                      <FormField
                          label="Sort By"
                          type="select"
                          options={["Departments","Groups"]}
                          value={sortBy}
                          onChange={(e)=>{setSortBy(e.target.value)}}
                      />
         </Box>
         {sortBy && (
          <Box>
             <Box sx={{padding:"10px", fontWeight:"500",fontSize:"1.25rem",lineHeight:"1.875rem", color:"#0081FF", marginBottom:"1.875rem"}}>
                    {sortBy}
              </Box>
              <SortByTable
                sortBy={sortBy}
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}  
              />
              <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                    <BtnPrimary 
                      onClick={handleNextClick} 
                      sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    >
                       Next
                    </BtnPrimary>
              </Box>
          </Box>
         )} 
         <Info open={isInfoDialogOpen} onClose={()=>setIsInfoDialogOpen(false)} infoId={infoId} sortBy={sortBy}/>
    </Box>
  )
}

export default SelectSortBy;