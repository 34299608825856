import React, {useState} from 'react';
import MainBox from '../../../../../container/MainBox';
import TopBar from '../../../../../../../component/TopBar';
import {Box, CircularProgress, IconButton} from '@mui/material';
import ActiveBar from '../../../../../../../component/PolicyNavbarUpdated';
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import BtnPrimary from "../../../../../../../component/PrimaryButton";
import ErrorMsg from '../../../../../../../component/ErrorMsg';
import FormField from '../../../../../../../component/Formfield';
import { useNavigate } from 'react-router';
import DateField from '../../../../../../../component/DateField';
import SwitchToggle from '../../../../../../../component/SwitchToggle';
import CheckboxField from '../../../../../../../component/CheckboxField';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from 'react-router-dom';
import { createAttendancePolicy } from '../../../../../../../services/attendancePolicy';

const AttendancePolicyDetails = () => {
    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const [policyData,setPolicyData] = useState({
          code:"",
          name:"",
          startDate:null,
          endDate:null,
     //   workMode:"Work from office",
          allowMobileClockIn:false,
          enableGeofencing:false,
          requireCommentMobileClockIn:false,
          allowWebClockIn:false,
          requireCommentWebClockIn:false,
     //   isApprovalMandatoryMobile:false,
     //   isApprovalMandatoryWeb:false,
    });
    
    const [startDateDialogOpen, setStartDateDialogOpen] = useState(false);
    const [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({code:"",
                                                              name:"",
                                                              startDate:"",
                                                              endDate:"",
                                                            });
   const [error,setError]=useState("");
  
   const handleNextClick = async() => {
        const errors ={};
  
        if (!policyData.code) errors.code="Code is required"
        if (!policyData.name) errors.name="Name is required"
        if (!policyData.startDate) errors.startDate="Start Date is required"
        if (!policyData.endDate) errors.endDate="End Date is required"
        
        if (Object.keys(errors).length > 0) {
          setValidationErrors(errors);
          return;
        }
  
        setApiCall(true);
        try {
          const res = await createAttendancePolicy(policyData);
          console.log(res);
          if(res.success){
            navigate("/app/attendance-policy/new/add-category",{state:{policyId: res?.data._id}});
          } else {
              console.error('Policy creation failed:', res.error);
              setError(res.message);
          }
        } catch (error) {
            console.error('Error creating Policy:', error);
        }
        setApiCall(false);
    };
  
    const handleChange = (field, value) => {
      const updatedValue = field === "code" ? value?.toUpperCase() : value;
      setPolicyData(prevState => ({...prevState, [field]: updatedValue}));
      setValidationErrors(prevState => ({...prevState, [field]: ""}));
      setError("");
    };
  
  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance Policy"}
          breadcrumbs={[{label:"Attendance", href:""},{label:"Policy Details", href:""}]}
        />
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                <ActiveBar active={["Policy Details"]} value="16"/>
                <BtnSecondary to={"/app/attendance-policy/listing"} sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}>
                    Back
                </BtnSecondary>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <FormField
                            label="Policy Code"
                            type="text"
                            value={policyData.code}
                            validation={validationErrors.code}
                            placeholder="e.g. E099"
                            onChange={(e) => handleChange('code', e.target.value)}
                        />
                        <FormField
                            label="Policy Name"
                            type="text"
                            value={policyData.name}
                            validation={validationErrors.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                        <DateField
                            label="Policy Start Date"
                            value={policyData.startDate}
                            isDialogOpen={startDateDialogOpen}
                            validation={validationErrors.startDate}
                            openDialog={() => setStartDateDialogOpen(true)}
                            closeDialog={() => setStartDateDialogOpen(false)}
                            onSelect={(date) => {
                                    handleChange('startDate', date)
                                    setStartDateDialogOpen(false)
                                    }}
                        />
                        <DateField
                            label="Policy End Date"
                            value={policyData.endDate}
                            isDialogOpen={endDateDialogOpen}
                            validation={validationErrors.endDate}
                            openDialog={() => setEndDateDialogOpen(true)}
                            closeDialog={() => setEndDateDialogOpen(false)}
                            onSelect={(date) => {
                                    handleChange('endDate', date)
                                    setEndDateDialogOpen(false)
                                    }}
                        />
                        {/* <FormField
                            label="Work Mode"
                            type="select"
                            options={["Work from office","Work from home"]}
                            value={policyData.workMode}
                            onChange={(e) => handleChange('workMode', e.target.value)}
                        /> */}
            </Box>
            <Box sx={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                <Box sx={{width:"48%"}}>
                   <SwitchToggle 
                        checked={policyData?.allowMobileClockIn} 
                        onChange={(e)=>handleChange('allowMobileClockIn',e.target.checked)} 
                        label="Mobile clock in"
                    />
                    <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <CheckboxField
                            label="Enable Geo-fencing"
                            checked={policyData.enableGeofencing}
                            disabled={!policyData?.allowMobileClockIn}
                            onChange={(isChecked) => handleChange('enableGeofencing',isChecked)}
                            labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowMobileClockIn?"#81818180":"#818181"}`}}
                        />
                        {/* <Link to="/app/settings/geofence">
                            <IconButton sx={{height:"2rem"}} disabled={!policyData?.allowMobileClockIn}>
                                <ControlPointIcon/>
                            </IconButton>
                        </Link> */}
                    </Box>
                    <CheckboxField
                        label="Comment is mandatory at clock in"
                        checked={policyData.requireCommentMobileClockIn}
                        disabled={!policyData?.allowMobileClockIn}
                        onChange={(isChecked) => handleChange('requireCommentMobileClockIn',isChecked)}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowMobileClockIn?"#81818180":"#818181"}`}}
                    />
                    {/* <CheckboxField
                        label="Approval is mandatory at first clock in"
                        checked={policyData.isApprovalMandatoryMobile}
                        disabled={!policyData?.allowMobileClockIn}
                        onChange={(isChecked) => handleChange('isApprovalMandatoryMobile',isChecked)}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowMobileClockIn?"#81818180":"#818181"}`}}
                    /> */}
                </Box>
                <Box sx={{width:"48%"}}>
                   <SwitchToggle 
                        checked={policyData?.allowWebClockIn} 
                        onChange={(e)=>handleChange("allowWebClockIn",e.target.checked)} 
                        label="Web clock in"
                    />
                    <CheckboxField
                        label="Comment is mandatory at clock in"
                        checked={policyData.requireCommentWebClockIn}
                        disabled={!policyData?.allowWebClockIn}
                        onChange={(isChecked) => handleChange('requireCommentWebClockIn',isChecked)}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowWebClockIn?"#81818180":"#818181"}`}}
                    />
                    {/* <CheckboxField
                        label="Approval is mandatory at first clock in"
                        checked={policyData.isApprovalMandatoryWeb}
                        disabled={!policyData?.allowWebClockIn}
                        onChange={(isChecked) => handleChange('isApprovalMandatoryWeb',isChecked)}
                        labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: `${!policyData?.allowWebClockIn?"#81818180":"#818181"}`}}
                    /> */}
                </Box>            
            </Box>
            <ErrorMsg>{error}</ErrorMsg>
            <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    onClick={handleNextClick} 
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    disabled={apiCall}
                >
                    {apiCall?<CircularProgress/>:"Next"}
                </BtnPrimary> 
            </Box>
        </MainBox>
    </Box>
    )
}

export default AttendancePolicyDetails;