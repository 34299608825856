import React,{useState} from 'react'
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';

const Client = () => {

    const [clientData, setClientData] = useState({
        clientName:"",
        currency:"",
        email:"",
        fname:"",
        lname:"",
        phone:"",
        city:"",
        street:"",
        country:"",
        industry:"",
        companySize:""
    });

  const handleChange = (field, value) => {
    setClientData(prevState => ({
            ...prevState,
            [field]: value 
        }));
  };

  return (
    <Box sx={{ width: "100%", padding:"1.875rem 1.25rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Client
        </Box>
        <Box>
            <FormField
                label="Client Name"
                type="text"
                name="clientName"
                value={clientData.clientName}
                onChange={(e) => handleChange('clientName', e.target.value)}
            />
            <FormField
                label="Currency"
                type="select"
                name="currency"
                options={[]}
                value={clientData.currency}
                onChange={(e) => handleChange('currency', e.target.value)}
            />
        </Box>
        <Box sx={{fontWeight:"500", fontSize:"1.375rem", lineHeight:"2.06rem", marginBottom:"1.5rem"}}>
            Contacts
        </Box>
        <Box>
            <FormField
                label="Email Id"
                type="text"
                name="email"
                value={clientData.email}
                onChange={(e) => handleChange('email', e.target.value)}
            />
            <FormField
                label="First Name"
                type="text"
                name="fname"
                value={clientData.fname}
                onChange={(e) => handleChange('fname', e.target.value)}
            />
            <FormField
                label="Last Name"
                type="text"
                name="lname"
                value={clientData.lname}
                onChange={(e) => handleChange('lname', e.target.value)}
            />
            <FormField
                label="Phone"
                type="text"
                name="phone"
                value={clientData.phone}
                onChange={(e) => handleChange('phone', e.target.value)}
            />
            <FormField
                label="City"
                type="text"
                name="city"
                value={clientData.city}
                onChange={(e) => handleChange('city', e.target.value)}
            />
            <FormField
                label="Street Address"
                type="text"
                name="street"
                value={clientData.street}
                onChange={(e) => handleChange('street', e.target.value)}
            />
            <FormField
                label="Country"
                type="select"
                name="country"
                options={[]}
                value={clientData.country}
                onChange={(e) => handleChange('country', e.target.value)}
            />
            <FormField
                label="Industry"
                type="select"
                name="industry"
                options={[]}
                value={clientData.industry}
                onChange={(e) => handleChange('industry', e.target.value)}
            />
             <FormField
                label="Company Size"
                type="text"
                name="companySize"
                value={clientData.companySize}
                onChange={(e) => handleChange('companySize', e.target.value)}
            />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", gap: "1.25rem", marginBlock: "1.875rem" }}>
                <BtnPrimary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Submit
                </BtnPrimary>
                <BtnSecondary sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
        </Box>
    </Box>
  )
}

export default Client