import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import styled from "@emotion/styled";
import EmployeeForm from "./OnboardingForm";

const TextBox = styled(Box)({
  display: "block",
  wordBreak: "break-word",
  width: "100%",
});

export default () => {

  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    Designation: "",
    PersonalEmailId: "",
    Location: "",
    ReportingTo: "",
    Department: "",
    ProbationPeriod: "",
    OnboardingType: "",
    ContactNumber: "",
    EmploymentType: ""
  });

  const updateFormData = (label, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [label]: value
    }));
  };

  return (
    <Box 
      width={"100%"} 
      bgcolor={"white"} 
      margin={"auto"} 
      borderRadius={"12px"} 
      padding={"1.5rem"}
      boxShadow={"0px 4px 15px 0px #0000001A"}
      marginBlock={"5.6rem"}
    >
      <TextBox
        color={"#141414"}
        fontWeight={500}
        fontSize={"1.375rem"}
        marginBottom={"1.5rem"}
        lineHeight={"2.06rem"}
      >
        Employee Onboarding
      </TextBox>
      <Stack padding={"1.5rem"} boxShadow={"0px 4px 20px 0px #00000010"} borderRadius={"12px"}>
        <TextBox
          color={"#141414"}
          fontSize={"1.56rem"}
          lineHeight={"2.34rem"}
        >
          Basic Details
        </TextBox>
        
        <EmployeeForm />
      </Stack>
    </Box>
  );
};
