import React,{useEffect, useState} from 'react';
import { Avatar, Box, MenuItem, TextField } from '@mui/material';
import ProgressBar from '../../../../../component/ProgressBar';
import DocsIcon from '../../../../../assets/icons/docs.svg';
import MainBox from "../../../container/MainBox";
import AttendanceDetails from './AttendanceDetails';
import RegularizationForm from './RegularizationForm';
import ShiftChangeForm from './ShiftChangeForm';

const InputStyle = {
   borderRadius: '4px',
   fontSize: '0.875rem',
   lineHeight: '1.31rem',
   fontWeight:"300",
   color: '#1E1E1E',
   height: "2rem", 
   width:"10.25rem",
   padding:"0.75rem",
   backgroundColor:"#0081FF",
   color:"#FFFFFF",
 }

const DetailsRequest = ({selectedDate, setSelectedDate}) => {

  const [requestType, setRequestType] =useState("Request");
  const options = ["Regularization","Shift Change"];

  useEffect(()=>{
     setRequestType("Request")
  },[selectedDate])

  return (
    <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1rem", alignItems:"end" }}>
             <Box>
                <Box sx={{display:"flex"}}>
                   <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                   <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                      Attendance Details
                   </Box>
                </Box>
                <ProgressBar/>
             </Box>
             <TextField
               select
               variant="outlined"
               value={requestType}
               onChange={(e)=>setRequestType(e.target.value)}
               sx={{
                  '& .MuiInputBase-root': {...InputStyle},
                  "& .MuiOutlinedInput-notchedOutline":{border: 'none'},
                  '& .MuiSelect-icon':{color:"#FFFFFF"}
               }}
            >
               <MenuItem value="Request" sx={{display:"none"}}>
                     Request
               </MenuItem>
               { options.map((option) => (
                     <MenuItem key={option} value={option}>
                        {option}
                     </MenuItem>
               ))}
             </TextField>
        </Box>
        <MainBox sx={{padding:"0.75rem 1.25rem 1rem 1.25rem"}}>
         {requestType==="Request"?(
             <AttendanceDetails selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
         ):(requestType==="Regularization"?
             <RegularizationForm/>
         :   <ShiftChangeForm/>
         )}      
        </MainBox>
    </Box>
  )
}

export default DetailsRequest