import React from 'react';
import {TextField, InputAdornment} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchBox = ({handleSearch, searchQuery, inputStyle, styles, placeholder }) => {
    
  return (
      <TextField 
        id="outlined-basic" 
        variant="outlined"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={placeholder||"Search by employee name"}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon/>
            </InputAdornment>
          ),
        }}
        sx={{
             "& .MuiInputBase-root":{
                  ...styles,
                  ...inputStyle,
                  borderRadius:"12px",
                  fontSize: '1rem',
                  lineHeight:"1.5rem",
                  color:"#16151C",
                  fontWeight: '300'
             },
             "& .MuiOutlinedInput-notchedOutline":{
                  border:"0.5px solid #16151C33"
             }
        }}
      />
  );
}


