import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';

function MultiCheckBox({ label, options, values, onChange }) {
  const handleChange = (name) => (event) => {
    onChange(name, event.target.checked);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox checked={values[option.value]} onChange={handleChange(option.value)} name={option.value} />}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default MultiCheckBox;
