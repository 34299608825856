import { postCall, getCall } from "./api";

 export const getAllDayAttendance = async () => {
    const request = {
        path: `/facial-recognition-attendance/days`,
    };
     return await getCall(request);
  };

  export const getAllDaySelfAttendance = async () => {
    const request = {
        path: `/facial-recognition-attendance/days/self`,
    };
     return await getCall(request);
  };

  export const getAllDayAttendanceByEmployeeId = async (employeeid) => {
    const request = {
        path: `/facial-recognition-attendance/days/${employeeid}`,
    };
     return await getCall(request);
  };
  
  