import React, {useState} from 'react';
import { Dialog,Box} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";
import FormField from '../../../../../../component/Formfield';
import ErrorMsg from '../../../../../../component/ErrorMsg';

const TextBox = styled(Box)({
    display: "block",
});

function AddNewOs({open, onClose}) {

  const [name,setName] = useState("");
  const [errorMsg,setErrorMsg] = useState("");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"42.81rem",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.5rem",
          display:"flex",
          flexDirection:"column",
          gap:"1.5rem"
        }
      }}
    >
        <Box sx={{display:"flex", 
                  width:"100%", 
                  justifyContent:"center", 
                  alignItems:"center", 
                  fontSize:"1.375rem",
                  lineHeight:"1.75rem"
                  }}>
           Add New Operating System
        </Box>
        <Box>
          <Box sx={{fontSize: '1rem',lineHeight: '1.5rem',color: '#818181'}}>
            OS Name
          </Box>
          <FormField
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullwidth={true}
          />
        </Box>
        {errorMsg && (
          <ErrorMsg>
            {errorMsg}
          </ErrorMsg>
        )}
        <Box style={{display:"flex", gap:"1.5rem", width:"100%", justifyContent:"end"}}>
           <BtnPrimary sx={{width:"6.25rem", height: '3rem', padding: '1.25rem', borderRadius: '10px' }}>
                Submit
           </BtnPrimary>
           <BtnSecondary onClick={onClose} sx={{width:"6.25rem", height: '3rem', padding: '1.25rem', borderRadius: '10px' }}>
                Cancel
           </BtnSecondary>
        </Box>
    </Dialog>
  );
}

export default AddNewOs;

