import React, {useState, useEffect} from "react";
import {Box, Button} from "@mui/material";
import MainBox from "../../../../container/MainBox";
import TopBar from "../../../../../../component/TopBar";
import CardItem from "../../../../../../component/CardItem";
import OfficeBuilding from "../../../../../../assets/icons/office-building.svg";
import Logout from "../../../../../../assets/icons/logout.svg";
import People from "../../../../../../assets/icons/people.svg";
import Card from "../../../../container/Card";
import FourCirclesIcon from "../../../../../../assets/icons/four-circles.svg";
import Ticket from "../../../../../../assets/icons/ticket.svg";
import Department from "../../../../../../component/Department";
import DashboardSectionHeading from "../../../../../../component/DashboardSectionHeading";
import TV from "../../../../../../assets/icons/tv.svg";
import { Link, useNavigate } from "react-router-dom";
import DropdownButton from "../../../../../../component/DropdownButton";
import EmployeeAttendanceCard from "../../../../../../component/EmployeeAttendanceCard";
import RequestTable from "./employees-attendance/RequestTable";
import { getOrgEmployees, getOrgShifts } from "../../../../../../services/globalData";
import { getAllDayAttendance} from "../../../../../../services/facialRecognition";
import { getEmployeeDetails } from "../../../../../../utils/getEmployeeNames";
import Loader from "../../../../../../component/Loader";

const AttendanceDashboard = () => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const shiftOptions = getOrgShifts()?.map((shift) => ({
      value: shift?._id,
      name: shift?.name,
  }));
  const [selectedShift, setSelectedShift] = useState(shiftOptions[0]?.value);
  const [employeesAttendanceData, setEmployeesAttendanceData] = useState([]);
  const [currentDayAttendanceData, setCurrentDayAttendanceData] = useState([]);
  const [loggedInEmployees, setLoggedInEmployees] = useState([]);
  const [onTimeEmployees, setOnTimeEmployees] = useState([]);
  const [lateEmployees, setLateEmployees] = useState([]);
  const [overTimeEmployees, setOverTimeEmployees] = useState([]);
  const [activeTab, setActiveTab] = useState(`Logged In`);

  useEffect(()=>{
    fetchAttendance();
  },[]);

  const fetchAttendance = async() => {
      setLoading(true);
      const res = await getAllDayAttendance();
      console.log(res);
      const groupedRecords = groupRecordsByDateAndEmployee(res?.data);
      setEmployeesAttendanceData(groupedRecords);
      
      //for current day attendance
      const currentDayAttendance = getCurrentDayAttendance(groupedRecords);
      const employeeIds = currentDayAttendance.map(data => data?.employeeId);
      const combinedData = await getEmployeeDetails(currentDayAttendance, employeeIds, "employeeId"); 
      setCurrentDayAttendanceData(combinedData);

      //logged in employees
      setLoggedInEmployees(combinedData.filter(data => !data?.departureTime));
      
      console.log(groupedRecords);
      setLoading(false);
  }
  
  console.log(currentDayAttendanceData);

  const groupRecordsByDateAndEmployee = (records) => {
    const groupedByDate = records.reduce((acc, record) => {
      const recordDate = new Date(record.date).toISOString().split('T')[0]; 
  
      if (!acc[recordDate]) {
        acc[recordDate] = {};
      }
  
      const employeeId = record?.employee;
  
      if (!acc[recordDate][employeeId]) {
        acc[recordDate][employeeId] = [];
      }
  
      acc[recordDate][employeeId].push(record);
  
      return acc;
    }, {});
  
    // Convert the grouped object into an array of arrays (one for each date with employee groups)
    return Object.entries(groupedByDate).map(([date, employeesRecords]) => ({
      date,
      employees: Object.entries(employeesRecords).map(([employeeId, records]) => ({
        employeeId,
        records
      }))
    }));
  };

  const getCurrentDayAttendance = (attendance) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const todayData = attendance.find((record) => record?.date === currentDate);
    return todayData ? todayData?.employees : [];
  };
  

  const handleViewDetailsClick = () => { 
    navigate("/app/attendance-details/all-employees",{state:{employeesAttendanceData:employeesAttendanceData}});
  };

  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
          MainHeading={"Attendance"} 
          breadcrumbs={[{label:"Attendance", href:""},{label:"Dashboard", href:""}]}
        />
        <MainBox sx={{ padding: "2rem 3rem"}}>
          {loading?(
              <Loader/>
          ):(
           <Box>  
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem", alignItems:"end" }}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    Dashboard
                </Box>
                <DropdownButton 
                   value={selectedShift} 
                   options={shiftOptions} 
                   onChange={(e)=>setSelectedShift(e.target.value)}
                />
            </Box>
            <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", gap: "1.75rem"}}>
                  <CardItem icon={People} Heading={"Total employees present"} 
                      subHeading={new Date().toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        12
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Total employees : {getOrgEmployees()?.length}
                    </Box>
                  </CardItem>
                  <CardItem icon={OfficeBuilding} Heading={"Employees working from office"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        12
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Total employees : {getOrgEmployees()?.length}
                    </Box>
                  </CardItem>
                  <CardItem icon={Logout} Heading={"Employees in Shift"}>
                    <Box sx={{fontWeight:"600", fontSize:"1.875rem",lineHeight:"2.5rem"}}>
                        12
                    </Box>
                    <Box sx={{fontSize:"0.75rem",lineHeight:"1.125rem", color:"#6B7280", marginTop:"0.5rem"}}>
                        Employees working overtime:
                    </Box>
                  </CardItem>
            </Box>
            <Box sx={{display:"flex", alignItems:"stretch", gap:"1.187rem", marginBlock:"1.125rem"}}>
                    <Box sx={{width:"48%", flex:"1", display:"flex", flexDirection:"column", gap:"1rem", justifyContent:"space-between"}}>
                      <Card sx={{flex:"1", padding:"1.5rem", height:"16.5rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                              <DashboardSectionHeading icon={TV} label={"Overview"}/>
                              <Department sx={{height:"2rem"}}/>
                        </Box>
                      </Card>
                      <Card sx={{flex:"1",height:"16.5rem", padding:"1.5rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                                <DashboardSectionHeading icon={Ticket} label={"Requests"}/>
                                <Button 
                                 sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4F4F50", textTransform:"none", textDecoration:"underline"}}
                                >
                                  <Link 
                                   style={{ textDecoration: 'none', color: 'inherit' }}
                                   to="/app/attendance-details/requests"
                                  >
                                     more info
                                  </Link>
                                </Button>
                          </Box>
                          <RequestTable/>
                      </Card>
                    </Box>
                    <Card sx={{width:"48%", height:"37rem", padding:"1.5rem", display:"flex", flexDirection:"column", gap:"1.175rem"}}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"end" }}>
                            <DashboardSectionHeading icon={FourCirclesIcon} label={"Employee Attendance"}/>
                            <Button  
                              sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4F4F50", textTransform:"none", textDecoration:"underline"}}
                              onClick={handleViewDetailsClick}
                            >
                                  View Details
                            </Button>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between"}}>
                              <Department sx={{height:"2rem"}}/>
                        </Box>
                        <Box sx={{width:"100%", display:"flex"}}>
                            {[
                                { label: "Logged In", count: loggedInEmployees?.length || 0 },
                                { label: "On Time", count: onTimeEmployees?.length || 0 },
                                { label: "Late", count: lateEmployees?.length || 0 },
                                { label: "Over Time", count: overTimeEmployees?.length || 0 },
                              ].map((tabItem)=>(
                              <Box
                                key={tabItem?.label}
                                onClick={() => setActiveTab(tabItem?.label)}
                                sx={{
                                  flex:"1",
                                  fontSize:"0.75rem",
                                  lineHeight:"1.5rem",
                                  fontWeight: activeTab === tabItem?.label ? "500" : "300",
                                  cursor:"pointer",
                                  padding: '0.5rem 0rem',
                                  color: activeTab === tabItem?.label ? "#0081FF" : "#14141480",
                                  borderTop:"1px solid #A2A1A833",
                                  borderBottom: `solid ${activeTab === tabItem?.label?"3px #0081FF":"2px #A2A1A833"}`,
                                  '&:hover': {
                                    fontWeight: "600",
                                  },
                                }}
                            >
                              {tabItem?.label} ({tabItem.count})
                            </Box>
                            ))}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.175rem", overflowY: "auto", height:"100%" }}>
                          {currentDayAttendanceData && currentDayAttendanceData.length > 0 ? (
                            currentDayAttendanceData.map((employeeData) => (
                              <EmployeeAttendanceCard key={employeeData?.employeeId} employeeData={employeeData} />
                            ))
                          ) : (
                            <Box sx={{fontSize:"1rem",lineHeight:"1.5rem", width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                              No employee logged in today
                            </Box>
                          )}
                        </Box>
                    </Card>
            </Box>
           </Box>
          )}
        </MainBox>
     </Box>
  );
};

export default AttendanceDashboard;
