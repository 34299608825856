import React, { useEffect, useState } from "react";
import {Box} from "@mui/material";
import PaginationTable from "../../../../../../component/PaginationTable";
import { getLegalUnitList } from "../../../../../../services/globalData";
import {getAllExpenseCategory} from "../../../../../../services/expense";
import { getEmployeeNames } from '../../../../../../utils/getEmployeeNames';
import { useNavigate } from "react-router";
import { formatDateByDay } from "../../../../../../utils/formatDate";
import PolicyListingHeader from "../../../../../../component/PolicyListingHeader";
import { PolicyTableCell } from "../../../../../../styles/PolicyTableCell";
import { PolicyTableHeadings } from "../../../../../../styles/PolicyTableHeadings";

const ExpenseTypeListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const legalUnit = getLegalUnitList();
        const res = await getAllExpenseCategory(legalUnit[0]._id);
        console.log(res); 
        const employeeIds = res.data.map(data => data.createdBy);
        const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
        setData(combinedData.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const headings = [
    { label: "Category Code", style: {...PolicyTableHeadings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
    { label: "Expense Category", style: {...PolicyTableHeadings} },
    { label: "Used in Policies", style: {...PolicyTableHeadings} },
    { label: "Created By", style: {...PolicyTableHeadings} },
    { label: "Last Modified", style: { ...PolicyTableHeadings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
  ];

  const handleMenuItemClick = (id) => { 
    navigate("form",{state:{typeId:id}});
  };

  return (
    <Box>
      <PolicyListingHeader 
          Heading = "Expense Categories"
          ButtonText = "+ Create Expense Category"
          ButtonLink = "form"
      />
      <PaginationTable
        rows={data}
        headings={headings}
        noRecordMessage={"No Record Found"}
        loading={loading}
        renderCell={(row) => (
          <>
            <PolicyTableCell>{row?.code}</PolicyTableCell>
            <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                      {row.name}
                </Box>
            </PolicyTableCell>
            <PolicyTableCell>{row?.policy?.length || 0}</PolicyTableCell>
            <PolicyTableCell>{row.employee.fname+" "+row.employee.lname}</PolicyTableCell>
            <PolicyTableCell>{formatDateByDay(row.updatedAt)}</PolicyTableCell>
            </>
          )}
      />
    </Box>
  );
};

export default ExpenseTypeListing;
