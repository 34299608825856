import React, { useState } from 'react'
import styled from "@emotion/styled";
import { Autocomplete, Box, FormControlLabel,Radio, RadioGroup, TextField } from '@mui/material';
import FormField from '../../../../../../../../component/Formfield';
import CheckboxField from '../../../../../../../../component/CheckboxField';
import BtnPrimary from "../../../../../../../../component/PrimaryButton";
import SwitchToggle from '../../../../../../../../component/SwitchToggle';
import MultiSectionClock from '../../../../../../../../component/MultiSectionClock';
import { getOrgShifts } from '../../../../../../../../services/globalData';

const TextBox = styled(Box)({
   fontSize:"1rem",
   lineHeight:"1.5rem"
});

const TardinessForm = ({loadNextPage, tardinessForm, setTardinessForm}) => {

  const handleChange = (field, value) => {
    setTardinessForm(prevState => ({...prevState, [field]: value}));
  };
  const shiftOptions = getOrgShifts()?.map((shift) => ({
    value: shift?._id,
    name: shift?.name,
  }));
  const [selectedShifts, setSelectedShifts] = useState([]);

  const handleShiftChange = (event, newValue) =>{
    setSelectedShifts(newValue);
    const selectedIds = newValue.map((shift) => shift?.value);
    handleChange('shifts',selectedIds);
  }

  return (
    <Box>
       <Box sx={{width:"100%", display:"flex", flexWrap:"wrap", justifyContent: "space-between", alignItems:"center"}}>
            <Box sx={{width:"100%", display:"flex", justifyContent:"space-between", marginBlock:"1rem"}}>
               <Box sx={{display:"flex", gap:"0.2rem"}}>
                 <TextBox>Tardiness-</TextBox>
                 <TextBox sx={{color:"#818181"}}>Penalize employee if they exceed allowed number / hours of late arrival </TextBox>
               </Box>
               <SwitchToggle 
                  checked={tardinessForm?.enableTardiness} 
                  onChange={(e)=> handleChange('enableTardiness',e.target.checked)}
               />
            </Box>
            <FormField
                label="Tardiness Type"
                type="select"
                options={[{name:"Late Login / Clock In", value:"late-login"},{name:"Low Punctuality", value:"low-punctuality"}]}
                value={tardinessForm?.tardinessType}
                onChange={(e) => {handleChange('tardinessType', e.target.value)}}
            />
            <Box sx={{ width: "48%",marginBottom: '1.125rem'}}>
              <TextBox sx={{ color: '#818181',marginBottom:"4px"}}>Scheduled Shift</TextBox>
              <Autocomplete
                multiple
                options={shiftOptions}
                getOptionLabel={(option) => option?.name} 
                size="small"
                value={selectedShifts}
                onChange={handleShiftChange}
                sx={{
                  '& .MuiInputBase-root': {
                    borderRadius: '10px',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight:"300",
                    color: '#16151CCC',
                    height: "3rem"
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: '1px solid #A2A1A833'
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box sx={{width:"100%", display:"flex", gap:"0.2rem", marginBlock:"1.375rem"}}>
               <TextBox>Grace Period-</TextBox>
               <TextBox sx={{color:"#818181"}}>
                    Period beyond which employee {`${tardinessForm.type==="Late Login / Clock In"?
                      "arrival will be considered late arrival.":
                      "punctuality will be considered as low."
                    }`}
               </TextBox>
            </Box>
            <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB"}}>
               <Box>
                    <TextBox>Set Limit for {tardinessForm?.tardinessType==="low-punctuality"?"minimum punctuality rate":"Grace Period"}</TextBox>
                    <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                           {tardinessForm?.tardinessType==="low-punctuality"?
                              (
                                <>
                                  <FormField
                                      width="5rem"
                                      height="3.5rem"
                                      type="text"
                                      value={tardinessForm?.minimumPunctuality}
                                      onChange={(e) => {handleChange('minimumPunctuality', e.target.value)}}
                                  /> /
                                  <FormField
                                        width="11.625rem"
                                        height="3.5rem"
                                        type="select"
                                        options={[{name:"Week",value:"weekly"},{name:"Month", value:"monthly"},{name:"Year", value:"yearly"}]}
                                        value={tardinessForm?.tardinessType==="low-punctuality"?(tardinessForm?.punctualityTimePeriod):(tardinessForm?.tardinessTimePeriod)}
                                        onChange={(e) => {handleChange(
                                          tardinessForm?.tardinessType==="low-punctuality"?"punctualityTimePeriod":"tardinessTimePeriod", e.target.value
                                        )}}
                                  />
                                </>
                              )
                              :
                               <MultiSectionClock 
                                    initialHours={Math.floor(tardinessForm?.tardinessThreshold/60)||0}
                                    initialMinutes={Math.floor(tardinessForm?.tardinessThreshold%60)||0}
                                    onChangeTime={(value) => handleChange('tardinessThreshold', value)}
                                />
                            }
                    </Box> 
               </Box>
               <Box>
                    <TextBox>How many times an employee is allowed to come in Late?</TextBox>
                    <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.75rem", color:"#16151C80"}}>
                            <FormField
                                  width="5rem"
                                  height="3.5rem"
                                  type="text"
                                  value={tardinessForm.noOfPermissibleLateArrivals}
                                  onChange={(e) => {handleChange('noOfPermissibleLateArrivals', e.target.value)}}
                            />
                            times 
                            {/* in a
                            <FormField
                                  width="11.625rem"
                                  height="3.5rem"
                                  type="select"
                                  options={["Month", "Week", "Year"]}
                                  value={tardinessForm.lateAllowedFrequency}
                                  onChange={(e) => {handleChange('lateAllowedFrequency', e.target.value)}}
                            />  */}
                    </Box> 
                </Box>
            </Box>
            <Box sx={{marginBlock:"1.375rem"}}>
               <TextBox>Penalty in case of breach</TextBox>
               <CheckboxField
                    label="Employees are penalized if the exceed allowed number of late arrival in a  week/ month. "
                    checked={tardinessForm?.tardinessPenalty !== "none"}
                    onChange={(isChecked) => {
                      handleChange('tardinessPenalty', isChecked ? "deduct" : "none");
                    }}
                    labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color:"#818181"}}
                />
            </Box>
            {
               tardinessForm?.tardinessPenalty !== "none"?(
                <Box sx={{width:"100%", borderRadius:"10px", padding:"1.25rem", backgroundColor:"#FAFAFB"}}>
                  <Box sx={{marginBlock:"1.5rem"}}>
                    <TextBox sx={{marginBottom:"0.75rem"}}>How are Penalties charged?</TextBox>
                    <Box sx={{display:"flex", justifyContent:"space-between"}}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={tardinessForm?.tardinessPenalty}
                          onChange={(e)=> handleChange('tardinessPenalty',e.target.value)}
                          style={{ width:"100%", flexDirection: 'row', justifyContent:"space-between"}}
                        >
                          <FormControlLabel 
                            value="deduct" 
                            control={<Radio />} 
                            label={
                              <Box>
                                Loss of Pay
                                <Box sx={{fontSize:"0.875rem", lineHeight:"1.31rem", color:"#818181"}}>
                                  Consider all penalties as loss of pay
                                </Box>
                              </Box>
                            } 
                          />
                          <FormControlLabel 
                            value="leave" 
                            control={<Radio />} 
                            label={
                              <Box>
                                Paid Leave
                                <Box sx={{fontSize:"0.875rem", lineHeight:"1.31rem", color:"#818181"}}>
                                  Consider all penalties as loss of pay
                                </Box>
                              </Box>
                            } 
                          />
                        </RadioGroup>
                    </Box>
                  </Box>
                  <Box sx={{marginBlock:"1.5rem"}}>
                    <TextBox sx={{marginBottom:"0.75rem"}}>Post 2 late arrival in a week, how is an employees penalized ?</TextBox>
                      {tardinessForm?.tardinessPenalty==="deduct"?
                         <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                            <FormField
                                  width="5rem"
                                  height="2rem"
                                  type="text"
                                  value={tardinessForm.noOfDaysOfPayLossForTardiness}
                                  onChange={(e) => {handleChange('noOfDaysOfPayLossForTardiness', e.target.value)}}
                            /> 
                            day(s) Pay loss (LOP) for each incident week.
                         </Box> :
                         <Box sx={{width:"100%",display:"flex", alignItems:"center", gap:"0.2rem", color:"#16151C80"}}>
                            <FormField
                                  width="5rem"
                                  height="2rem"
                                  type="text"
                                  value={tardinessForm.numberOfDaysPaidLeaveDeduction}
                                  onChange={(e) => {handleChange('noOfPaidLeaveDeductForTardiness', e.target.value)}}
                            /> 
                            day(s) Paid Leave.
                        </Box> 
                      }
                  </Box>
            </Box>
               ):null
            }
       </Box>
       <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                <BtnPrimary 
                    sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                    onClick={() => loadNextPage("chooseCategory")}
                >
                    Next
                </BtnPrimary> 
         </Box>
    </Box>
  )
}

export default TardinessForm