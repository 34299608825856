import styled from '@emotion/styled';
import {TableCell} from "@mui/material";

export const PolicyTableCell = styled(TableCell)({
    fontFamily:"Poppins",
    fontWeight: "300",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    padding: "2.06rem 1rem",
    textAlign:"center",
    color:"#16151C"
  });