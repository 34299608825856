import React from 'react';
import { Box } from '@mui/material';
import HolidaySetting from "../assets/icons/HolidaySetting";
import GeneralSettings from "../assets/icons/GeneralSettings";
import AddDocument from "../assets/icons/AddDocument";
import Briefcase from "../assets/icons/Briefcase";
import ProgressBar from "./ProgressBar";

const ActiveBar = ({ active, value }) => {

  const items = [{value:"Policy Details",icon:GeneralSettings},
    {value:"Assign Policy",icon:Briefcase},
    {value:"Rules",icon:HolidaySetting}
  ]

  return (
  <Box sx={{width:"80%",paddingBlock:"0.5rem"}}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        minHeight: "2.18rem",
        gap: "1.25rem",
      }}
    >
       {
            items.map((item)=>{
                const {value,icon:Icon}=item;
                return(
                  <Box sx={{
                    display:"flex", 
                    alignItems:"center",
                    gap:"0.625rem",
                    fontWeight:"300",
                    fontSize: "1rem",
                    lineHeight: "1.5rem",
                    color: active.includes(value) ? "#0081FF" : "#00000080",
                  }}>
                      <Icon color={active.includes(value)? "#0081FF" : "#00000080"}/>
                            <Box>
                              <strong>{value}</strong>
                            </Box>
                      </Box> 
                      )
                  })
              }
          </Box>
          <ProgressBar value={value}/>
  </Box>
  );
};

export default ActiveBar;
