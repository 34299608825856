import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import TuneIcon from '@mui/icons-material/Tune';

const SecondaryBtn = styled(Button)({
  border: "1px solid  #0081FF",
  color: "#0081FF",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  letterSpacing: "0.19px",
  wordWrap: "break-word",
  textAlign: "center",
  textTransform: "none"
});

const SecondaryButton = (props) => {
  const { onClick: onBtnClick , startIcon: StartIcon, disabled, sx, to} = props;

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (onBtnClick) {
      onBtnClick();
    }
  };
  
  if (to) {
    return (
      <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
        <SecondaryBtn
          sx={{
            ...sx,
            "&:hover": {
              background: "#0081FF",
              color: "white",
            },
          }}
        >
          {StartIcon && <TuneIcon />}
          {props.children || "default"}
        </SecondaryBtn>
      </Link>
    );
  }

  return (
    <SecondaryBtn onClick={(e) => handleOnClick(e)}
      disabled={disabled}
      sx={{
        ...sx,
        "&:hover": {
          background: "#0081FF",
          color: "white",
        },
      }}
      startIcon={StartIcon ? <TuneIcon/> : null}
    >
      {props.children || "default"}
    </SecondaryBtn>
  );
};

export default SecondaryButton;
