import React,{useState, useEffect} from 'react';
import {Avatar, Box} from "@mui/material";
import PaginationTable from '../../../../../component/PaginationTable';
import { formatDate, formatDateByDay } from '../../../../../utils/formatDate';
import { PolicyTableHeadings} from '../../../../../styles/PolicyTableHeadings';
import { PolicyTableCell} from '../../../../../styles/PolicyTableCell';
import ProgressBar from '../../../../../component/ProgressBar';
import TV from '../../../../../assets/icons/tv.svg'

const RequestUpdatesTable = () => {
    const [data, setData] = useState([{id:"1",type:"Regularisation",date:"12/07/23",status:"pending"},
        {id:"1",type:"Regularisation",date:"12/07/23",status:"approved"},
        {id:"1",type:"Regularisation",date:"12/07/23",status:"rejected"}
    ]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);
  
    const headings = [
      { label: "Request ID", style: {...PolicyTableHeadings, width:"14.93rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Request Type", style: {...PolicyTableHeadings,  width:"12.25rem"} },
      { label: "Date", style: {...PolicyTableHeadings, width:"12.56rem"} },
      { label: "Approval Status", style: { ...PolicyTableHeadings,width:"20.06rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];


  return (
    <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1rem", alignItems:"end" }}>
             <Box>
                <Box sx={{display:"flex", gap:"0.625rem"}}>
                   <Avatar src={TV} style={{ width: '1.5rem', height: '1.5rem' }}/>
                   <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                       Request Updates
                   </Box>
                </Box>
                <ProgressBar/>
             </Box>
        </Box>
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell>{row?.id}</PolicyTableCell>
              <PolicyTableCell>{row.type}</PolicyTableCell>
              <PolicyTableCell>{row.date}</PolicyTableCell>
              <PolicyTableCell>
               <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}>
                    <Box sx={{
                                width:"5.81rem",
                                borderRadius:"4px", 
                                padding:"4px 8px",
                                textAlign:"center",
                                backgroundColor: row.status === "pending" ? "#FFA80033" : (row.status === "rejected" ? "#E493931A" : "#03B85833"),
                                color: row.status === "pending" ? "#FFA800" : (row.status === "rejected" ? "#E52E2E" : "#227C63")
                                }}>
                            {row.status}
                        </Box>
               </Box>
              </PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default RequestUpdatesTable