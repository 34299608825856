import React,{useState} from 'react';
import {Box} from '@mui/material';
import ActiveBar from "../../../../../../../../component/PolicyNavbarUpdated";
import TopBar from '../../../../../../../../component/TopBar';
import MainBox from '../../../../../../container/MainBox';
import ChooseCategory from './ChooseCategory';
import AbsenteeismForm from './AbsenteeismForm';
import BreakTimeForm from './BreakTimeForm';
import TardinessForm from './TardinessForm';
import BtnSecondary from "../../../../../../../../component/SecondaryButton";

const AddCategory = () => {

  const [page, setPage] = useState("chooseCategory");
  const [attendanceCategories, setAttendanceCategories] = useState([""]);
  const absenteeismFormDefaultState = {
    enableAbsenteeism: true,
    autoDetectAbsenteeism: false,
    minDailyHours: 0,
    absenteeismThreshold: 0,
    absenteeismTimePeriod: "monthly",
    penaliseAdjoiningHolidays: false,
    penaliseAdjoiningWeekends: false,
    minAbsenceDurationForAdjoiningHolidayPenalty: 0,
    minAbsenceDurationForAdjoiningWeekendPenalty: 0,
    absenteeismPenalty: "none",
    noOfPaidLeaveDeductForAbsenteeism: 0,
    noOfDaysOfPayLossForAbsenteeism: 0
  };
  const tardinessFormDefaultState = {
    enableTardiness: true,
    tardinessType:"late-login",
    shifts:[],
    tardinessThreshold:0,
    minimumPunctuality:0,
    tardinessTimePeriod:"monthly",
    punctualityTimePeriod:"monthly",
    noOfPermissibleLateArrivals:0,
    tardinessPenalty:"none",
    noOfDaysOfPayLossForTardiness: 0,
    noOfPaidLeaveDeductForTardiness: 0
 };
 const breakTimeFormDefaultState = {
  type:"",
  breakTimeWindow:"",
  maxBreak:"",
  breakFrequency:"",
  breakFrequencyType:"Day",
  graceLimit:"",
  graceLimitFrequency:"Minutes",
  notifyEmployee:true,
  notifyReportingManager:true,
  isPenalised:false,
  penaltyType:"",
  numberOfEXtendedBreakForDeduction:"",
  effectiveHoursDeduction:"",
  effectiveHoursDeductionFrequency:"Minutes"
 };
 const [absenteeismForm, setAbsenteeismForm] =useState(absenteeismFormDefaultState);
 const [tardinessForm, setTardinessForm]=useState(tardinessFormDefaultState);
 const [breakTimeForm, setBreakTimeForm]=useState(breakTimeFormDefaultState);

 const loadNextPage = (p) => {
      setPage(p);
 };

 const mapPages = {
    chooseCategory: ChooseCategory,
    absenteeismForm: AbsenteeismForm,
    breakTimeForm: BreakTimeForm,
    tardinessForm: TardinessForm
 };

 const CurrentPage = mapPages[page];

  return (
    <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <TopBar 
           MainHeading={"Attendance Policy"}
           breadcrumbs={[{label:"Attendance", href:""},{label:"Policy Details", href:""},{label:"Add Category", href:""}]}
        />
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                <ActiveBar active={["Policy Details","Add Category"]} value="36"/>
                {page!=="chooseCategory"?
                   <BtnSecondary 
                        sx={{width:"10rem", height:"2.25rem", borderRadius:"10px"}} 
                        onClick={()=>{
                          loadNextPage("chooseCategory")
                        }}
                    >
                       Back
                    </BtnSecondary>
                :null}
            </Box>
            <CurrentPage
                loadNextPage={loadNextPage}
                absenteeismForm={absenteeismForm}
                setAbsenteeismForm={setAbsenteeismForm}
                absenteeismFormDefaultState={absenteeismFormDefaultState}
                tardinessForm={tardinessForm}
                setTardinessForm={setTardinessForm}
                tardinessFormDefaultState={tardinessFormDefaultState}
                breakTimeForm={breakTimeForm}
                setBreakTimeForm={setBreakTimeForm}
                breakTimeFormDefaultState={breakTimeFormDefaultState}
                attendanceCategories={attendanceCategories}
                setAttendanceCategories={setAttendanceCategories}
            />
        </MainBox>
      </Box>
    )
}

export default AddCategory;