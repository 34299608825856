import { mkConfig, generateCsv, download } from 'export-to-csv';
import BtnFilter from './FilterButton'
import { default as CSVIcon } from '../assets/icons/elements.png';
import React from 'react'

const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const CSVButton = ({styles, data}) => {

  const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
  };

  return (
    <BtnFilter 
        sx={styles} 
        endIcon={CSVIcon} 
        onClick={handleExportData}
    >
        Export CSV
    </BtnFilter>

  )
}

export default CSVButton