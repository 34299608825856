import React, {useState, useEffect} from 'react'
import styled from "@emotion/styled";
import {Box} from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import ActionDialog from '../../../../../../component/ActionDialog';
import {approveExpense, getExpenseById, rejectExpense } from '../../../../../../services/expense';
import CloseIcon from '@mui/icons-material/Close';
import { getOrgEmployees } from '../../../../../../services/globalData';

const LabelBox = styled(Box)({
    fontSize: "0.875rem",
    color: "#5C5C5C",
    lineHeight: "1.5rem",
});

const ValueBox = styled(Box)({
    fontSize: "1rem",
    color: "#16151C",
    lineHeight: "1.5rem",
});

const ExpenseInfo = ({expenseId, setOpen}) => {

const [expenseDetails, setExpenseDetails] = useState([
  { label: "Expense ID", value:""},
  { label: "Date", value: ""},
  { label: "Reporting Manager", value: ""},
  { label: "Expense Policy", value: ""},
  { label: "Expense Category", value: ""},
  { label: "Reason", value: ""}
  ]);

 const [approveDialogOpen, setApproveDialogOpen] = useState(false);
 const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
 const [status,setStatus] = useState("");
 const [employee,setEmployee] = useState({});

  const fetchExpenseDetails = async () => {
    try {
      const res = await getExpenseById(expenseId); 
      console.log(res); 
      const expenseData = res?.data;
      setStatus(expenseData?.status);
      const approvers = getOrgEmployees().filter(emp => expenseData?.policy?.approverIds.includes(emp._id));      
      setExpenseDetails([
        { label: "Expense ID", value: expenseData?._id },
        { label: "Date", value: new Date(expenseData?.createdDate).toLocaleDateString('en-GB')},
        { label: "Reporting Manager", value: approvers?.length > 0 
          ? approvers?.map(approver => `${approver?.fname} ${approver?.lname}`).join(', ')
          : '--'},
        { label: "Expense Policy", value: expenseData?.policy?.name},
        { label: "Expense Category", value: expenseData?.policyCateogary?.name },
        { label: "Reason", value: expenseData?.reason }
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (expenseId) {
      fetchExpenseDetails();
    }
  }, [expenseId]);

  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  const handleApprove = async() => {
    setApproveDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialogOpen(false);
  };
  
  const handleCloseApproveDialog = () => {
    setApproveDialogOpen(false);
  };

  return (
    <Box sx={{width:"21.5rem", backgroundColor:"#F5F5F5",borderRadius:"12px",padding:"1.5rem"}}>
          <Box sx={{display:"flex",justifyContent:"space-between"}}>
              <Box sx={{fontWeight:"600",fontSize:"1.25rem",lineHeight:"1.875rem",color:"#16151C"}}>
                Expense Details
              </Box>
              <CloseIcon 
                fontSize='small' 
                onClick={()=>setOpen(false)} 
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}/>
          </Box>
          <Box sx={{display:"flex",justifyContent:"space-between",fontWeight:"300",fontSize:"0.875rem",lineHeight:"1.375rem"}}>
              <Box>
                  <Box sx={{color:"#16151C"}}>{employee?.fname+" "+employee?.lname}</Box>
                  <Box sx={{color:"#0081FF"}}>{employee?.designation}</Box>
              </Box>
              <Box sx={{
                        alignSelf:"end",
                        borderRadius:"4px", 
                        padding:"2px 8px",
                        textAlign:"center",
                        backgroundColor: status === "pending" ? "#FFBE64" : (status === "rejected" ? "#E493931A" : "#0CBD7D"),
                        color: status === "pending" ? "#212423" : (status === "rejected" ? "#E52E2E" : "#FFFFFF")
                      }}>
                  {status}
              </Box>
          </Box>
          <Box sx={{display:"flex", flexDirection:"column", gap:"1rem", marginTop:"1.5rem"}}>
                {expenseDetails.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", justifyContent: "space-between"}}
                    >
                      <LabelBox>{item?.label}</LabelBox>
                      <ValueBox>{item?.value}</ValueBox>
                    </Box>
                  ))}
          </Box>
          {
            status==="pending"?
              <Box sx={{ width:"100%", display: "flex", gap: "1rem", marginBlock: "1.875rem" }}>
                  <BtnSecondary onClick={handleReject} sx={{ borderRadius: "8px", height: "3rem", flex:"1" }}>
                      Reject
                  </BtnSecondary>
                  <BtnPrimary onClick={handleApprove} sx={{ borderRadius: "8px", height: "3rem",flex:"1" }}>
                      Approve
                  </BtnPrimary>
              </Box>
            :null
          }
          <ActionDialog 
            type="approve" 
            open={approveDialogOpen} 
            onClose={handleCloseApproveDialog} 
            reviewApi={approveExpense}
            id={expenseId} 
            text="Approve Expense?"
          />
          <ActionDialog 
            type="reject" 
            open={rejectDialogOpen} 
            onClose={handleCloseRejectDialog} 
            reviewApi={rejectExpense}
            id={expenseId}
            text="Reject Expense?"
          />
    </Box>
  )
}

export default ExpenseInfo