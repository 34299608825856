import React, { useState, useEffect } from 'react';
import { Box,TableCell } from '@mui/material';
import PaginationTable from '../../../../../../../component/PaginationTable';
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { getAllLeaveRequests} from '../../../../../../../services/leave';
import TableHeader from '../../../../../../../component/TableHeader';
import { getEmployeeDetails} from '../../../../../../../utils/getEmployeeNames';
import BtnSecondary from "../../../../../../../component/SecondaryButton";

const CustomTableCell = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};

const Headings = {
  fontFamily:"Poppins",
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  textAlign:"center",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const LeaveDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date().toLocaleDateString('en-GB'));
    const [order,setOrder] = useState("desc");
    const [data, setData] = useState([]);
    const [filteredData,setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(()=>{
      setFilteredData(filteredData.reverse());
    },[order]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>item.date===(new Date(date).toLocaleDateString('en-GB'))));
    },[date]);

    useEffect(()=>{
      setFilteredData(data.filter((item)=>(
        searchQuery===""?item:item?.employeeName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      )));
    },[searchQuery]);
    
      const headings = [
        { label: "", style: {...Headings, width:"4.75rem", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
        { label: "Employee Name", style: {...Headings, width:"10.25rem"} },
        { label: "Employee ID", style: {...Headings, width:"7.75rem"} },
        { label: "Start Date", style: {...Headings, width:"7.75rem"} },
        { label: "End Date", style: {...Headings, width:"7.75rem"} },
        { label: "No. of days", style: {...Headings, width:"7.75rem"} },
        { label: "Leave Type", style: {...Headings, width:"7.75rem"} },
        { label: "Status", style: { ...Headings, width:"7.75rem", borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
      ];


      useEffect(()=>{
        const fetchData = async () => {
          try {
            const res = await getAllLeaveRequests();
            if(res.success){
            console.log(res);
            const employeeIds = res.data.map(data => data.employeeId);
            const combinedData = await getEmployeeDetails(res.data, employeeIds, "employeeId");        
            const dataTable = combinedData.map((item)=>({
              _id: item?._id,
              date:(new Date(item?.createdAt).toLocaleDateString('en-GB')),
              startDate:(new Date(item?.firstDay).toLocaleDateString('en-GB')),
              endDate:(new Date(item?.lastDay).toLocaleDateString('en-GB')),
              noOfDays: item?.days?.length,
              employeeName:item?.employee?.fname+" "+item?.employee?.lname,
              employeeAliasId:item?.employee?.aliasId,
              leaveId:item?._id,
              leaveType: item?.leaveType,
              status:item?.status
           }));
           console.log(dataTable);
           const reversedDataTable = dataTable.reverse();
            setData(reversedDataTable);
            setFilteredData([...reversedDataTable]);
            console.log(filteredData);
            setLoading(false);
           }
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
      
        fetchData();
      },[])

    return (
        <Box sx={{ width: "100%", paddingBlock:"1rem"}}>
            <Topbar 
              MainHeading={"Leave"}
              breadcrumbs={[{label:"Leave", href:""},{label:"Dashboard", href:""},{label:"Requests", href:""}]}
            />
            <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.125rem"}}>
                <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                    Requests
                </Box>
                <BtnSecondary 
                  sx={{fontWeight:"300",width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}
                  to="/app/leave-dashboard"
                >
                    Back
                </BtnSecondary>
            </Box>
            <TableHeader  
               data={data} 
               setDate={setDate} 
               setOrder={setOrder}
               searchQuery={searchQuery}
               handleSearch={(value)=>setSearchQuery(value)}
            />
            <Box sx={{display:"flex", gap:"1rem", overflow:"hidden"}}>
                <Box sx={{flex:1}}>
                 <PaginationTable
                     rows={filteredData}
                     headings={headings}
                     loading={loading}
                     noRecordMessage={"No Record Found"}
                     link={true}
                     path="/app/leave-requests-details"
                     renderCell={(row) => (
                       <>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                            
                          </TableCell>
                          <TableCell align="center" sx={{...CustomTableCell}}>
                             {row?.employeeName}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                           {row?.employeeAliasId}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                          {row?.startDate}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                          {row?.endDate}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                          {row?.noOfDays}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                          {"sick"}
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell}}>
                                  <Box sx={{
                                            borderRadius:"4px", 
                                            padding:"4px 8px",
                                            textAlign:"center",
                                            backgroundColor: row.status === "Pending" ? "#FFBE64" : (row.status === "Rejected" ? "#E493931A" : "#0CBD7D"),
                                            color: row.status === "Pending" ? "#212423" : (row.status === "Rejected" ? "#E52E2E" : "#FFFFFF")
                                          }}>
                                        {row?.status}
                                  </Box>
                          </TableCell>
                       </>
                     )}
                   />
                </Box>
            </Box> 
            </MainBox>
        </Box>
    )
}

export default LeaveDashboard;
