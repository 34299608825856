import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box, TextField, Select, MenuItem} from "@mui/material";
import BtnPrimary from "../../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../../component/SecondaryButton";

const TextBox = styled(Box)({
    fontSize: ".875rem",
    display:"block",
    lineHeight: "1.31rem",
    marginTop: "1.5rem",
    marginBottom: "0.75rem",
    color:"#16151C",
  });

export default (props) => {

  return (
    <Box sx={{width:"100%", padding:"1rem 1.25rem", display:"flex"}}>
        
    </Box>
  );
};
