import TableCell from '@mui/material/TableCell';
import styled from "@emotion/styled";

// Custom TableCell component
const CustomTableCell = styled(TableCell)({
  fontWeight: "300",
  fontSize: "1rem",
  color: "#000000",
  lineHeight: "1.5rem",
  padding: "1rem",
});

// Custom TableHeadCell component
const CustomTableHeadCell = styled(CustomTableCell)({
  padding: "1.375rem 1rem",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
});

export { CustomTableCell, CustomTableHeadCell };
