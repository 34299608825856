import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const RadioButtonGroup = ({ label, name, value, options, onChange ,disabled,display, labelStyle}) => {
    return (
        <FormControl sx={{ width: "100%"}}>
            <FormLabel sx={labelStyle ? labelStyle : { width: "100%", fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}>{label}</FormLabel>
            <RadioGroup name={name} value={value} onChange={onChange} sx={{ flexDirection: display==="col"?"col":"row", flexWrap: 'wrap' }}>
                {options.map((option, index) => (
                    <FormControlLabel 
                       key={index} 
                       value={option.value} 
                       control={<Radio />} 
                       label={option.label}
                       disabled={disabled}
                       sx={ labelStyle ? labelStyle : { fontSize:"1rem", lineHeight:"1.5rem", color:"#818181"}}
                     />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonGroup;
