import axios from "axios";

const setTokenInfo = (data) => {
  const dataToStr = JSON.stringify(data);
  sessionStorage.setItem("sty-user-token", dataToStr);
};
export const getTokenInfo = () => {
  const data = sessionStorage.getItem("sty-user-token");
  return data ? JSON.parse(data) : {};
};
const setUsrBearerToken = (data) => {
  data && sessionStorage.setItem("sty-bearer-token", data);
};

const getUsrBearerToken = () => {
  return sessionStorage.getItem("sty-bearer-token");
};

export let org_id;
export const setOrgId = (id) => (org_id = id);

const getUrl = (path) => {
  // process.env should be disabled for linting
  // eslint-disable-next-line
  if (process.env.REACT_APP_MODE === "production") {
    return `https://api.slatesy.com/api${path}`;
  }
  return `https://dev.slatesy.com/api${path}`;
};

const storeTokenInfo = (data = {}) => {
  const { accessToken, accessTokenData, refreshToken } = data;
  let tokenInfo = getTokenInfo();
  if (accessToken) {
    tokenInfo = { ...tokenInfo, accessToken };
  }
  if (accessTokenData) {
    tokenInfo = { ...tokenInfo, accessTokenData };
  }
  if (refreshToken) {
    tokenInfo = { ...tokenInfo, refreshToken };
  }
  setTokenInfo(tokenInfo);
  let bearerToken;
  if (tokenInfo.accessToken) {
    bearerToken = tokenInfo.accessToken;
  } else if (tokenInfo.refreshToken) {
    bearerToken = tokenInfo.refreshToken?.value;
  }
  setUsrBearerToken(bearerToken);
};

export const setBearerToken = async () => {
  const tokenInfo = getTokenInfo() || {};
  let { exp } = tokenInfo.refreshToken || {};
  const expiry = Math.floor(new Date(exp) / 1000);
  const twoMinutes = 120 * 1000;
  if (expiry > Math.floor(new Date(Date.now() - twoMinutes) / 1000)) {
    return true;
  } else if (org_id) {
    const request = {
      method: "GET",
      withCredentials: true,
      url: getUrl(`/auth/access-token/${org_id}`),
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.request(request);
      const { data } = res.data;
      storeTokenInfo(data);
      return data;
    } catch (err) {
      const { message, response = {} } = err;
      if (response.data) return response.data;
      return message;
    }
  }
};

export const postCall = async (req) => {
  const { firebaseToken, path, bodyParams = {} } = req;
  const requestOptions = {
    method: "POST",
    withCredentials: true,
    data: {
      ...bodyParams,
    },
    url: getUrl(path),
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (firebaseToken) {
    requestOptions.data["firebaseToken"] = firebaseToken;
  }

  let bearerToken = getUsrBearerToken();
  if (bearerToken && bearerToken !== "undefined") {
    await setBearerToken();
    bearerToken = getUsrBearerToken();
    requestOptions.headers["Authorization"] = `Bearer ${bearerToken}`;
  }

  try {
    const res = await axios.request(requestOptions);
    const { data, message } = res.data;
    storeTokenInfo(data);
    return {
      success: true,
      data,
      message,
    };
  } catch (err) {
    const { message, response = {} } = err;
    if (response.data) {
      const { data, message } = response.data;
      return {
        success: false,
        data,
        message,
      };
    }
    return message;
  }
};

export const putCall = async (req) => {
  const { firebaseToken, path, bodyParams = {} } = req;
  const requestOptions = {
    method: "PUT",
    withCredentials: true,
    data: {
      ...bodyParams,
    },
    url: getUrl(path),
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (firebaseToken) {
    requestOptions.data["firebaseToken"] = firebaseToken;
  }

  let bearerToken = getUsrBearerToken();
  if (bearerToken && bearerToken !== "undefined") {
    await setBearerToken();
    bearerToken = getUsrBearerToken();
    requestOptions.headers["Authorization"] = `Bearer ${bearerToken}`;
  }

  try {
    const res = await axios.request(requestOptions);
    const { data, message } = res.data;
    storeTokenInfo(data);
    return {
      success: true,
      data,
      message,
    };
  } catch (err) {
    const { message, response = {} } = err;
    if (response.data) {
      const { data, message } = response.data;
      return {
        success: false,
        data,
        message,
      };
    }
    return message;
  }
};

export const getCall = async (req, type) => {
  const { path } = req;
  const requestOptions = {
    method: "GET",
    withCredentials: true,
    url: getUrl(path),
    headers: {
      "Content-Type": "application/json",
    },
  };

  let bearerToken = getUsrBearerToken();
  if (bearerToken && bearerToken !== "undefined") {
    await setBearerToken();
    if (type === "OrgLogin") {
      const token = getTokenInfo();
      bearerToken = token?.refreshToken?.value;
    } else {
      bearerToken = getUsrBearerToken();
    }
    requestOptions.headers["Authorization"] = `Bearer ${bearerToken}`;
  }

  try {
    const res = await axios.request(requestOptions);
    const { data, message } = res.data;
    storeTokenInfo(data);
    return {
      success: true,
      data,
      message,
    };
  } catch (err) {
    const { message, response = {} } = err;
    if (response.data) {
      const { data, message } = response.data;
      return {
        success: false,
        data,
        message,
      };
    }

    return message;
  }
};
