import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, Tab, Tabs } from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import DisplayCard from './DisplayCard';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p:"1.5rem" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
    fontSize:"1.125rem",
    lineHeight:"1.68rem",
    textTransform:"none",
    fontWeight:"400", 
    "&.MuiTabs-root":{
        minHeight:0,
    },
    "&.MuiTab-root":{
        minWidth:0,
        padding:0,
        marginInline:"1.25rem",
    },
    "&.Mui-selected":{
        color:"#0081FF"
    },
    "&.MuiTabs-indicator":{
        color:"#0081FF",
        backgroundColor:"#0081FF"
    }
}

const StatutoryComponent = () => {
    
  const [value, setValue] = useState(0);
  const EPF = [
                {label:"EPF Number",value:"AA/AAA/0000000/XXX"},
                {label:"Deduction Cycle",value:"Monthly"},
                {label:"Employee Contribution Rate",value:"12% of Actual PF Wage"},
                {label:"Employer Contribution Rate",value:"12% of Actual PF Wage (view Split-up ) "},
                {label:"CTC Inclusions",value:`Employer's contribution is included in the CTC.
                                               Employer's EDLI contribution is not included in the CTC
                                               Admin charges is not included in the CTC`
                },
                {label:"Allow Employee level Override",value:"No"},
                {label:"Pro-rate Restricted PF Wage",value:"No"},
                {label:"Consider applicable salary components based on LOP",value:"Yes (when PF wage is less than ₹15,000)"},
                {label:"Eligible for ABRY Scheme",value:"No"}
              ]

  const ESI = [
                {label:"ESI Number",value:"00-00-000000-000-0000"},
                {label:"Deduction Cycle",value:"Monthly"},
                {label:"Employee's Contribution",value:"0.75% of Gross Pay"},
                {label:"Employer's Contribution",value:"3.25% of Gross Pay"},
                {label:"Other Details",value:"Employer's contribution is included in the CTC."},
             ]
  
  const PT = [
    {label:"PT Number",value:"0000 000 000"},
    {label:"State",value:"Maharashtra"},
    {label:"Deduction Cycle",value:"Monthly"},
    {label:"PT Slabs",value:"View Tax Slabs"},
    ]

  const LWF = [
    {label:"Employees' Contribution",value:"₹ 12.00"},
    {label:"Employer's Contribution",value:"₹ 36.00"},
    {label:"Deduction Cycle",value:"Half Yearly"},
    {label:"Status",value:"Enabled"},
  ]

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={"Statutory Components"} SubHeading={"Payroll > Statutory Component"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="EPF" {...a11yProps(0)} sx={tabStyle}/>
                    <Tab label="ESI" {...a11yProps(1)} sx={tabStyle}/>
                    <Tab label="Professional Tax" {...a11yProps(2)} sx={tabStyle}/>
                    <Tab label="Labour Welfare Fund" {...a11yProps(3)} sx={tabStyle}/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
               <DisplayCard 
                 mainHeading="Employees Provident Fund"
                 data={EPF}
               />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
               <DisplayCard 
                 mainHeading="Employees State Insurance "
                 data={ESI}
               />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <DisplayCard 
                 mainHeading="Head office"
                 data={PT}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <DisplayCard 
                 mainHeading="Maharashtra"
                 data={LWF}
               />
            </CustomTabPanel>
          </Box>
        </MainBox>
     </Box>
      
  );
}

export default StatutoryComponent;