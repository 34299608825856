import React, { useState } from 'react';
import { Box,TableCell } from '@mui/material';
import { default as CSVIcon } from '../../../../../assets/icons/elements.png';
import DateComponent from "../../../../../component/DatePicker";
import { SearchBox } from "../../../../../component/SearchBox";
import BtnFilter from "../../../../../component/FilterButton";
import BtnPrimary from "../../../../../component/PrimaryButton";
import BtnSecondary from "../../../../../component/SecondaryButton";
import PaginationTable from '../../../../../component/PaginationTable';
import Topbar from "../../../../../component/TopBar";
import MainBox from "../../../container/MainBox";

const CustomTableCell = {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#374151",
    padding: "1rem",
    borderBottom: "0.5px solid #16151C33",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis", 
    overflow:"hidden"
  };

  const Headings = {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: "#374151",
    padding: "1.375rem 1rem",
    borderBottom: "1px solid #D1D5DB",
  }

  const tab = {
    fontSize: "1rem",
    color: "#16151C",
    lineHeight: "1.5rem",
    padding: "1.375rem 1rem",
    display:"flex", 
    justifyContent:"space-between",
    flex:1,
    cursor: "pointer",
  }

const Timesheet = () => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); 
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState("Employees");

  const handleDateSelect = (date) => {
      setSelectedDate(date);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
};

//   const loadData = () => {
//     let filteredData;
//     switch (activeTab) {
//         case "Employees":
//             filteredData = data;
//             break;
//         case "Working":
//             filteredData = data.filter(item => item?.status === "Working");
//             break;
//         case "Absent":
//             filteredData = data.filter(item => item?.status === "Absent");
//             break;
//         case "Late":
//             filteredData = data.filter(item => item?.status === "Late");
//             break;
//         default:
//             filteredData = [];
//     }
//     setTableData(filteredData);
// };

  const headings = [
    { label: "Name", style: {...Headings,width:"9rem",} },
    { label: "Shift", style: {...Headings,width:"9rem"} },
    { label: "Productive Time", style: {...Headings,width:"9rem"} },
    { label: "Offline Time", style: {...Headings,width:"9rem"} },
    { label: "Clock In", style: {...Headings,width:"9rem"} },
    { label: "Clock Out", style: {...Headings,width:"9rem"} },
    { label: "Project Name", style: {...Headings,width:"9rem"} },
    { label: "", style: {...Headings,width:"1.25rem"} }
  ];

  return (
    <Box sx={{ width: "100%" }}>
            <Topbar MainHeading={"My Timesheet"}/>
            <MainBox sx={{ padding: "0.875rem 1rem" }}>
               <Box sx={{display:"flex", justifyContent:"space-between", gap:"1rem", marginBottom:"1.5rem"}}>
                 <SearchBox sx={{flex:1}}/>
                 <DateComponent onDateSelect={handleDateSelect} sx={{flex:1}}/> 
                 <BtnFilter sx={{padding: '1rem 1.5rem', borderRadius: '12px', flex:1}}> 
                  All Departments
                 </BtnFilter>
                 <BtnFilter sx={{padding: '1rem 1.5rem', borderRadius: '12px', flex:1}} endIcon={CSVIcon}> 
                  Export CSV
                 </BtnFilter>
               </Box>
               <Box sx={{display:"flex",flexDirection:"column", gap:"0.875rem", alignItems:"center", marginBottom:"1.5rem"}}>
                  <Box sx={{fontSize:"1.625rem", lineHeight:"1.5rem", fontWeight:"500"}}>
                      Add team members
                  </Box>
                  <Box sx={{fontSize:"0.875rem", lineHeight:"1.5rem", color:"#4B5563", textAlign:"center"}}>
                      To access real-time data about your team’s activities, invite your team members to download the DeskTime desktop app, install it, and log in. You can also start by adding teams to group employees of the same department together.
                  </Box>
                  <Box sx={{display:"flex", gap:"0.875rem"}}>
                          <BtnPrimary sx={{ borderRadius: "10px", height: "3rem"}}>
                              Add Team
                          </BtnPrimary>
                          <BtnSecondary sx={{ borderRadius: "10px", height: "3rem"}}>
                              Add Team Members
                          </BtnSecondary>
                  </Box>
               </Box>
               <Box sx={{display:"flex"}}> 
                 <Box 
                    sx={{...tab,
                        backgroundColor: activeTab==="Employees"?"#FEF3C7":"#F3F4F6",
                        borderBottom: activeTab==="Employees"?"2px solid var(--Colors-Prime-Indigo-Prime-Indigo-500, #6366F1)":"none",
                        borderTopLeftRadius: "12px"
                       }}
                    onClick={() => handleTabClick("Employees")}
                 >
                    <Box>Employees</Box>
                    <Box>12</Box>
                 </Box>
                 <Box 
                    sx={{...tab,
                        backgroundColor: activeTab==="Working"?"#FEF3C7":"#F3F4F6",
                        borderBottom: activeTab==="Working"?"2px solid var(--Colors-Prime-Indigo-Prime-Indigo-500, #6366F1)":"none",
                    }}
                    onClick={() => handleTabClick("Working")}
                 >
                    <Box>Working</Box>
                    <Box>12</Box>
                 </Box>
                 <Box 
                    sx={{...tab,
                        backgroundColor: activeTab==="Absent"?"#FEF3C7":"#F3F4F6",
                        borderBottom: activeTab==="Absent"?"2px solid var(--Colors-Prime-Indigo-Prime-Indigo-500, #6366F1)":"none",
                    }}
                    onClick={() => handleTabClick("Absent")}
                 >
                    <Box>Absent</Box>
                    <Box>12</Box>
                 </Box>
                 <Box 
                    sx={{...tab,
                        backgroundColor: activeTab==="Late"?"#FEF3C7":"#F3F4F6",
                        borderBottom: activeTab==="Late"?"2px solid var(--Colors-Prime-Indigo-Prime-Indigo-500, #6366F1)":"none",
                        borderTopRightRadius: "12px"
                    }}
                    onClick={() => handleTabClick("Late")}
                 >
                    <Box>Late</Box>
                    <Box>12</Box>
                 </Box>
               </Box>
               <PaginationTable
                     rows={data}
                     headings={headings}
                     loading={loading}
                     noRecordMessage={"No Record Found"}
                     renderCell={(row) => (
                       <>
                         <TableCell 
                           align="center"
                           sx={{...CustomTableCell, width:"9rem"}}
                          >
                            
                          </TableCell>
                         <TableCell 
                           align="center"
                           sx={{...CustomTableCell, width:"9rem"}}
                         >
                          
                         </TableCell>
                         <TableCell 
                           align="center"
                           sx={{...CustomTableCell, width:"9rem"}}
                         >
                          
                         </TableCell>
                         <TableCell align="center" sx={{...CustomTableCell, width:"9rem"}}></TableCell>
                         <TableCell align="center" sx={{...CustomTableCell, width:"9rem"}}></TableCell>
                         <TableCell align="center" sx={{...CustomTableCell, width:"9rem"}}></TableCell>
                         <TableCell align="center" sx={{...CustomTableCell, width:"9rem"}}></TableCell>
                         <TableCell align="center" sx={{...CustomTableCell, width:"1.25rem"}}></TableCell>
                       </>
                     )}
                />
            </MainBox>
    </Box>
  )
}

export default Timesheet