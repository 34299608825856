import React from 'react';
import PolicyDetails from "../../../../../../../component/PolicyDetails";

const ExpensePolicyDetails = () => {
  
  return (
       <PolicyDetails
           type="ANY"
           listingPageLink="/app/expense-policy/listing"
           addCategoryPageLink="/app/expense-policy/new/add-category"
       />
    )
}

export default ExpensePolicyDetails;