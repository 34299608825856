import React, { useEffect,useState } from "react";
import {Box, Button, CircularProgress} from "@mui/material";
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import { Outlet} from "react-router";
import {useLocation, useParams} from "react-router-dom";
import { fetchEmployeeDetailsById, sendEmployeeOnboardingEmail } from "../../../../../../../services/employee";
import TopNavbar from "../../../../../../../component/TopNavbar";
import SaveDialog from "../../../../../../../component/SaveDialog";

const EmployeeDetails = () => {
      const [loading,setLoading]=useState(false);
      const [employeeData,setEmployeeData] = useState(null);
      const location = useLocation();
      const {employeeId} = useParams();
      const [isDialogOpen,setIsDialogOpen] = useState(false);
      const [active,setActive] = useState("personal-details");
      const navItems = [
        {check:"personal-details", link:"", value:"Personal"},
        {check:"self-identification", link:"self-identification", value:"Self Identification"}
      ]
      
      useEffect(()=>{
        const { pathname } = location;
        if (pathname.includes("self-identification")) {
          setActive("self-identification");
        } else {
          setActive("personal-details");
        }
      },[location])
    
      useEffect(() => {
          const fetchEmployeeDetails = async () => {
            setLoading(true);
            try {
              const res = await fetchEmployeeDetailsById(employeeId); 
              console.log(res); 
              if(res.success){
                setEmployeeData(res.data);
              }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
            setLoading(false);
          };
          fetchEmployeeDetails();
      }, []);

      const resendOnboardingEmail = async () => {
        try {
          const res = await sendEmployeeOnboardingEmail(employeeId); 
          console.log(res); 
          if(res.success)
            setIsDialogOpen(true);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

  return (
    <Box sx={{width:"100%"}}>
       <Topbar MainHeading="Onboarding Form" SubHeading="All Employee"/>        
       <MainBox sx={{padding:"1.5rem 1.25rem 1.875rem 1.25rem"}}>
        { 
         !employeeData?.hasBeenOnboarded?
            <Box sx={{ borderRadius:"10px", padding:"1.5rem", backgroundColor:"#E0F2FE", marginBottom:"1.5rem"}}>
              <Box sx={{
                    fontWeight:"500",
                    fontSize:"1.125rem",
                    lineHeight:"1.5rem",
                    color:"#141414",
                    marginBottom:"1rem"
                    }}>
                  Need Your Attention
              </Box>
              <Box sx={{
                    fontSize:"1rem",
                    lineHeight:"1.5rem",
                    color:"#141414",
                    padding:"0rem 1rem"
                    }}>
                  An email invite has been sent to this employee to access the Employee Self Service Portal. However, 
                  the employee is yet to accept it. 
                  <Button sx={{
                    textTransform:"none",
                    fontSize:"1rem",
                    lineHeight:"1.5rem",
                    textDecoration:"underline"
                  }}
                    onClick={resendOnboardingEmail}
                  >
                    Reinvite
                  </Button>
                  / Disable Portal
              </Box>
            </Box>
         :null
        }
           <Box sx={{ marginBottom:"1.56rem"}}>
                <TopNavbar active={active} navItems={navItems}/>
           </Box>
           {
              loading?(
                <Box sx={{width:"100%", display:"flex", justifyContent:"center"}}>
                    <CircularProgress/>
                </Box>
              ):(<Outlet context={[employeeData]}/>)
            }
       </MainBox>
       <SaveDialog open={isDialogOpen} onClose={()=>setIsDialogOpen(false)} resendInfo={employeeData?.email}/>
    </Box>
  )
}

export default EmployeeDetails