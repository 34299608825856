import React, { useState, useEffect} from 'react';
import { Dialog,Box, Avatar,} from '@mui/material';
import BtnPrimary from "./PrimaryButton";
import TypeBtn from "./AddRemoveButton";
import FormField from './Formfield';
import ErrorMsg from './ErrorMsg';
import Cross from '../assets/icons/cross.png';

function SetLimit({open, onClose, limits}) {

  const [currentLimits, setCurrentLimits] = useState([]);
  const [errorMsg,setErrorMsg] = useState("");

  useEffect(() => {
    setCurrentLimits(limits);
  }, [limits]);

  const handleAddLimit = () => {
    setCurrentLimits([...currentLimits, {limit:"",frequency:"",instances:"",frequencyType:""}]);
  };

  const handleRemoveLimit = (index) =>{
    let selectedLimit = [...currentLimits];
    selectedLimit.splice(index, 1);
    setCurrentLimits(selectedLimit);
  }

  const handleSave = () => {
      onClose(currentLimits);
  };

  const handleLimitSet = (index, fieldName, value) => {
    let updatedLimits = [...currentLimits];
    updatedLimits[index][fieldName] = value;
    setCurrentLimits(updatedLimits);
  };

  const getFrequencyType = (frequency) =>{
    const freqType =  frequency==="Daily"?"Day":( frequency==="Monthly"?"Month":"Year");
    return freqType;
  }

  console.log(currentLimits);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(null)}
      PaperProps={{
        sx: {
          width:"80%",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "3.43rem 1.25rem",
        }
      }}
    >
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
            <Box sx={{display: "block",
                      fontWeight:"500",
                      fontSize:"1.25rem",
                      lineHeight:"1.875rem",
                      color:"#0081FF",
                      textDecoration:"underline",
                      marginBottom:"0.75rem"
                    }}>
              Set Limit
            </Box>
            <Avatar src={Cross} sx={{height:"1.2rem", width:"1.2rem", '&:hover': {cursor: "pointer"}}} onClick={() => onClose(null)}/>
        </Box>
        {
            currentLimits?.map((item,index)=>(
              <Box>
                <Box sx={{width:"100%", borderBottom:"1px solid #D5D4DF", marginBottom:"0.5rem"}}>
                    <Box sx={{width:"55%"}}>
                            <FormField
                                label="Expense Limit"
                                type="text"
                                value={item.limit}
                                fullwidth={true}
                                onChange={(e) => {handleLimitSet(index, 'limit', e.target.value)}}
                            />
                    </Box>
                    <Box sx={{ width: "100%", display:"flex", gap:"1.5rem"}}>
                           <Box sx={{width:"55%"}}>
                            <FormField
                                      label="Expense Frequency"
                                      type="select"
                                      options={["Daily","Monthly","Yearly"]}
                                      value={item.frequency}
                                      fullwidth={true}
                                      onChange={(e) => {
                                          handleLimitSet(index, 'frequency', e.target.value)
                                          handleLimitSet(index, 'frequencyType', getFrequencyType(e.target.value))
                                      }}
                              />
                            </Box>
                            <Box sx={{width:"40%"}}>
                              <FormField
                                      label="Allowed no. of Instances"
                                      type="text"
                                      value={item.instances}
                                      fullwidth={true}
                                      onChange={(e) => {handleLimitSet(index, 'instances', e.target.value)}}
                                      endAdornment={item.frequencyType?"/ "+item.frequencyType:null}
                              />
                            </Box>
                    </Box>
                    <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
                        <TypeBtn type="remove" onClick={() => handleRemoveLimit(index)}>
                                Remove Limit
                        </TypeBtn>
                    </Box>
                </Box>
              </Box>
            ))
        }
        {(currentLimits?.length===0 || currentLimits[currentLimits?.length - 1]?.limit !=="")?(
                <Box>
                  <TypeBtn type="add" onClick={handleAddLimit}>
                              Add Limit
                  </TypeBtn>
                </Box>
          ):null}
        <ErrorMsg>
          {errorMsg}
        </ErrorMsg>
        <Box style={{display:"flex",justifyContent:"center", width:"100%"}}>
           <BtnPrimary onClick={handleSave} sx={{width:"11.875rem", height: '3.125rem', padding: '1.25rem', borderRadius: '10px' }}>
                Save Limit
           </BtnPrimary>
        </Box>
    </Dialog>
  );
}

export default SetLimit;

