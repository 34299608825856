import React, { useState, useEffect } from 'react';
import {Box, CircularProgress, TableCell,} from '@mui/material';
import Topbar from "../../../../../../../component/TopBar";
import MainBox from "../../../../../container/MainBox";
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import BtnPrimary from "../../../../../../../component/PrimaryButton";
import { getLeaveRequestById , reviewLeaveRequest} from '../../../../../../../services/leave';
import { useParams } from 'react-router';
import { getOrgEmployees } from '../../../../../../../services/globalData';
import FormField from '../../../../../../../component/Formfield';
import DateField from '../../../../../../../component/DateField';
import ActionDialog from '../../../../../../../component/ActionDialog';
import PaginationTable from '../../../../../../../component/PaginationTable';
import { findDepartment } from '../../../../../../../utils/findDepartment';

const CustomTableCell = {
  fontWeight: "300",
  fontSize: "1rem",
  color: "#16151C",
  lineHeight: "1.5rem",
  padding: "10px",
  whiteSpace: "nowrap",
  borderBottom:"0.5px solid #16151C33",
  textOverflow: "ellipsis", 
  overflow:"hidden"
};


const Headings = {
  fontWeight: "600",
  fontSize: "0.75rem",
  lineHeight: "1.875rem",
  color: "#16151C",
  padding: "10px",
  backgroundColor: "#F5F5F5",
  borderBottom: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}

const RequestDetails = () => {
    const [loading, setLoading] = useState(true);
    const {id} = useParams();
    const [employee,setEmployee] = useState();
    const [leaveData, setLeaveData] = useState();
    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [leaveBalanceData, setLeaveBalanceData] = useState([]);

    const leaveBalanceHeadings = [
      { label: "Leave Type", style: {...Headings,borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }},
      { label: "Planned", style: {...Headings}},
      { label: "Available", style: { ...Headings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" }}
    ];

      useEffect(()=>{
        fetchData();
      },[])

      const fetchData = async () => {
        try {
          const res = await getLeaveRequestById(id); 
          console.log(res);
          setEmployee(getOrgEmployees()?.find(employee => employee?._id=== res?.data?.employeeId));
          setLeaveData(res?.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };

      const handleReject = () => {
        setRejectDialogOpen(true);
      };
    
      const handleApprove = () => {
        setApproveDialogOpen(true);
      };
    
      const handleCloseRejectDialog = () => {
        setRejectDialogOpen(false);
      };
      
      const handleCloseApproveDialog = () => {
        setApproveDialogOpen(false);
      };

    return (
        <Box sx={{ width: "100%", paddingBlock:"1rem"}}>
            <Topbar 
              MainHeading={"Leave"}
              breadcrumbs={[{label:"Leave", href:""},{label:"Dashboard", href:""},{label:"Requests", href:""}]}
            />
           {loading?
           <Box sx={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <CircularProgress/>
           </Box>
           :
           <MainBox sx={{ padding: "2.62rem 1.875rem" }}>
           <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"2.56rem"}}>
               <Box sx={{fontWeight:"500", fontSize:"1.5rem", lineHeight:"2.25rem"}}>
                   Request Details
               </Box>
               {new Date().toLocaleDateString("en-US", {day: "numeric",month: "long",year: "numeric"})}
           </Box>
           <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"3.68rem"}}>
               <Box>{employee?.fname+" "+employee?.lname}</Box>
               <Box>{employee?.aliasId+" | Department: "+ findDepartment(employee?.department)?.name+" | "}</Box>
               <BtnSecondary sx={{width:"8.31rem", height:"2rem", borderRadius:"4px"}}>
                   Leave History
               </BtnSecondary>
           </Box>
           <Box sx={{ display: "flex", justifyContent: "space-between",gap:"1rem",marginBottom:"3.68rem"}}>
              <Box sx={{flex:1, display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
                   <FormField
                       label="Leave Type"
                       value={""}
                       disabled={true}
                   />
                   <FormField
                       label="Leave Policy"
                       value={""}
                       disabled={true}
                   />
                   <DateField
                       label="Start Date"
                       value={new Date(leaveData?.firstDay).toLocaleDateString('en-GB')}
                       disabled={true}
                   />
                   <DateField
                       label="End Date"
                       value={new Date(leaveData?.lastDay).toLocaleDateString('en-GB')}
                       disabled={true}
                   />
                   <Box sx={{fontWeight:"500", marginBlock:"1.375rem"}}>
                       Total No. of Days: {leaveData?.days?.length}
                   </Box>
                   <FormField
                       label="Reason"
                       value={leaveData?.reason}
                       disabled={true}
                       fullwidth={true}
                   />
                   {leaveData?.status==="Pending"?
                          <Box sx={{ width:"100%", display: "flex", gap: "1rem", justifyContent:"end"}}>
                               <BtnSecondary onClick={handleReject} sx={{ borderRadius: "8px", height: "3rem", width:"7.81rem"}}>
                                   Reject
                               </BtnSecondary>
                               <BtnPrimary onClick={handleApprove} sx={{ borderRadius: "8px", height: "3rem", width:"7.81rem"}}>
                                   Approve
                               </BtnPrimary>
                          </Box>
                    :null}
              </Box>
              <Box sx={{width:"26.25rem", backgroundColor:"#F5F5F59E", borderRadius:"12px", padding:"1rem"}}>
                   <Box sx={{borderRadius:"12px", padding:"1.5rem", border:"1px solid #A2A1A833"}}>
                        <Box sx={{color:"#818181", marginBottom:"0.75rem"}}>Leave Balance</Box>
                        <PaginationTable
                            customWidth="300px"
                            rows={leaveBalanceData}
                            headings={leaveBalanceHeadings}
                            loading={loading}
                            paginationHide={true}
                            noRecordMessage={"No Record Found"}
                            renderCell={(row) => (
                            <>
                                <TableCell sx={{...CustomTableCell}}>
                                </TableCell>
                                <TableCell sx={{...CustomTableCell}}>
                                </TableCell>
                                <TableCell sx={{...CustomTableCell}}>
                                </TableCell>
                            </>
                            )}
                        />
                   </Box>
              </Box>
           </Box>
           <ActionDialog 
                type="approve" 
                open={approveDialogOpen} 
                onClose={handleCloseApproveDialog} 
                reviewApi={reviewLeaveRequest} 
                id={leaveData?._id}
                text="Approve Leave?"
            />
            <ActionDialog 
                type="reject" 
                open={rejectDialogOpen} 
                onClose={handleCloseRejectDialog} 
                reviewApi={reviewLeaveRequest} 
                id={leaveData?._id}
                text="Reject Leave?"
            />
       </MainBox>
       }
        </Box>
    )
}

export default RequestDetails;
