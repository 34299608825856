import React,{useState, useEffect} from 'react';
import {Box, CircularProgress} from '@mui/material';
import BtnPrimary from '../PrimaryButton';
import FormField from '../Formfield';
import MainBox from "../../app/main/container/MainBox";
import TypeBtn from '../AddRemoveButton';
import { findDepartment } from '../../utils/findDepartment';
import CheckboxField from '../CheckboxField';
import {getEmployeeListFromGroups, getEmployeeListFromDepartments} from '../../utils/getEmployeeList';
import { useNavigate } from 'react-router';
import { linkPolicy} from '../../services/expense';
import { findGroup } from '../../utils/findGroup';
import { addDepartmentsToLeavePolicy, addGroupsToLeavePolicy } from '../../services/leave';
import ErrorMsg from '../ErrorMsg';
import { updateShift } from '../../services/shift';
import SuccessDialog from '../SuccessDialog';
import { updateAttendancePolicy } from '../../services/attendancePolicy';

const ReviewSortBy = ({loadNextPage, sortBy, selectedList, policyId, rulesPageLink, type}) => {
    
    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const [error,setError] = useState("");
    const [list,setList]=useState();
    const [selectedPolicyBearers,setSelectedPolicyBearer] = useState([]);
    const [isSuccessDialogOpen,setIsSuccessDialogOpen] = useState(false);

    useEffect(()=>{
       if(sortBy==="Departments"){
        setList(selectedList.department?.map((item)=>findDepartment(item)));
        setSelectedPolicyBearer(getEmployeeListFromDepartments(selectedList.department));
       }else{
        setList(selectedList.employeeGroup?.map((item)=>findGroup(item)));
        setSelectedPolicyBearer(getEmployeeListFromGroups(selectedList.employeeGroup));
       }    
    },[])

    console.log(policyId);

    const handleAdd = () =>{
         loadNextPage("selectSortBy");
    }

    console.log(selectedList);

    const handleNextClick = async () => {
          setApiCall(true);
          if(type==="leave"){
            const result = await addDepartmentsToLeavePolicy(policyId,{departments:selectedList?.department});
            const result2 = await addGroupsToLeavePolicy(policyId,{groups:selectedList?.employeeGroup});
            console.log(result, result2);
            if(result.success && result2.success){
              navigate(rulesPageLink,{state:{policyId:policyId}});
            }else{
              setError(result.message);
            }
          }else if(type==="shift"){
            const result = await updateShift(policyId, {
              employeeGroup: selectedList?.employeeGroup, 
              department: selectedList?.department
            });
            if(result.success){
              setIsSuccessDialogOpen(true);
            }else{
              setError(result.message);
            }
          }else if(type==="attendance"){
            const result = await updateAttendancePolicy(policyId, {
              employeeGroup: selectedList?.employeeGroup, 
              department: selectedList?.department
            });
            if(result.success){
              navigate(rulesPageLink,{state:{policyId:policyId}});
            }else{
              setError(result.message);
            }
          }else{
            const result = await linkPolicy(policyId, { linkTo: selectedList });
            console.log(result);
            if(result.success){
              navigate(rulesPageLink,{state:{policyId:policyId}});
            }else{
              setError(result.message==="Duplicate policy cateogary"?
                        "Duplicate policy category":result.message);
            }
          }
          setApiCall(false);
    };

    return (
      <Box>
          <Box sx={{ width: "100%", display: "flex", gap:"1.5rem" }}>
                        <FormField
                            label={sortBy+":"}
                            type="text"
                            value={list?.map(obj => obj?.name).join(', ')}
                            disabled={true}
                        />
                        <TypeBtn type="add" onClick={handleAdd}>
                            Add
                        </TypeBtn>
           </Box>
           <Box sx={{
                     fontSize: '1rem',
                     lineHeight: '1.5rem',
                     color: '#818181',
                     marginBottom: '4px'
                    }}>
                No. of employees selected : {selectedPolicyBearers?.length}
            </Box>
           <MainBox sx={{padding:"2rem"}}>
                <Box sx={{display:"flex", gap:"1.125rem", flexWrap:"wrap"}}>
                          {selectedPolicyBearers?.map((item)=>(
                            <Box sx={{backgroundColor: '#E5F2FF',  borderRadius:"6px",padding:"4px", width:"10rem", height:"2rem",
                                textAlign:"center", fontWeight:"600", fontSize: '0.875rem', 
                                lineHeight: '1.125rem', color:"#00000080", letterSpacing:"-2.5%",
                                display:"flex", alignItems:"center"
                              }}>
                                <CheckboxField
                                    checked={true}
                                    disabled={true}
                                />
                                {item.fname+" "+item.lname}
                            </Box>
                          ))}
                </Box>
           </MainBox>
           <ErrorMsg>{error}</ErrorMsg>
           <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"3rem"}}>
                    <BtnPrimary 
                      onClick={handleNextClick} 
                      sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                      disabled={apiCall}
                    >
                        {apiCall?<CircularProgress/>:"Save and Apply"}
                    </BtnPrimary>
            </Box>
            <SuccessDialog 
              open={isSuccessDialogOpen} 
              onClose={()=>setIsSuccessDialogOpen(false)} 
              message={`Shift created successfully!`} 
              addNewLink={"/app/shift/new/details"} 
              addNewText="Create New Shift" 
              viewAllLink={"/app/shift/listing"} 
              viewAllText="View All Shifts"
            />  
      </Box>
    )
}

export default ReviewSortBy