import React,{useState} from 'react'
import { Avatar, Box, Button, Menu, MenuItem} from '@mui/material'
import { SearchBox } from './SearchBox'
import BtnFilter from './FilterButton'
import { default as FilterIcon } from '../assets/icons/filter.svg';
import DepartmentDropdown from './Department';
import DateComponent from "./DatePicker";
import styled from "@emotion/styled";
import CSVButton from './CSVButton';

const FilterButton = styled(Button)({
  border: "1px solid  #16151C33",
  backgroundColor: "white",
  color: "#16151C",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  letterSpacing: "0.19px",
  wordWrap: "break-word",
  textAlign: "center",
  textTransform: "none",
});

const TableHeader = ({data, setDate, setOrder, handleSearch, searchQuery, sx}) => {
  
  const [selectedDate, setSelectedDate] = useState();  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDepartmentChange = () =>{}

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setDate(date);
  };

  return (
    <Box display="flex" justifyContent="space-between" marginBottom={"1.5rem"}>
            <Box display="flex" gap="1rem">
                <SearchBox handleSearch={handleSearch} searchQuery={searchQuery} styles={sx}/>
                <Box>
                    <FilterButton 
                      sx={{padding: '1rem 1.5rem',height:"3.5rem", borderRadius: '12px',...sx}} 
                      endIcon={<Avatar src={FilterIcon} style={{ width: '0.8rem', height: '0.8rem' }}/>}
                      onClick={handleClick}
                    >
                      Filter
                    </FilterButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem onClick={()=>{
                                              setOrder("asc");
                                              handleClose();
                                        }}>
                            Ascending Order
                      </MenuItem>
                      <MenuItem onClick={()=>{
                                              setOrder("desc");
                                              handleClose();
                                        }}>
                            Descending Order
                      </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Box display="flex" gap="1rem">
                <DepartmentDropdown onChange={handleDepartmentChange} sx={sx}/>
                <DateComponent value={selectedDate} onDateSelect={handleDateSelect} styles={sx}/> 
                <CSVButton data={data} styles={{padding: '1rem 1.5rem',height:"3.5rem", borderRadius: '12px', ...sx}}/>
            </Box>
    </Box> 
  )
}

export default TableHeader