import React, { useState } from 'react'
import { Box } from '@mui/material'
import FormField from '../../../../../component/Formfield'
import BtnPrimary from '../../../../../component/PrimaryButton';

const AttendanceDetails = ({selectedDate, setSelectedDate}) => {

 const [attendanceData,setAttendanceData] = useState({
        clockInTime:"",
        clockInLocation:"",
        clockOutTime:"",
        clockOutLocation:""
  });

  return (
    <Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
            <FormField
                label="Marked Date"
                type="text"
                value={selectedDate.format('YYYY-MM-DD')}
                fullwidth
                disabled={true}
              />
              <FormField
                label="Clock In Time"
                type="text"
                value={attendanceData.clockInTime}
                disabled={true}
              />
              <FormField
                label="Clock In Location"
                type="text"
                value={attendanceData.clockInLocation}
                disabled={true}
              />
              <FormField
                label="Clock Out Time"
                type="text"
                value={attendanceData.clockOutTime}
                disabled={true}
              />
              <FormField
                label="Clock Out Location"
                type="text"
                value={attendanceData.clockOutLocation}
                disabled={true}
              />
        </Box>
        <Box sx={{display:"flex", justifyContent:"end", marginTop:"3rem"}}>
              <BtnPrimary 
                onClick={()=> {setSelectedDate(null)}} 
                sx={{width:"11.25rem", height:"3rem", borderRadius:"8px"}}
              >
                  Back
              </BtnPrimary> 
        </Box>
    </Box>
  )
}

export default AttendanceDetails