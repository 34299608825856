import React,{useState} from 'react';
import {Box, CircularProgress} from '@mui/material';
import BtnPrimary from './PrimaryButton';
import FormField from './Formfield';
import MainBox from "../app/main/container/MainBox";
import { createGroup } from '../services/employeeGroups';
import { getLegalUnitList, getOrgEmployees } from '../services/globalData';
import CheckboxField from './CheckboxField';
import SuccessDialog from './SuccessDialog';
import { getAllGroups } from "../services/employeeGroups";
import { setOrgGroups } from '../services/globalData';

const CreateGroup = ({redirectLink, loadNextPage}) => {
    const [apiCall,setApiCall]=useState(false);
    const [name,setName] = useState("");
    const [selectionType,setSelectionType]=useState("Employee name");
    const employeeList = getOrgEmployees();
    const [employee,setEmployee]=useState([]);
    const [isDialogOpen,setIsDialogOpen]=useState(false);

    console.log(employeeList);
    console.log(loadNextPage);

    const handleNextClick = async() =>{
        const legalUnit = getLegalUnitList();
        const groupData = {name:name,employee:employee, legalUnit:legalUnit[0]._id}
        setApiCall(true);
        try {
            const res = await createGroup(groupData);
            console.log(res);
            if(res.success){
                const groups = await getAllGroups();
                console.log(groups);
                setOrgGroups(groups?.data);
            }
            setIsDialogOpen(true);
            setName("");
            setEmployee([]);
        } catch (error) {
            console.error('Error creating Group:', error);
        }
        setApiCall(false);
    }

    const handleCheckboxChange = (id, isChecked) => {
        if (isChecked) {
            setEmployee(prevEmployee => [...prevEmployee, id]);
        } else {
            setEmployee(prevEmployee => prevEmployee.filter(empId => empId !== id));
        }
    };

    return (
      <Box>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <FormField
                            label="Group Name"
                            type="text"
                            value={name}
                            onChange={(e)=>setName(e.target.value)}
                        />
           </Box>
           <Box sx={{
                     fontSize: '1rem',
                     lineHeight: '1.5rem',
                     color: '#818181',
                     marginBottom: '4px'
                    }}>
                No. of employees selected : {employee.length}
            </Box>
           <MainBox sx={{padding:"2rem"}}>
                      <FormField
                          type="select"
                          options={["Employee name","Department name","Group name", "Designation"]}
                          value={selectionType}
                          onChange={(e)=>{setSelectionType(e.target.value)}}
                      />
                      <Box sx={{display:"flex", gap:"1.125rem", flexWrap:"wrap"}}>
                          {employeeList.map((item)=>(
                            <Box sx={{backgroundColor: '#E5F2FF',  borderRadius:"6px",padding:"4px", width:"10rem", height:"2rem",
                                textAlign:"center", fontWeight:"600", fontSize: '0.875rem', 
                                lineHeight: '1.125rem', color:"#00000080", letterSpacing:"-2.5%",
                                display:"flex", alignItems:"center"
                              }}>
                                <CheckboxField
                                    checked={employee?.includes(item._id)}
                                    onChange={(isChecked) => handleCheckboxChange(item._id, isChecked)}
                                />
                                {item.fname+" "+item.lname}
                            </Box>
                          ))}
                      </Box>
           </MainBox>
           <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
                    <BtnPrimary 
                      onClick={handleNextClick} 
                      sx={{width:"11.875rem", height:"3.125rem", borderRadius:"10px"}}
                      disabled={apiCall}
                    >
                        {apiCall?<CircularProgress/>:"Save Group"}
                    </BtnPrimary>
            </Box>
            <SuccessDialog 
              open={isDialogOpen} 
              onClose={()=>setIsDialogOpen(false)} 
              message={`${name} group is created successfully!`} 
              addNewLink={redirectLink} 
              addNewText="Create New Group" 
              viewAllLink={() => loadNextPage("sortBy")} 
              viewAllText="Continue Assigning Policy"
            />
      </Box>
    )
}

export default CreateGroup