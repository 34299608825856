import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import PaginationTable from '../../../../../../../component/PaginationTable';
import { getAllTripsPolicy} from '../../../../../../../services/trips';
import { getLegalUnitList } from "../../../../../../../services/globalData";
import { getEmployeeNames } from '../../../../../../../utils/getEmployeeNames';
import { useNavigate } from "react-router-dom";
import PolicyListingHeader from "../../../../../../../component/PolicyListingHeader";
import { PolicyTableCell } from "../../../../../../../styles/PolicyTableCell";
import { PolicyTableHeadings } from "../../../../../../../styles/PolicyTableHeadings";


const TripsPolicyListing = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const legalUnit = getLegalUnitList();
          const res = await getAllTripsPolicy(legalUnit[0]._id);
          console.log(res); 
          const employeeIds = res.data.map(data => data.createdBy);
          const combinedData = await getEmployeeNames(res.data, employeeIds, "createdBy");
          setData(combinedData?.reverse());
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
    
      fetchData();
    }, []);

    const headings = [
      { label: "Policy Id", style: {...PolicyTableHeadings, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" } },
      { label: "Policy Name", style: {...PolicyTableHeadings} },
      { label: "Start Date", style: {...PolicyTableHeadings} },
      { label: "End Date", style: {...PolicyTableHeadings} },
      { label: "Created By", style: {...PolicyTableHeadings} },
      { label: "Category attached", style: { ...PolicyTableHeadings, borderTopRightRadius: "12px", borderBottomRightRadius: "12px" } }
    ];

    const handleMenuItemClick = (id) => { 
      navigate("/app/trips/edit",{state:{policyId:id}});
    };

  return (
    <Box>
        <PolicyListingHeader 
          Heading = "Trips Policies"
          ButtonText = "+ Create Trips Policy"
          ButtonLink = "/app/trips/new/setting"
        />
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          renderCell={(row) => (
            <>
              <PolicyTableCell>{row?.code}</PolicyTableCell>
              <PolicyTableCell onClick={() => handleMenuItemClick(row._id)}>
                 <Box sx={{width:"100%", ":hover":{cursor:"pointer"}, color:"#008cc4",textDecoration:"underline"}}>
                      {row.name}
                </Box>
              </PolicyTableCell>
              <PolicyTableCell>{new Date(row.startDate).toLocaleDateString('en-GB')}</PolicyTableCell>
              <PolicyTableCell>{new Date(row.endDate).toLocaleDateString('en-GB')}</PolicyTableCell>
              <PolicyTableCell>{row.employee.fname+" "+row.employee.lname}</PolicyTableCell>
              <PolicyTableCell>{row.policyCateogary.length}</PolicyTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default TripsPolicyListing