import React,{useState, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from './PolicyNavbarUpdated';
import BtnPrimary from './PrimaryButton';
import FormField from './Formfield';
import TypeBtn from './AddRemoveButton';
import SuccessDialog from './SuccessDialog';
import ErrorMsg from './ErrorMsg';
import {updatePolicy} from "../services/policy";
import { getOrgEmployees } from '../services/globalData';
import { getPolicyById } from '../services/expense';

const PolicyRules = ({addNewLink, viewAllLink}) => {
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [apiCall,setApiCall] = useState(false);
  const [policyId,setPolicyId] = useState("");
  const [approver, setApprover] = useState([""]);
  const [errorMsg,setErrorMsg] = useState("");
  const {state} = useLocation();
  const [isSuccessDialogOpen,setIsSuccessDialogOpen] = useState(false);
  const approverOptions = getOrgEmployees().map(employee => ({
    name: employee.fname+" "+employee.lname,
    value: employee._id
  }));

  console.log(state);

  useEffect(() => {
    if (state) {
      setPolicyId(state.policyId);
      fetchPolicyData(state.policyId); 
    } 
  }, [state]);

  const fetchPolicyData = async (policyId) => {
    setLoading(true);
    try {
        const res = await getPolicyById(policyId); 
        console.log(res);
        setApprover(res.data.approverIds); 
    }catch (error) {
        console.error('Error fetching policy data:', error);
    }
    setLoading(false);
  };

  const handleAddApproval = () =>{
    setApprover([...approver,""])
  }

  const handleRemoveApprover = (index) => {
    let approverList = [...approver];
    approverList.splice(index, 1);
    setApprover(approverList);
  };

  const handleApproverChange = (index, value) => {
    const updatedApprover = [...approver];
    updatedApprover[index] = value;
    setApprover(updatedApprover);
  };

  const handleNextClick = async() => {
    setApiCall(true);
    try{
      console.log(policyId,{approverIds:approver});
      const res = await updatePolicy(policyId,{approverIds:approver});
      console.log(res);
      if(res.success){
        setIsSuccessDialogOpen(true);
      }else{
        setErrorMsg("Select Approver to proceed");
      }
    }catch(error){
      console.log(error.response);
    }
    setApiCall(false);
};

  return (
    loading?(
      <Box sx={{width:"100%", height:"100%",display:"flex", justifyContent:"center"}}>
        <CircularProgress/>
      </Box>
    ):(
    <Box sx={{width:"100%", height:"100%"}}>
        <Box sx={{display:"flex", justifyContent:"space-between"}}>
              <ActiveBar active={["Policy Details","Add Category","Assign Policy","Rules"]} value="80"/>
        </Box>
        <Box sx={{marginTop:"2rem", paddingBlock:"1rem"}}>
            <Box sx={{fontWeight:"500", fontSize: "1rem",lineHeight: "1.5rem", color:"#818181",marginBottom:"2rem"}}>
              Approval Chain
            </Box>
            <Box>
            {
                approver?.map((item,index)=>(
                  <Box key={index} 
                       sx={{ width: "100%", 
                             display: "flex",  
                             marginBottom: "1rem", 
                             justifyContent:"space-between",
                             padding:"1.125rem 1.875rem",
                             borderRadius:"10px",
                             border:"1px solid #A2A1A833"
                          }}
                  >
                        <FormField
                            label="Employee Name"
                            type="select"
                            options={approverOptions}
                            value={item}
                            onChange={(e) => handleApproverChange(index, e.target.value)}
                        />
                        <TypeBtn type="remove" onClick={()=>handleRemoveApprover(index)}>
                                Remove 
                        </TypeBtn>
                  </Box>
                ))
            }
            <TypeBtn onClick={handleAddApproval} type="add">
                Add Approver
            </TypeBtn>
            <ErrorMsg>
              {errorMsg}
            </ErrorMsg>
          </Box>
        </Box>
        <Box sx={{display:"flex", justifyContent:"end", marginTop:"1.875rem"}}> 
            <BtnPrimary 
            onClick={handleNextClick} 
            sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
            disabled={apiCall}
            >
            {apiCall?<CircularProgress/>:"Save Policy"}
            </BtnPrimary>
        </Box>
        <SuccessDialog 
              open={isSuccessDialogOpen} 
              onClose={()=>setIsSuccessDialogOpen(false)} 
              message={`Policy created successfully!`} 
              addNewLink={addNewLink} 
              addNewText="Create New Policy" 
              viewAllLink={viewAllLink} 
              viewAllText="View All Policies"
        />
    </Box>
  ))
}

export default PolicyRules