import { MenuItem, TextField } from '@mui/material'
import React from 'react'

const ButtonStyle = {
    borderRadius: '4px',
    fontSize: '0.875rem',
    lineHeight: '1.31rem',
    fontWeight:"300",
    color: '#1E1E1E',
    height: "2rem", 
    width:"10.25rem",
    padding:"0.75rem",
    backgroundColor:"#0081FF",
    color:"#FFFFFF",
}

const DropdownButton = ({value,options,onChange}) => {

  return (
    <TextField
        select
        variant="outlined"
        value={value}
        onChange={onChange}
        sx={{
            '& .MuiInputBase-root': {...ButtonStyle},
            "& .MuiOutlinedInput-notchedOutline":{border: 'none'},
            '& .MuiSelect-icon':{color:"#FFFFFF"}
        }}
        >
        { options.map((option) => {
          if (typeof option === 'object' && option !== null) {
            return (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.name}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          }
        })}
    </TextField>
  )
}

export default DropdownButton