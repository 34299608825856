import React,{useState, useEffect} from 'react';
import { Box, Avatar, CircularProgress, TextField, Autocomplete} from "@mui/material";
import styled from '@emotion/styled';
import BtnSecondary from "../../../../../../../component/SecondaryButton";
import { getLegalUnitList, getOrgDepartments, getOrgGroups } from "../../../../../../../services/globalData";
import { useLocation } from "react-router-dom";
import ProgressBar from '../../../../../../../component/ProgressBar';
import DocsIcon from '../../../../../../../assets/icons/docs.svg';
import { findGroup } from '../../../../../../../utils/findGroup';
import { findDepartment } from '../../../../../../../utils/findDepartment';
import FormField from '../../../../../../../component/Formfield';
import Pencil from '../../../../../../../assets/icons/pencil.svg';
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import SortByTable from '../../../../../../../component/assign-policy/SortByTable';
import ErrorMsg from '../../../../../../../component/ErrorMsg';
import SaveDialog from '../../../../../../../component/SaveDialog';
import { getShiftById, updateShift } from '../../../../../../../services/shift';
import TopBar from '../../../../../../../component/TopBar';
import MainBox from '../../../../../container/MainBox';
import SetShift from './new/SetShift';

const initialDayState = {
  isHoliday: true,
  startTime: undefined,
  endTime: undefined,
  breakTime:0,
  hoursMetric: "gross",
  minHalfDayHours: 0,
  minFullDayHours: 0,
  maxHalfDayHours: 0,
  maxFullDayHours: 0,
};

const HeadingBox = styled(Box)({
        fontSize: "1rem",
        lineHeight: "1.5rem",
});

const HeaderSection = () => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.9375rem', alignItems: 'end' }}>
      <Box>
          <Box sx={{ display: 'flex' }}>
              <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }} />
              <Box sx={{
                      fontWeight: '300',
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      color: '#0081FF',
                      marginBottom: '0.56rem',
                  }}
              >
                  Shift Details
              </Box>
          </Box>
          <ProgressBar />
      </Box>
      <BtnSecondary sx={{ width: '5.56rem', height: '2.25rem', borderRadius: '10px' }} to="/app/shift/listing">
          Back
      </BtnSecondary>
  </Box>
);

const Headings = ({title,onEditClick, onSaveClick, edit, apiCall}) => (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom:"1.875rem" }}>
      <HeadingBox>{title}</HeadingBox>
      {
        edit?(
          <BtnPrimary sx={{ width: '10rem', height: '2.25rem', borderRadius: '10px' }} onClick={onSaveClick}>
            {apiCall?<CircularProgress size={"1rem"}/>:"Save Changes"}
          </BtnPrimary>
        ):<Avatar src={Pencil} sx={{width:"1.5rem",height:"1.5rem",'&:hover': {cursor: "pointer"}}} onClick={onEditClick}/>
      }
    </Box>
)


const EditShift = () => {
        const {state} = useLocation();
        const [loading, setLoading] = useState(true);
        const [apiCall,setApiCall]=useState(false);
        const [isSaveOpen, setIsSaveOpen]=useState(false);
        const [shiftData,setShiftData] = useState({
              code:"",
              name:"",  
              employeeGroup:[],
              department:[],
              legalUnit:""         
        });
        const [validationErrors, setValidationErrors] = useState({code:"",name:""});
        const [error,setError]=useState("");
        const [sortBy,setSortBy] = useState("");
        const [detailsEdit,setDetailsEdit]=useState(false);
        const [assignPolicyEdit,setAssignPolicyEdit]=useState(false);
        const [selectedList,setSelectedList] = useState({department: [],employeeGroup:[]});
        const [data, setData] = useState();
        const allDepartments = getOrgDepartments();
        const allGroups = getOrgGroups();
        const [workingDays, setWorkingDays] = useState([]);
        const [holidays, setHolidays] = useState([]);
        const [selectedLocation, setSelectedLocation]=useState("");
        const legalUnits = getLegalUnitList()?.map(legalUnit => ({
          id: legalUnit?._id,
          label: legalUnit?.name
        }));


        const onLocationChange = (value) =>{
          setSelectedLocation(value);
          setShiftData(prevState => ({
            ...prevState,
            legalUnit: value?.id
          }));
        }

        useEffect(()=>{
          setData(sortBy==="Departments"?allDepartments:allGroups);
        },[sortBy])

        useEffect(()=>{
          getShiftData(state?.shiftId);
        },[]);

        //to get shift data
        const getShiftData = async (shiftId) => {
                setLoading(true);
                try {
                    const res = await getShiftById(shiftId); 
                    const data = res.data;
                    console.log(data);
                    if(res.success){
                          setShiftData(prevState => ({
                            ...prevState,
                            code: data?.code,
                            name: data?.name,
                            days: data?.days,
                            employeeGroup: data?.employeeGroup,
                            department: data?.department,
                            legalUnit: data?.legalUnit,
                            flexibleWorkingHours: data?.flexibleWorkingHours,                          
                            doesScheduleVary: data?.doesScheduleVary
                        })); 
                        const shiftLocation = legalUnits?.find((option) =>
                          data?.legalUnit===option?.id
                        );
                        setSelectedLocation(shiftLocation);
                    }
                    data?.days?.map((day, index)=>{
                      console.log(day?.isHoliday);
                      if(day?.isHoliday){
                        holidays.push(index);
                      }else{
                         workingDays.push(index);
                      }
                    })
                    if(data?.employeeGroup?.length>0){
                       setSortBy("Groups");
                       setSelectedList(prevState => ({...prevState,employeeGroup: data?.employeeGroup}));
                    }else if(data?.department?.length>0){
                      setSortBy("Departments");
                      setSelectedList(prevState => ({...prevState,department: data?.department}));
                    }
                } catch (error) {
                    console.error('Error fetching shift data:', error);
                }
                setLoading(false);
        };
          
        //to manage input
        const handleChange = (field, value) => {
                const updatedValue = field === "code" ? value?.toUpperCase() : value;
                setShiftData(prevState => ({...prevState, [field]: updatedValue}));
                setValidationErrors(prevState => ({...prevState, [field]: ""}));
                setError("");
        };

        //to display selected policy bearer
        const formatPolicyBearer = (linkTo) =>{
          const employeeGroups = linkTo?.employeeGroup?.map(findGroup)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          const departments = linkTo?.department?.map(findDepartment)?.map(obj => obj?.name).filter(Boolean).join(', ') || '';
          if(employeeGroups || departments)
              return `${employeeGroups}${employeeGroups && departments ? ', ' : ''}${departments}`;
          else
              return "No Shift Bearer Assigned Yet"
        }
            
      const handleSaveChanges = async() =>{
        setError("");
        setApiCall(true);
        const res = await updateShift(state?.shiftId,{...shiftData,
          employeeGroup: selectedList?.employeeGroup, 
          department: selectedList?.department,
          legalUnit: selectedLocation?.id
        });
        console.log(res);
        setApiCall(false);
        setDetailsEdit(false);
        setAssignPolicyEdit(false);
      }

      const handleNextClick = () =>{
        handleSaveChanges();
        setIsSaveOpen(true);
      }

  return (
    loading?(
      <Box sx={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
        <CircularProgress/>
      </Box>
    ):(
      <Box sx={{width:"100%",paddingBlock:"1rem"}}>
        <TopBar MainHeading={"Shift Schedular"}/>
        <MainBox sx={{ padding: "1.8125rem 1.25rem", height:"100%" }}>
        <Box>
        <HeaderSection/>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Shift Details" 
             edit={detailsEdit} 
             onEditClick={()=>setDetailsEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                      <FormField
                          label="Shift Code"
                          type="text"
                          value={shiftData.code}
                          validation={validationErrors.code}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('code', e.target.value)}
                      />
                      <FormField
                          label="Shift Name"
                          type="text"
                          value={shiftData.name}
                          validation={validationErrors.name}
                          disabled={!detailsEdit}
                          onChange={(e) => handleChange('name', e.target.value)}
                      />
                      <SetShift 
                           disabled={!detailsEdit}
                           shiftData={shiftData}
                           setShiftData={setShiftData}
                           workingDays={workingDays}
                           setWorkingDays={setWorkingDays}
                           holidays={holidays}
                           setHolidays={setHolidays}
                           initialDayState={initialDayState}
                        />
                      <Box sx={{width:"48%"}}>
                           <Box sx={{ fontSize: '1rem',lineHeight: '1.5rem',color: '#818181',marginBottom: '4px'}}>
                            Shift Location
                           </Box>
                           <Autocomplete
                            options={legalUnits}
                            size="small"
                            value={selectedLocation}
                            onChange={(e,newValue)=>onLocationChange(newValue)}
                            disabled={!detailsEdit}
                            sx={{
                              '& .MuiInputBase-root':{
                                 borderRadius: '10px',
                                 fontSize: '1rem',
                                 lineHeight: '1.5rem',
                                 fontWeight:"300",
                                 color: '#16151CCC',
                                 height:"3rem",
                                },
                                "& .MuiOutlinedInput-notchedOutline":{
                                  border: '1px solid #A2A1A833'
                                }
                            }}
                            renderInput={(params)=><TextField {...params}/>}
                          />
                        </Box>
            </Box>
        </Box>
        <Box sx={{marginBottom: '1.9375rem'}}>
            <Headings 
             title="Assign Shift" 
             edit={assignPolicyEdit} 
             onEditClick={()=>setAssignPolicyEdit(true)} 
             onSaveClick={handleSaveChanges}
             apiCall={apiCall}
            />
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Sort By"
                            type="select"
                            options={["Departments","Groups"]}
                            value={sortBy}
                            disabled={!assignPolicyEdit}
                            onChange={(e)=>{setSortBy(e.target.value)}}
                        />
                        <FormField
                            label="Shift Bearers"
                            type="text"
                            value={formatPolicyBearer(selectedList)}
                            disabled={true}
                        />
            </Box>
            <Box sx={{marginBottom:"1rem"}}>
                <ErrorMsg>{error}</ErrorMsg>
            </Box>
            {(sortBy && assignPolicyEdit) && (
              <SortByTable
                sortBy={sortBy}
                data={data}
                selectedList={selectedList}
                setSelectedList={setSelectedList}  
              />
            )}   
        </Box>
        <Box sx={{display:"flex", justifyContent:"center", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"18.75rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress color='info'/>:"Save Changes"}
              </BtnPrimary> 
        </Box>
        <SaveDialog 
            open={isSaveOpen} 
            onClose={()=>setIsSaveOpen(false)} 
            redirectLink={"/app/shift/listing"}
        />
      </Box>
    </MainBox>
    </Box> 
  ))
}

export default EditShift
        