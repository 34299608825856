import React, { useState } from 'react';
import styled from "@emotion/styled";
import { Box, TextField, InputAdornment } from '@mui/material';
import CalenderIcon from "../assets/icons/calendar-dash.svg";
import DateExpand from "./DateExpand";

const DatePicker = ({ value, styles, onDateSelect}) => {

  const [isDialogOpen,setIsDialogOpen]=useState(false);

  const onSelect = (date) =>{
    onDateSelect(date);
    setIsDialogOpen(false);
  }

  return (
    <Box>
      <TextField
        value={value || new Date().toLocaleDateString('en-GB')}
        onClick={()=>setIsDialogOpen(true)}
        variant="outlined"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <img src={CalenderIcon} alt="Calendar" />
            </InputAdornment>
          ),
        }}
        sx={{ 
          "& .MuiInputBase-root":{
                ...styles,
                width:"10.5rem",  
                borderRadius:"12px",
                fontSize: '1rem',
                lineHeight:"1.5rem",
                color:"#16151C",
                fontWeight: '300',
                paddingInline:"1rem"
          },
          "& .MuiOutlinedInput-notchedOutline":{
                border:"0.5px solid #16151C33"
          }
        }}
      />
      <DateExpand open={isDialogOpen} onClose={()=>setIsDialogOpen(false)} onSelect={onSelect} />
    </Box>
  );
};

export default DatePicker;
