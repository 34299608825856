import React, {useEffect, useState} from 'react';
import { Dialog,Box, Avatar} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "./PrimaryButton";
import BtnSecondary from "./SecondaryButton";
import FormField from './Formfield';
import GreenTick from "../assets/icons/greentick.png";
import RedCross from "../assets/icons/red-cross-filled.png";
import ErrorMsg from './ErrorMsg';

const TextBox = styled(Box)({
    display: "block",
    textAlign:"center",
    fontWeight:"500",
    color:"#5C5C5C"
});

function ActionDialog({type, open, onClose, reviewApi, id, text}) {

  const [comment,setComment] = useState("");
  const [errorMsg,setErrorMsg] = useState("");

  useEffect(() => {
    if (!open) {
      setComment("");
      setErrorMsg("");
    }
  }, [open]);

  const handleYes = async () => {
    const Form = {
      status:type==="approve"?"Approved":"Rejected",
      reviewerComment:type==="approve"?"Approved":comment
   }
    try {
      console.log(id);
      const res = await reviewApi(Form, id); 
      console.log(res); 
      if(res.success){
        onClose();
      }else{
        setErrorMsg(res?.data?.message || res?.message);
      }
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"30%",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.5rem",
          display:"flex",
          flexDirection:"column",
          gap:"1.5rem"
        }
      }}
    >
        <Box sx={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center"}}>
            <Avatar src={type==="approve"?GreenTick:RedCross} alt="icon" sx={{height:"3rem", width:"3rem"}}/>
        </Box>
        <TextBox>{text}</TextBox>
        {type==="reject"?
          <FormField
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullwidth={true}
        />
        :null}
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <ErrorMsg>
              {errorMsg}
            </ErrorMsg>
        </Box>
        <Box style={{display:"flex",justifyContent:"center", gap:"1.5rem", width:"100%"}}>
           <BtnPrimary onClick={handleYes} sx={{width:"6.25rem", height: '3rem', padding: '1.25rem', borderRadius: '10px' }}>
                Yes
           </BtnPrimary>
           <BtnSecondary onClick={onClose} sx={{width:"6.25rem", height: '3rem', padding: '1.25rem', borderRadius: '10px' }}>
                No
           </BtnSecondary>
        </Box>
    </Dialog>
  );
}

export default ActionDialog;

