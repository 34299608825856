import React,{useState, useEffect} from 'react';
import {Box} from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import { useLocation, useNavigate } from 'react-router';
import FormField from '../../../../../../component/Formfield';

const StatutoryComponentForm = () => {

  const {state} = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  console.log(state);
  const EPF = [{heading:"Employee's Contribution",items:[{label:"EPF(12% of 20,000)",value:"₹2,400"}]},
               {heading:"Employer's Contribution",items:[{label:"EPS(8.33% of 20,000 (Max of 15,000))",value:"₹1,250"},
                                                         {label:"EPF(12% of 20,000)-EPS",value:"₹1,150"}
                                                        ]}
              ]

  const handleChange = (label, newValue) => {
    setFormData(prevFormData => {
      const updatedFormData = prevFormData.map(item => {
        if (item.label === label) {
          return { ...item, value: newValue }; 
        }
        return item;
      });
      return updatedFormData;
    });
  };

  return (
     <Box sx={{width:"100%"}}>
        <Topbar MainHeading={state.mainHeading} SubHeading={"Payroll > Statutory Component"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
          <Box sx={{display:"flex", gap: "1.5rem"}}>
            <Box sx={{width:"100%", flex:1}}>
                {
                    state?.data?.map((item)=>{
                        return(
                                <FormField
                                label={item.label}
                                value={item.value}
                                fullwidth
                                onChange={(e)=> handleChange(item.label,e.target.value)}
                                />
                        )
                    })
                }
            </Box>
            {
                state.mainHeading==="Employees Provident Fund"?(
                   <Box sx={{padding:"1.5rem",backgroundColor:"#FEF3C733", display:"flex", flexDirection:"column", gap:"1rem", height:"70%"}}>
                         <Box sx={{fontSize:"1.25rem", fontWeight:"600"}}>Sample EPF Calculation</Box>
                         <Box sx={{fontSize:"0.875rem", fontWeight:"300"}}>Let's assume PF wage is ₹20,000. The breakup of contribution will be</Box>
                         <Box sx={{backgroundColor:"white", borderRadius:"10px", border:"1px solid #A2A1A833", padding:"1rem"}}>
                            {EPF.map((item)=>(
                                <Box sx={{borderBottom:"1px solid #A2A1A833", paddingBlock:"1rem", display:"flex", flexDirection:"column", gap:"1rem"}}>
                                    <Box sx={{fontWeight:"600"}}>{item.heading}</Box>
                                    {item.items.map((content)=>(
                                        <Box sx={{display:"flex", justifyContent:"space-between"}}>
                                            <Box sx={{fontWeight:"300"}}>{content.label}</Box>
                                            <Box sx={{fontWeight:"300"}}>{content.value}</Box>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                                <Box sx={{fontWeight:"500"}}>Total</Box>
                                <Box sx={{fontWeight:"500"}}>₹2400</Box>
                            </Box>
                         </Box>
                    </Box>
                ):(null)
            }
          </Box>
          <Box sx={{display:"flex", gap: "1.25rem", marginBlock: "1.875rem", justifyContent:"flex-end"}}>
                <BtnPrimary 
                 sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Save
                </BtnPrimary>
                <BtnSecondary 
                 onClick={() => navigate(-1)}
                 sx={{ borderRadius: "10px", width: "5.68rem", height: "3.125rem" }}>
                    Cancel
                </BtnSecondary>
           </Box>
        </MainBox>
     </Box>
      
  );
}

export default StatutoryComponentForm;