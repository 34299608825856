import React from "react";
import { Stack, Box} from "@mui/material";
import styled from "@emotion/styled";
import CurrentDateTime from "./CurrentDateTime";

const TextBox = styled(Box)({
  display: "block",
  wordBreak: "break-word",
});

const ColoredText = styled.span({
    color: "#0081FF",
});

const DashboardHeader = ({ heading, tab1, tab2, tab3 }) => {
  return (
    <Stack width="100%" direction="row" justifyContent="space-between" marginBottom="1.6rem" paddingInline="0.64rem" alignItems="center">
      <Box>
        <TextBox
          fontSize={"1.5rem"}
          lineHeight={"2.5rem"}
        >
          {heading}
        </TextBox>
        <TextBox
            fontSize={"0.875rem"}
            fontWeight={300}
            lineHeight={"1.25rem"}
            color={"#00000099"}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <Box>{tab1}</Box>
            <Box>{tab2}</Box>
            <Box><ColoredText>{tab3}</ColoredText></Box>
        </TextBox>
      </Box>
      <CurrentDateTime />
    </Stack>
  );
};

export default DashboardHeader;
