//to format date in June 24' format

export const formatDate = (date) =>{
    const formattedDate = new Date(date)
    const options = { month: 'long' };
    const month = formattedDate.toLocaleDateString('en-US', options);
    const year = formattedDate.getFullYear().toString().slice(-2);
    return `${month} ${year}'`;
}


export const formatDateByDay = (date) =>{
    const formattedDate = new Date(date)
    const options = { month: 'long' };
    const day = formattedDate.getDate().toString();
    const month = formattedDate.toLocaleDateString('en-US', options);
    const year = formattedDate.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}'`;
}

