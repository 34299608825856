
//   const [vehicleTypes,setVehicleTypes] = useState([]);
//   const [vehicleOptions, setVehicleOptions] = useState([]);
 
//  useEffect(() => {
//       fetchVehicleTypeData();
//       if (state && state.mode) {
//         setMode(state.mode);
//         setPolicyId(state.policyId);
//         fetchPolicyData(state.policyId); 
//       }else{
//         setMode("create");
//       }
//     }, [state]);

//     const fetchVehicleTypeData = async () => {
//       try {
//         const legalUnit = getLegalUnitList();
//         const res = await getAllVehicleType(legalUnit[0]._id);
//         console.log(res); 
//         const mappedOptions = res.data.map(option => ({ value: option._id, name: option.name }));
//         setVehicleOptions(mappedOptions);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };


//     const handleAddVehicleTypeClick = () =>{
//       setVehicleTypes([...vehicleTypes,{_id:"",`ratePerKm:""`}])
//     }

//     const handleRemoveVehicleTypeClick = (index) => {
//       let updatedVehicleType = [...vehicleTypes];
//       updatedVehicleType.splice(index, 1);
//       setVehicleTypes(updatedVehicleType);
//     };

//     const handleVehicleChange = (index, fieldName, value) => {
//       let updatedVehicleType = [...vehicleTypes];
//       updatedVehicleType[index][fieldName] = value;
//       setVehicleTypes(updatedVehicleType);
//     };


//     const handleNextClick = async() => {
//       console.log(PolicyFormData);
//       setApiCall(true);
//       try {
//         if (mode === 'create') {
//           PolicyFormData.type="TRIP"
//           const legalUnit = getLegalUnitList();
//           PolicyFormData.legalUnit = legalUnit[0]._id;
//           const res = await createTripPolicy(PolicyFormData);
//           if(res.success){
//             navigate("/app/trips/new/groups",{state:{policyId: res.data._id, mode}});
//           } else {
//               console.error('Policy creation failed:', res.error);
//               setError(res.message);
//           }
//         } else if (mode === 'edit') {
//           const res = await updateTripPolicy(policyId,PolicyFormData);
//           console.log(res);
//           if(res.success){
//             navigate("/app/trips/new/groups",{state:{policyId, mode}});
//           }else{
//             console.error('Policy updation failed:', res.error);
//             setError(res.message);
//           }
//         } else {        
//            navigate("/app/trips/new/groups",{state:{policyId, mode}});  
//         }
//       } catch (error) {
//           console.error('Error saving Policy:', error);
//       }
//       setApiCall(false);
//   };


//           <TypeBtn type="add" onClick={handleAddVehicleTypeClick} disabled={mode === 'view'}>
//               Add Vehicle
//           </TypeBtn>
//           {
//             vehicleTypes?.map((item,index)=>(
//               <Box key={index} sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "1rem", justifyContent: "space-between" }}>
//                      <FormField
//                          label="Vehicle"
//                          type="select"
//                          options={vehicleOptions}
//                          value={item._id}
//                          disabled={mode === 'view'}
//                          onChange={(e) => handleVehicleChange(index, '_id', e.target.value)}
//                      />
//                      <FormField
//                          label="Rate per km"
//                          type="text"
//                          value={item.ratePerKm}
//                          disabled={mode === 'view'}
//                          onChange={(e) => handleVehicleChange(index, 'ratePerKm', e.target.value)}
//                      />
//                      <Box sx={{width:"100%", display:"flex", justifyContent:"end"}}>
//                          <TypeBtn type="remove" onClick={()=>handleRemoveVehicleTypeClick(index)} disabled={mode === 'view'}>
//                             Remove 
//                          </TypeBtn>
//                      </Box>
//               </Box>
//             ))
//           }
//         </Box>
//         <ErrorMsg>{error}</ErrorMsg>
//         <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
//              <BtnPrimary 
//                onClick={handleNextClick} 
//                sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
//                disabled={apiCall}
//              >
//                 {apiCall?<CircularProgress/>:"Next"}
//              </BtnPrimary>
//              <BtnSecondary to="/app/trips/listing" sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}>
//                 Back
//              </BtnSecondary>
//         </Box>
//     </Box>
//     )
//   )
// }

// export default PolicySetting;

import React from 'react';
import PolicyDetails from "../../../../../../../../component/PolicyDetails";

const TripsPolicyDetails = () => {
  
  return (
       <PolicyDetails
           type="TRIP"
           listingPageLink="/app/trips/listing"
           addCategoryPageLink="/app/trips/new/add-vehicle-type"
       />
    )
}

export default TripsPolicyDetails;