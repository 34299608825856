import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const TextBox = styled(Box)({
  display: "block",
  wordBreak: "break-word",
});

function CurrentDateTime() {
  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const formattedDate = currentDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      const formattedTime = currentDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      setCurrentDateTime(`${formattedDate} ${formattedTime}`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <TextBox fontSize={"1rem"} lineHeight={"1.5rem"}>
      {currentDateTime}
    </TextBox>
  );
}

export default CurrentDateTime;
