import React, { useState, useEffect } from 'react';
import { Avatar, Box, CircularProgress } from '@mui/material';
import BtnPrimary from '../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../component/SecondaryButton';
import FormField from '../../../../../../component/Formfield';
import RadioButtonGroup from '../../../../../../component/RadioButtonGroup';
import CheckboxField from '../../../../../../component/CheckboxField';
import {createLeaveType, updateLeaveType, getLeaveTypeById} from "../../../../../../services/leave";
import {useLocation, useNavigate} from "react-router-dom";
import ErrorMsg from '../../../../../../component/ErrorMsg';
import DeleteWarning from '../../../../../../component/DeleteWarning';
import SaveDialog from '../../../../../../component/SaveDialog';
import ProgressBar from '../../../../../../component/ProgressBar';
import DocsIcon from '../../../../../../assets/icons/docs.svg';

const LeaveTypeForm = () => {
    const [apiCall,setApiCall]=useState(false);
    const navigate = useNavigate();
    const {state} = useLocation();
    const [mode, setMode] = useState('create'); 
    const [typeId, setTypeId] = useState(null);
    const [isDeleteDialogOpen,setIsDeleteDialogOpen] = useState(false);
    const [isSaveDialogOpen,setIsSaveDialogOpen] = useState(false);
    const [apiError,setApiError] = useState("");

    const [leaveData, setLeaveData] = useState({
        leaveCode: '',
        name: '',
        isPaid: true,
        isCountedTowardsHours: true,
        isPartiallyPaid: false,
        paymentPercentage: '',
        restrictToGender: false,
        genders: [],
        leaveEncashment: false,
        maximumEncashableDays: '',
        nonEncashableDays: '',
        earningComponent: '',
        isEarnedLeave: false,
        earnedLeaveFrequency: '',
        earnedLeaveRounding: ''
    });

    const [validationErrors, setValidationErrors] = useState({
        leaveCode: '',
        name: '',
        paymentPercentage: '',
        maximumEncashableDays: '',
        nonEncashableDays: '',
        earningComponent: '',
        earnedLeaveFrequency: '',
        earnedLeaveRounding: ''
    });

    useEffect(() => {
        if (state && state.typeId) {
            setMode('view');
            setTypeId(state.typeId);
            fetchTypeData(state.typeId); 
        } 
    }, [state]);

    const fetchTypeData = async (typeId) => {
        try {
            const res = await getLeaveTypeById(typeId); 
            const data = res.data;
            console.log(data);
            setLeaveData(prevState => ({
                ...prevState,
                leaveCode: data?.leaveCode,
                name: data?.name,
                isPaid: data?.isPaid,
                isCountedTowardsHours: data?.isCountedTowardsHours,
                isPartiallyPaid: data?.isPartiallyPaid,
                paymentPercentage: data?.paymentPercentage,
                restrictToGender: data?.restrictToGender,
                genders: [],
                leaveEncashment: data?.leaveEncashment,
                maximumEncashableDays: data?.maximumEncashableDays,
                nonEncashableDays: data?.nonEncashableDays,
                earningComponent: data?.earningComponent,
                isEarnedLeave: data?.isEarnedLeave,
                earnedLeaveFrequency: data?.earnedLeaveFrequency,
                earnedLeaveRounding: data?.earnedLeaveRounding
            })); 
        } catch (error) {
            console.error('Error fetching policy data:', error);
        }
    };
    
    console.log(leaveData);

    const isValidationErrorExists = () => {
        const values = Object.values(validationErrors);
        return values.some(value => value !== '');
    };

    const handleSaveClick = async() => {
        if (!leaveData.leaveCode) {
            setValidationErrors(prevState => ({
                ...prevState,
                leaveCode: "Leave Code is required"}))
        }
        if (!leaveData.name) {
            setValidationErrors(prevState => ({
                ...prevState,
                name: "Leave Name is required"}))
        }
        const leaveForm = {
            leaveCode: leaveData.leaveCode,
            name: leaveData.name,
            isPaid: leaveData.isPaid,
            isCountedTowardsHours: leaveData.isCountedTowardsHours,
            isPartiallyPaid: leaveData.isPartiallyPaid,
            genders: leaveData.genders,
            isEarnedLeave: leaveData.isEarnedLeave,
        }
        if (leaveData.isPartiallyPaid) {
            leaveForm.paymentPercentage = leaveData.paymentPercentage;
            if (!leaveData.paymentPercentage) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    paymentPercentage: "Payment Percentage is required"}))
            }
        }

        if (leaveData.leaveEncashment) {
            leaveForm.maximumEncashableDays = leaveData.maximumEncashableDays;
            if (!leaveData.maximumEncashableDays) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    maximumEncashableDays: "Maximum Encashable Days is required"}))
            }
            leaveForm.nonEncashableDays = leaveData.nonEncashableDays;
            if (!leaveData.nonEncashableDays) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    nonEncashableDays: "Non Encashable Days is required"}))
            }
            leaveForm.earningComponent = leaveData.earningComponent;
            if (!leaveData.earningComponent) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    earningComponent: "Earning Component is required"}))
            }
        }

        if (leaveData.isEarnedLeave) {
            leaveForm.earnedLeaveFrequency = leaveData.earnedLeaveFrequency;
            if (!leaveData.earnedLeaveFrequency) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    earnedLeaveFrequency: "Earned Leave Frequency is required"}))
            }
            leaveForm.earnedLeaveRounding = leaveData.earnedLeaveRounding;
            if (!leaveData.earnedLeaveRounding) {
                setValidationErrors(prevState => ({
                    ...prevState,
                    earnedLeaveRounding: "earned Leave Rounding is required"}))
            }
        }
        
        if (isValidationErrorExists()) {
            return;
        }

        console.log(leaveForm);
        try {
            setApiCall(true);
            if (mode === 'create') {
                const res = await createLeaveType(leaveForm); 
                if (res.success) {
                    handleNavigate(); 
                } else {
                    console.error('Leave creation failed:', res.error);
                }
            } else if (mode === 'edit') {
                const res = await updateLeaveType(typeId, leaveForm); 
                if (res.success) {
                     setIsSaveDialogOpen(true);
                } else {
                    console.error('Leave update failed:', res.error);
                }
            }
        } catch (error) {
            console.error('Error saving leave type:', error);
        }
        setApiCall(false);
    };

    const handleNavigate = () =>{
        setLeaveData({
            leaveCode: '',
            name: '',
            isPaid: true,
            isCountedTowardsHours: true,
            isPartiallyPaid: false,
            paymentPercentage: '',
            restrictToGender: false,
            genders: [],
            leaveEncashment: false,
            maximumEncashableDays: '',
            nonEncashableDays: '',
            earningComponent: '',
            isEarnedLeave: false,
            earnedLeaveFrequency: '',
            earnedLeaveRounding: ''
        });
        navigate('/app/leave-type');
    }

    const handleDelete = () =>{
        setIsDeleteDialogOpen(true);
    }

    const handleChange = (field, value) => {
        const updatedValue = field === "leaveCode" ? value?.toUpperCase() : value;
        setLeaveData(prevState => ({...prevState,[field]: updatedValue }));
        setValidationErrors(prevState => ({...prevState,[field]: ""}))
    };

    const modeSwitch = () =>{
       setMode(mode==="edit"?"view":"edit")
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
                <Box>
                    <Box sx={{display:"flex"}}>
                      <Avatar src={DocsIcon} style={{ width: '1.5rem', height: '1.5rem' }}/>
                      <Box sx={{fontWeight:"300", fontSize:"1rem", lineHeight:"1.5rem", color:"#0081FF", marginBottom:"0.56rem"}}>
                          Leave Type
                      </Box>
                    </Box>
                    <ProgressBar/>
                </Box> 
                <BtnSecondary 
                      to="/app/leave-type"
                      sx={{width:"5.56rem", height:"2.25rem", borderRadius:"10px"}}
                  >
                        Back
                </BtnSecondary>
            </Box>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: "1rem", justifyContent: "space-between" }}>
                    <FormField
                        label="Leave Code"
                        type="text"
                        value={leaveData.leaveCode}
                        validation={validationErrors.leaveCode}
                        onChange={(e) => handleChange('leaveCode', e.target.value)}
                        disabled={mode === 'view'}
                    />
                    <FormField
                        label="Leave Name"
                        type="text"
                        value={leaveData.name}
                        validation={validationErrors.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        disabled={mode === 'view'}
                    />
            </Box>
            <RadioButtonGroup
                label="Payroll Selection"
                name="isPaid"
                value={leaveData.isPaid} 
                disabled={mode === 'view'}
                onChange={(e) => handleChange('isPaid', e.target.value)}
                options={[
                    { value: true, label: 'Paid' },
                    { value: false, label: 'Unpaid' }
                ]}
            />
            <RadioButtonGroup
              label="Should this be counted as hours worked by the employee?"
              name="isCountedTowardsHours"
              value={leaveData.isCountedTowardsHours}
              disabled={mode === 'view'}
              onChange={(e) => handleChange('isCountedTowardsHours', e.target.value)}
              options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
              ]}
          />

          <CheckboxField
              label="Is this a partially paid leave?"
              checked={leaveData.isPartiallyPaid}
              disabled={mode === 'view'}
              onChange={(isChecked) => handleChange('isPartiallyPaid', isChecked)}
              labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
          />

          {leaveData.isPartiallyPaid && (
              <FormField
                  label="Percent of daily salary per leave"
                  type="number"
                  value={leaveData.paymentPercentage}
                  validation={validationErrors.paymentPercentage}
                  disabled={mode === 'view'}
                  onChange={(e) => handleChange('paymentPercentage', e.target.value)}
                  fullwidth={true}
              />
          )}

          <CheckboxField
              label="Restrict to gender"
              checked={leaveData.restrictToGender}
              disabled={mode === 'view'}
              onChange={(isChecked) => handleChange('restrictToGender', isChecked)}
              labelStyle={{ fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
          />

          {leaveData.restrictToGender && (
              <RadioButtonGroup
                  name="genders"
                  value={leaveData.genders}
                  disabled={mode === 'view'}
                  onChange={(e) => handleChange('genders',e.target.value)}
                  options={[
                      { value: 'male', label: 'Male' },
                      { value: 'female', label: 'Female' },
                      { value: 'others', label: 'Others' }
                  ]}
              />
          )}

          <CheckboxField
              label="Leave encashment"
              checked={leaveData.leaveEncashment}
              disabled={mode === 'view'}
              onChange={(isChecked) => handleChange('leaveEncashment',isChecked)}
              labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
          />

          {leaveData.leaveEncashment && (
              <>
                  <FormField
                      label="Maximum encashable leaves"
                      type="number"
                      value={leaveData.maximumEncashableDays}
                      validation={validationErrors.maximumEncashableDays}
                      disabled={mode === 'view'}
                      onChange={(e) => handleChange('maximumEncashableDays', e.target.value)}
                      fullwidth={true}
                  />
                  <FormField
                      label="Non-encashable leaves"
                      type="number"
                      value={leaveData.nonEncashableDays}
                      validation={validationErrors.nonEncashableDays}
                      disabled={mode === 'view'}
                      onChange={(e) => handleChange('nonEncashableDays', e.target.value)}
                      fullwidth={true}
                  />
                  <FormField
                      label="Earning Component"
                      type="text"
                      value={leaveData.earningComponent}
                      validation={validationErrors.earningComponent}
                      disabled={mode === 'view'}
                      onChange={(e) => handleChange('earningComponent', e.target.value)}
                      fullwidth={true}
                  />
              </>
          )}

          <CheckboxField
              label="Earned leave"
              checked={leaveData.isEarnedLeave}
              disabled={mode === 'view'}
              onChange={(isChecked) => handleChange('isEarnedLeave',isChecked)}
              labelStyle={{fontSize: "1rem", lineHeight: "1.5rem", color: "#818181" }}
          />

           {leaveData.isEarnedLeave && (
               <>
                   <FormField
                       label="Frequency"
                       type="select"
                       value={leaveData.earnedLeaveFrequency}
                       validation={validationErrors.earnedLeaveFrequency}
                       options={['year', 'month']}
                       disabled={mode === 'view'}
                       onChange={(e) => handleChange('earnedLeaveFrequency', e.target.value)}
                       fullwidth={true}
                   />
                   <FormField
                       label="Rounding"
                       type="text"
                       value={leaveData.earnedLeaveRounding}
                       validation={validationErrors.earnedLeaveRounding}
                       disabled={mode === 'view'}
                       onChange={(e) => handleChange('earnedLeaveRounding', e.target.value)}
                       fullwidth={true}
                   />
               </>
           )}
           <ErrorMsg>{apiError}</ErrorMsg>
            <Box sx={{ display: "flex", justifyContent: "end", gap: "1rem", marginBlock: "1.875rem" }}>
                {mode==="view"?
                      <BtnSecondary onClick={modeSwitch} sx={{borderRadius:"10px",width:"5.56rem",height:"2.25rem"}}>
                        Edit
                      </BtnSecondary> 
                  :(
                    <Box sx={{display:"flex", gap:"0.5rem"}}>
                        <BtnPrimary 
                            onClick={handleSaveClick} 
                            sx={{ borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                            disabled={apiCall}
                        >
                          {apiCall?<CircularProgress/>:"Save Leave Type"}
                        </BtnPrimary>
                        {mode==="edit"?(
                          <BtnSecondary 
                          onClick={handleDelete} 
                          sx={{borderRadius: "8px", width: "11.875rem", height: "3.125rem" }}
                            >
                              Delete
                            </BtnSecondary>
                        ):null}
                    </Box>
              )}
            </Box>
            <SaveDialog open={isSaveDialogOpen} onClose={()=>setIsSaveDialogOpen(false)} redirectLink={`/app/leave-type`}/>
            <DeleteWarning open={isDeleteDialogOpen} onClose={()=>setIsDeleteDialogOpen(false)} redirectLink={'/app/leave-type'}/>
        </Box>
    )
}

export default LeaveTypeForm;
