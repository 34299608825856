import React,{useState} from 'react';
import {Box, TableCell} from "@mui/material";
import styled from '@emotion/styled';
import BtnPrimary from "../../../../../../component/PrimaryButton";
import PaginationTable from '../../../../../../component/PaginationTable';
import { useNavigate } from 'react-router';
import { SearchBox } from '../../../../../../component/SearchBox';

const Headings = {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    color: "#000000",
    padding: "1.375rem 1rem",
    borderBottom: "1px solid #D1D5DB",
}

const CustomTableCell = styled(TableCell)({
    fontSize: "1rem",
    color: "#374151",
    lineHeight: "1.5rem",
    padding: "1rem",
    borderBottom: "0.5px solid #16151C33",
});


const MachineListing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([{
    name:"Adobe",
    type:"Graphics",
    version:"1233.0000",
    manufacturer:"Adobe",
  }]);
  const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const res = await 
    //       setLoading(false);
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //       setLoading(false);
    //     } 
    //   };
    //   fetchData();
    // }, []);
    
    // console.log(data);
  
    const headings = [
      { label: "Name", style: {...Headings, width:"7.5rem", borderTopLeftRadius: "12px"}},
      { label: "Type", style: {...Headings, width:"18.875rem"} },
      { label: "Version", style: {...Headings, width:"18.875rem"} },
      { label: "Manufacturer", style: {...Headings, width:"18.875rem"} },
    ];

  return (
    <Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom:"1.5rem" }}>
             <SearchBox inputStyle={{height:"2.625rem"}}/>
             <BtnPrimary
               to="form"
               sx={{ borderRadius: "10px",padding:"1.25rem", height: "2.625rem" }}
             >
               Add Software
             </BtnPrimary>
         </Box>
        <PaginationTable
          rows={data}
          headings={headings}
          loading={loading}
          noRecordMessage={"No Record Found"}
          tableStyle={{border:"0.5px solid #00000033", borderRadius:"12px"}}
          renderCell={(row) => (
            <>
                <CustomTableCell>{row.name}</CustomTableCell>
                <CustomTableCell>{row.type}</CustomTableCell>
                <CustomTableCell>{row.version}</CustomTableCell>
                <CustomTableCell>{row.manufacturer}</CustomTableCell>
            </>
          )}
        />
    </Box>
  )
}

export default MachineListing