import React, { useEffect, useState } from 'react';
import { apiKey } from "./googleMap/Helper";

const MapWithMarker = ({ location, defaultZoom, sx }) => {
 
  const [map, setMap] = useState(null); // State to store the map instance

  useEffect(() => {
    const initMap = () => {
      const mapInstance = new window.google.maps.Map(document.getElementById("map"), {
        zoom: defaultZoom || 12,
        center: location, 
        mapTypeId: "terrain",
      });

      // Add the marker to the map
      new window.google.maps.Marker({
        position: location,
        map: mapInstance,
      });

      setMap(mapInstance); // Store the map instance in the state
    };

    // Load the Google Maps script if it's not already loaded
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&v=weekly&channel=2`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = initMap;
    } else {
      initMap();
    }
  }, [location, defaultZoom]);

  return <div id="map" style={sx}></div>;
};

export default MapWithMarker;
