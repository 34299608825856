import { postCall, getCall, putCall } from "./api";

export const createShift = async (input = {}) => {
  const request = {
    path: `/shift`,
    bodyParams: input,
  };
  return await postCall(request);
};

export const getAllShift = async () => {
  const request = {
    path: `/shift`,
  };
  return await getCall(request);
};

export const getShiftById = async (id) => {
  const request = {
    path: `/shift/${id}`,
  };
  return await getCall(request);
};

export const updateShift = async (id, input = {}) => {
  const request = {
    path: `/shift/${id}`,
    bodyParams: input,
  };
  return await putCall(request);
};

