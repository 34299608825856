import { getCall, putCall, postCall } from "./api";

//create policy
export const createPolicy = async (input = {}) => {
  const request = {
    path: `/policy`,
    bodyParams: input,
  };
  return await postCall(request);
};


//policy category
export const getPolicyCategoryById = async (id) => {
    const request = {
      path: `/policy-cateogary/id/${id}`,
    };
    return await getCall(request);
  };

  export const updatePolicyCategory = async (id,input = {}) => {
    const request = {
      path: `/policy-cateogary/${id}`,
      bodyParams: input
    };
    return await putCall(request);
  };

  //policy
  export const updatePolicy = async (policyId, input = {}) => {
    const request = {
      path: `/policy/${policyId}`,
      bodyParams: input,
    };
    return await putCall(request);
  };

  //get self policy
  export const getSelfPolicy = async () => {
    const request = {
      path: `/policy/self`,
    };
    return await getCall(request);
  };
  