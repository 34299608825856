import React, {useState} from 'react';
import { Dialog,Box,Button} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import BtnPrimary from "../../../../../component/PrimaryButton";
import FormField from '../../../../../component/Formfield';
import CloseIcon from '@mui/icons-material/Close';
import EmpFormField from '../../../../../component/EmpFormField';
import { getOrgEmployees } from '../../../../../services/globalData';

function ShareExpense({open, setOpen, onClose}) {

  const [errorMsg,setErrorMsg] = useState("");
  const [employeeList,setEmployeeList] = useState(false);
  const [employees, setEmployees] = useState([]);

  const employeeOptions = getOrgEmployees()?.map(employee => ({
    value: employee._id,
    name: employee.fname+" "+employee.lname
  }));

  const handleClick = async () => {
   
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width:"49.56rem",
          borderRadius: "12px",
          boxShadow: "0px 4px 10px 0px #0000001A",
          padding: "1.625rem 0.875rem",
        }
      }}
    >
        <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Box sx={{fontWeight:"500", fontSize:"1.25rem", lineHeight:"1.875rem", color:"#0A0A0A", width:"90%", textAlign:"center"}}>
                Share Expense
            </Box>
            <CloseIcon 
                fontSize='small' 
                onClick={()=>setOpen(false)} 
                sx={{
                  justifySelf:"end",
                  "&:hover": {
                    cursor: "pointer",
                  },
            }}/>
        </Box>
        <Box sx={{fontSize:"1rem", lineHeight:"1.5rem",marginTop:"1rem", marginBottom:"0.5rem"}}>
            Add Employee
        </Box>
        <EmpFormField
                type="select"
                value={employees}
                fullwidth
                options={employeeOptions}
                onChange={(e) => setEmployees(e.target.value)}
                row="0.75"
                placeholder="Search Employee Name"
        />
        <Box sx={{display:"flex", justifyContent:"center"}}>
            <BtnPrimary sx={{width:"18.75rem",height:"3rem", borderRadius:"4px"}} onClick={handleClick}>
               Add Employee
            </BtnPrimary>
        </Box>
    </Dialog>
  );
}

export default ShareExpense;

