import React from 'react'
import styled from "@emotion/styled";
import {Box, Checkbox} from "@mui/material";
import { useOutletContext } from 'react-router';

const Container = styled(Box)({
    borderRadius:"12px",
    padding:"1.5rem",
    border:"1px solid var(--Colors-Prime-Gray-Prime-Gray-300, #D1D5DB)",
 });


const SelfIdentification = () => {

  const [employeeData] = useOutletContext();
  console.log(employeeData);

  const gender = [
   {checked:employeeData?.gender==="Male", point:"Male"},
   {checked:employeeData?.gender==="Female", point:"Female"},
   {checked:employeeData?.gender==="None", point:"Do not wish to identify"}
  ]

  const ethnicity= [
   {checked:employeeData?.ethinicity=== "Hispanic or Latino: A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin regardless of race.",
    point:"Hispanic or Latino: A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin regardless of race."
   },
   {checked:employeeData?.ethinicity=== "White (Not Hispanic or Latino): A person having origins in any of the original peoples of Europe, the Middle East or North Africa.",
    point:"White (Not Hispanic or Latino): A person having origins in any of the original peoples of Europe, the Middle East or North Africa."
   },
   {checked:employeeData?.ethinicity=== "Black or African American (Not Hispanic or Latino): A person having origins in any of the black racial groups of Africa.",
    point:"Black or African American (Not Hispanic or Latino): A person having origins in any of the black racial groups of Africa."
   },
   {checked:employeeData?.ethinicity=== "Native Hawaiian or Pacific Islander (Not Hispanic or Latino): A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands.",
    point:"Native Hawaiian or Pacific Islander (Not Hispanic or Latino): A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands."
   },
   {checked:employeeData?.ethinicity=== "Asian (Not Hispanic or Latino): A person having origins in any of the original peoples of the Far East, Southeast Asia or the Indian Subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam.",
    point:"Asian (Not Hispanic or Latino): A person having origins in any of the original peoples of the Far East, Southeast Asia or the Indian Subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam."
   },
   {checked:employeeData?.ethinicity==="I do not wish to disclose.", 
 point:"I do not wish to disclose."},  
]

  return (
     <Container sx={{display:"flex", flexDirection:"column", gap:"1rem"}}>
        <Box sx={{fontWeight:"500", fontSize:"1.25rem", lineHeight:"1.75rem", color:"#141414"}}>
            Self-Identification
        </Box>
        <Box sx={{fontSize:"0.875rem", lineHeight:"1.31rem", color:"#5C5C5C"}}>
           At  Kinesiis, we value diversity and inclusion. We are committed to providing equal opportunities and ensuring reasonable accommodations for individuals with disabilities. Please provide the following information to help us understand and address any specific needs or accommodations you may require. Your response will be kept confidential and will only be shared with relevant parties involved in the accommodation process, as required by law.
           <br/><br/>
           How do I know if I have a disability?
           <br/>
           You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.
           <br/><br/>
           Please provide the following information to help us understand and address any specific needs or accommodations you may require. Your response will be kept confidential and will only be shared with relevant parties involved in the accommodation process, as required by law.
        </Box>
        <Box sx={{fontSize:"1.125rem", lineHeight:"1.68", color:"#3A3A3A"}}>
          Gender
        </Box>
        <Box sx={{display:"flex", gap:"1.5rem"}}>
           {gender.map((item)=>{
             return(
                <Box sx={{display:"flex", gap:"1rem"}}>
                      <Checkbox
                          checked={item.checked}
                          disabled="true"
                          sx={{
                            "&.MuiButtonBase-root":{
                              padding:0,
                              color:"#0081FF66"
                            },
                          }}
                      />
                      <Box sx={{fontSize:"1rem",lineHeight:"1.5rem",color:"#141414"}}>
                         {item.point}
                      </Box>
                </Box>
             )
           })

           }
        </Box>
        <Box sx={{fontSize:"1.125rem", lineHeight:"1.68", color:"#3A3A3A"}}>
          Ethnicity
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", gap:"1.5rem"}}>
           {ethnicity.map((item)=>{
             return(
                <Box sx={{display:"flex", gap:"1rem"}}>
                      <Checkbox
                          checked={item.checked}
                          disabled="true"
                          sx={{
                            "&.MuiButtonBase-root":{
                              padding:0,
                              color:"#0081FF66"
                            },
                          }}
                      />
                      <Box sx={{fontSize:"1rem",lineHeight:"1.5rem",color:"#141414"}}>
                         {item.point}
                      </Box>
                </Box>
             )
           })}
        </Box>
     </Container>
  )
}

export default SelfIdentification