import React, { useState } from "react";
import { Box } from "@mui/material";
import Topbar from "../../../../../../component/TopBar";
import MainBox from "../../../../container/MainBox";
import { Outlet } from "react-router";

function LeaveType() {
  return (
     <Box sx={{width:"100%", paddingBlock:"1rem"}}>
        <Topbar MainHeading={"Leave Type"}/>
        <MainBox sx={{ padding: "1.875rem 1.25rem" }}>
              <Outlet/>
        </MainBox>
     </Box>
      
  );
}

export default LeaveType;
