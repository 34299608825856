import React, { useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import BtnPrimary from "../../../../../component/PrimaryButton";
import FormField from "../../../../../component/Formfield";
import MultiSectionClock from "../../../../../component/MultiSectionClock";
import MonthPicker from "../../../../../component/MonthPicker";

const TextLabel = styled(Box)({
  fontSize: "0.875rem",
  lineHeight: "1.31rem",
  color: "#16151C",
  marginBottom: "1rem",
});

const TextValue = styled(TextLabel)({
  fontWeight: "600",
});

const RegularizationForm = () => {
  const [regularizationFormData, setRegularizationFormData] = useState({
    updatedClockInTime: "",
    updatedClockOutTime: "",
    reason: "",
  });

  const handleSubmit = () => {
    console.log(regularizationFormData);
  };

  const handleChange = (field, value) => {
    setRegularizationFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "0.75rem",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "100%", marginBottom: "0.25rem" }}>
        <MonthPicker />
      </Box>
      <Box sx={{ width: "48%" }}>
        <TextLabel>Clock in time :</TextLabel>
        <TextValue></TextValue>
      </Box>
      <Box sx={{ width: "48%" }}>
        <TextLabel>Clock out time :</TextLabel>
        <TextValue></TextValue>
      </Box>
      <Box sx={{ width: "48%" }}>
        <TextLabel>Update Clock in time</TextLabel>
        <MultiSectionClock />
      </Box>
      <Box sx={{ width: "48%" }}>
        <TextLabel>Update Clock out time</TextLabel>
        <MultiSectionClock />
      </Box>
      <Box sx={{ width: "100%" }}>
        <TextLabel>Reason</TextLabel>
        <FormField
          type="text"
          value={regularizationFormData.reason}
          onChange={(e) => handleChange("reason", e.target.value)}
          fullwidth={true}
          row={4}
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <BtnPrimary
          onClick={handleSubmit}
          sx={{ width: "11.25rem", height: "3rem", borderRadius: "8px" }}
        >
          Submit
        </BtnPrimary>
      </Box>
    </Box>
  );
};

export default RegularizationForm;
