import React,{useState, useEffect} from 'react';
import {Box, CircularProgress} from '@mui/material';
import ActiveBar from "../../../../../../../component/PolicyNavbarUpdated";
import BtnPrimary from '../../../../../../../component/PrimaryButton';
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import FormField from '../../../../../../../component/Formfield';
import TypeBtn from '../../../../../../../component/AddRemoveButton';
import {getAllExpenseCategory} from "../../../../../../../services/expense";
import { updatePolicy } from '../../../../../../../services/policy';
import { getLegalUnitList } from '../../../../../../../services/globalData';
import {useLocation, useNavigate} from "react-router-dom";
import ErrorMsg from '../../../../../../../component/ErrorMsg';
import SetLimit from '../../../../../../../component/SetLimit';

const AddCategory = () => {

  const [apiCall,setApiCall]=useState(false);
  const navigate = useNavigate();
  const {state} = useLocation();
  const [policyId, setPolicyId] = useState(null);
  const [expenseCategories,setExpenseCategories] = useState([{_id:"",code:"",limits:[]}]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null); 
  const [error,setError]=useState("");

  console.log(state);

  useEffect(()=>{
    getExpenseCategoryList();
    setPolicyId(state?.policyId);
  },[]);

  const getExpenseCategoryList = async () => {
    try {
      const legalUnit = getLegalUnitList();
      const res = await getAllExpenseCategory(legalUnit[0]._id);
      const categories = res.data.map(category => ({ value: category._id, name: category.name, code: category.code}));
      console.log(categories);
      setCategoryOptions(categories);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


    const handleAddExpenseCategory = () =>{
      setExpenseCategories([...expenseCategories,{ _id: "", code: "", limits: [] }])
    }

    const handleRemoveExpenseCategory = (index) => {
      let selectedExpenseCategory = [...expenseCategories];
      selectedExpenseCategory.splice(index, 1);
      setExpenseCategories(selectedExpenseCategory);
    };

    const handleCategorySelection = (index, fieldName, value) => {
      let selectedExpenseCategory = [...expenseCategories];
      selectedExpenseCategory[index][fieldName] = value;
      setExpenseCategories(selectedExpenseCategory);
    };

    //returns corresponding code when an expense is selected
    const getCode = (id) =>{
        return categoryOptions.find((option)=>option.value===id)?.code;  
    }

    const handleSetLimit = (index) => {
      setSelectedCategoryIndex(index); 
      setIsLimitDialogOpen(true);
    };

    const handleLimitDialogClose = (limits) => {
      setIsLimitDialogOpen(false);
      if (limits && selectedCategoryIndex !== null) {
        let updatedExpenseCategories = [...expenseCategories];
        updatedExpenseCategories[selectedCategoryIndex].limits = limits; 
        setExpenseCategories(updatedExpenseCategories);
      }
      setSelectedCategoryIndex(null); 
    };

    const handleNextClick = async() => {
     setApiCall(true);
     try {
          const res = await updatePolicy(policyId,{policyCateogary:expenseCategories});
          console.log(res);
          if(res.success){
            navigate("/app/expense-policy/new/assign-policy",{state:{policyId}});
          }else{
            console.error('Policy updation failed:', res.error);
            setError(res.message);
          }
      } catch (error) {
          console.error('Error saving Policy:', error);
      }
      setApiCall(false);
  };

  console.log(expenseCategories);

  return (
      <Box sx={{width:"100%"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={["Policy Details","Add Category"]} value="36"/>
          </Box>
          <Box> 
            {
              expenseCategories?.map((item,index)=>(
                <Box key={index}>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                    <Box sx={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems:"center" }}>
                        <FormField
                            label="Choose Category"
                            type="select"
                            options={categoryOptions}
                            value={item._id}
                            onChange={(e) => {
                                      handleCategorySelection(index, '_id', e.target.value)
                                      handleCategorySelection(index, 'code', getCode(e.target.value) )
                                    }}
                        />
                        <FormField
                            label="Category Code"
                            type="text"
                            value={item.code}
                            disabled={true}
                        />
                      </Box>
                        <TypeBtn type="remove" onClick={()=>handleRemoveExpenseCategory(index)} sx={{width: '1.125rem', height: '1.125rem'}}>
                        </TypeBtn>
                  </Box>
                  {item?.limits?.length > 0?(
                    <Box sx={{backgroundColor:"#FAFAFB",borderRadius:"1rem",padding:"2rem 1rem", display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
                        <Box sx={{fontSize:"1rem",lineHeight:"1.5rem", display:"flex"}}>
                          {"Expense Limit set to: "} 
                          {item?.limits?.map((limit)=>`${limit?.limit}/${limit?.frequency}`).join(', ')}
                        </Box>
                        <BtnSecondary
                          onClick={() => handleSetLimit(index)}
                          sx={{width:"8.93rem", height:"1.93rem", borderRadius:"10px"}}
                        >
                            Edit Limit
                        </BtnSecondary>
                    </Box>
                  ):(
                    <Box sx={{display:"flex",justifyContent:"end"}}>
                          <BtnSecondary
                            onClick={() => handleSetLimit(index)}
                            sx={{width:"8.93rem", height:"1.93rem", borderRadius:"10px"}}
                            disabled={item.code===""}
                          >
                              Set Limit
                          </BtnSecondary>
                    </Box>
                  )}             
                </Box>
              ))
            }
            <ErrorMsg>{error}</ErrorMsg>
            {(expenseCategories.length===0 || expenseCategories[expenseCategories?.length - 1]?._id !=="")?(
                <TypeBtn type="add" onClick={handleAddExpenseCategory}>
                    Add Category
                </TypeBtn>
            ):null}
          </Box>
          
          <Box sx={{display:"flex", justifyContent:"end", gap:"1rem", marginTop:"1.875rem"}}>
              <BtnPrimary 
                onClick={handleNextClick} 
                sx={{width:"10rem", height:"3.125rem", borderRadius:"10px"}}
                disabled={apiCall}
              >
                  {apiCall?<CircularProgress/>:"Next"}
              </BtnPrimary>
          </Box>

          <SetLimit
            open={isLimitDialogOpen}
            limits={
              expenseCategories[selectedCategoryIndex]?.limits?.length>0 
              ? expenseCategories[selectedCategoryIndex]?.limits 
              : [{limit:"",frequency:"",instances:"",frequencyType:""}]
            }
            onClose={handleLimitDialogClose}
          />
      </Box>
    )
}

export default AddCategory;