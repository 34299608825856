import React,{useState} from 'react';
import {Box} from '@mui/material';
import ActiveBar from "../../../../../../../component/PolicyNavbar";
import BtnSecondary from '../../../../../../../component/SecondaryButton';
import SortBy from './SortBy';
import CreateGroup from '../../../../../../../component/CreateGroup';
import { useLocation } from 'react-router';

const AssignPolicy = () => {

  const [page, setPage] = useState("sortBy");
  const {state} = useLocation();

  const loadNextPage = (p) => {
    setPage(p);
  };

  const commonProps = {
    loadNextPage,
    policyId:state?.policyId
  };

  const sortByProps = {
    ...commonProps,
  };

  const createGroupProps = {
    ...commonProps,
    redirectLink: "/app/leave-policy/assign-policy", 
  };


  const mapPages = {
    sortBy: (props) => <SortBy {...sortByProps} />,
    createGroup: (props) => <CreateGroup {...createGroupProps} />,
  };

  const ShowPage = mapPages[page];
 
  return (
      <Box sx={{width:"100%"}}>
          <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"1rem"}}>
              <ActiveBar active={["Policy Details","Assign Policy"]} value="52"/>
              <BtnSecondary 
                sx={{width:"10rem", height:"2.25rem", borderRadius:"10px"}} 
                onClick={()=>{page==="sortBy"?loadNextPage("createGroup"):loadNextPage("sortBy")}}
              >
                  {page==="sortBy"?"+ Create Group":"Back"}
              </BtnSecondary>
          </Box>
          <Box>
            <ShowPage/>
          </Box> 
      </Box>
    )
}

export default AssignPolicy;